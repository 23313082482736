import { RESTClient } from './client';
import { ItemType } from '../models/itemType';

export class ItemTypeClient extends RESTClient<ItemType> {
  public constructor(token?: string) {
    super('itemTypes', token);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public read(_entity: ItemType): never {
    throw new Error('Not supported by API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public update(_entity: ItemType): never {
    throw new Error('Not supported by API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public delete(_entity: ItemType): never {
    throw new Error('Not supported by API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public create(_entity: ItemType): never {
    throw new Error('Not supported by API');
  }
}
