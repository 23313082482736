export interface Country {
  id: number;
  name: string;
  alpha2: string;
  alpha3: string;
}

export const initialCountry: Country = {
  id: undefined,
  name: undefined,
  alpha2: undefined,
  alpha3: undefined
};
