import { User } from '../models/user';
import { Client } from './client';

export class SessionClient extends Client {
  public signIn(email: string, password: string) {
    return this.clientInstance.post<User>('users/sign_in', {
      user: {
        email,
        password
      }
    });
  }
}
