export enum NotificationType {
  INFO,
  SUCCESS,
  WARNING,
  ERROR
}

export interface Notification {
  details?: string;
  message: string; // i18n key
  time?: number;
  type?: NotificationType;
  values?: { [key: string]: string };
}
