import { Item, SingleChoiceItem, MultiChoiceItem } from './item';
import { Participant } from './participant';
import { Answer, SingleChoiceAnswer, MultiChoiceAnswer } from './answer';
import { TextTemplate } from './textTemplate';
import { ResponseItem } from './responseItem';

export interface Progress {
  certification: {
    title: string;
    passingScore: number;
    finalPageTextTemplate?: TextTemplate;
  };
  responseItems: ResponseItem[];
  participant: Participant;
  expiration: string;
}

export interface ProgressItem {
  id: number;
  item: Omit<Item, 'id'>;
  answer?: Omit<Answer, 'id' | 'certificationItem'>;
}

export interface Transaction {
  progress?: Progress;
  token?: string;
}

export const initialTransaction: Transaction = {};

export const calculatePointMaximum = (responseItems: Progress['responseItems']) =>
  responseItems.reduce((total, current) => (current.item.definition['points'] ? total + current.item.definition['points'] : total), 0);

export const calculateAchievedPoints = (responseItems: Progress['responseItems']) => {
  return Math.round(
    responseItems.reduce((total, current) => {
      if (!current.answer) {
        return total;
      }
      switch (current.item.itemType.kind) {
        case 'singleChoiceItem': {
          return total + calculateSingleChoicePoints(current);
        }
        case 'multiChoiceItem': {
          return total + calculateMultiChoicePoints(current);
        }
      }
      return total;
    }, 0)
  );
};

export const calculateSingleChoicePoints = (progressItem: Omit<ProgressItem, 'id'>) => {
  const item = progressItem.item as SingleChoiceItem;
  const answer = progressItem.answer as SingleChoiceAnswer;
  if (answer.value.choice === item.definition.solution) {
    return item.definition.points;
  }
  return 0;
};

export const calculateMultiChoicePoints = (progressItem: Omit<ProgressItem, 'id'>) => {
  const item = progressItem.item as MultiChoiceItem;
  const answer = progressItem.answer as MultiChoiceAnswer;
  const correctAnswers = item.definition.choice.reduce((pointTotal, currentChoice) => {
    // If this choice is a correct answer and it was selected, then award a point.
    if (item.definition.solution?.indexOf(currentChoice) !== -1 && answer.value.choice?.indexOf(currentChoice) !== -1) {
      return pointTotal + 1;
    }
    // If this choice is a wrong answer and it was not selected, then award a point.
    if (item.definition.solution?.indexOf(currentChoice) === -1 && answer.value.choice?.indexOf(currentChoice) === -1) {
      return pointTotal + 1;
    }
    return pointTotal;
  }, 0);

  return (item.definition.points / item.definition.choice.length) * correctAnswers;
};

export const calculatePassingPoints = (totalPoints: number, passingScore: number) => {
  return Math.round((totalPoints / 100) * passingScore);
};
