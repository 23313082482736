import React from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { ItemGroup, ItemGroupDraft } from '../../models/itemGroup';
import { EditOutlined, FileAddOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { createRequiredRule } from '../../config/validation';
import { UpdateReferencesDialog } from '../UpdateReferencesDialog';

type FormModel = {
  name: string;
};

type Props = {
  itemGroup?: ItemGroup;
  saveItemGroup: (itemGroup: (ItemGroup & { additionalParams?: Record<string, any> }) | ItemGroupDraft) => void;
};

export const ItemGroupForm: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  const [tempItemGroup, setTempItemGroup] = React.useState<ItemGroup>();

  const [showUpdateReferencesDialog, setShowUpdateReferencesDialog] = React.useState(false);
  const toggleUpdateReferencesDialog = () => setShowUpdateReferencesDialog(!showUpdateReferencesDialog);

  const [form] = useForm<FormModel>();

  const requiredRule = createRequiredRule(intl);

  const isCreate = !props.itemGroup;

  React.useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({ ...props.itemGroup });
    }
  }, [isOpen, props.itemGroup, form]);

  const createButtons = () =>
    isCreate ? (
      <Button icon={<FileAddOutlined />} onClick={toggleModal}>
        <FormattedMessage id="action.create" values={{ entity: formatMessage({ id: 'model.itemGroup' }) }} children={(msg) => <span>{msg}</span>} />
      </Button>
    ) : (
      <Button icon={<EditOutlined />} onClick={toggleModal} />
    );

  const createTitle = () => formatMessage({ id: isCreate ? 'action.create' : 'action.update' }, { entity: formatMessage({ id: 'model.itemGroup' }) });

  const handleSave = (itemGroup: ItemGroup) => {
    if (itemGroup.usedIn?.length) {
      toggleUpdateReferencesDialog();
    } else {
      props.saveItemGroup(itemGroup);
    }
  };

  const confirmSave = (updateReferences?: boolean) => {
    props.saveItemGroup({ ...tempItemGroup, additionalParams: { update_references: updateReferences } });
  };

  const handleSubmit = (values: FormModel) => {
    const itemGroup = { ...props.itemGroup, ...values };
    setTempItemGroup(itemGroup);
    handleSave(itemGroup);
    setIsOpen(false);
  };

  return (
    <>
      {createButtons()}
      <Modal open={isOpen} onCancel={toggleModal} title={createTitle()} okText={formatMessage({ id: 'action.save' })} onOk={form.submit}>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item name="name" label={formatMessage({ id: 'model.attribute.name' })} rules={[requiredRule]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <UpdateReferencesDialog
        show={showUpdateReferencesDialog}
        onCancel={toggleUpdateReferencesDialog}
        onSaveWithoutUpdate={() => confirmSave()}
        onConfirmUpdateReferences={() => confirmSave(true)}
      />
    </>
  );
};
