import { ExportOutlined } from '@ant-design/icons';
import { Card, List, Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReportingCardContent } from '../../containers/Reporting';

export type Props = {
  cardContent: ReportingCardContent;
};

export const ReportingCard: React.FC<Props> = ({ cardContent }) => {
  const { formatMessage } = useIntl();

  return (
    <Card title={formatMessage({ id: 'action.export' }, { entity: formatMessage({ id: cardContent.titleKey }) })}>
      <List>
        {cardContent.options.map(({ titleKey, descriptionKey, onExport }) => (
          <List.Item key={titleKey}>
            <List.Item.Meta title={formatMessage({ id: titleKey })} description={formatMessage({ id: descriptionKey })} />
            <div>
              <Button icon={<ExportOutlined />} onClick={onExport}>
                <FormattedMessage id="action.export" values={{ entity: '' }} />
              </Button>
            </div>
          </List.Item>
        ))}
      </List>
    </Card>
  );
};
