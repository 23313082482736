import notificationReducer from './notification';
import sessionReducer from './session';
import translationReducer from './translation';
import transactionReducer from './user/transaction';

export const rootReducer = {
  notifications: notificationReducer,
  session: sessionReducer,
  transaction: transactionReducer,
  translation: translationReducer
};
