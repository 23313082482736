import { Client } from '../client/client';
import { Dispatch } from 'redux';
import { State } from '../store';

export const createClientAction =
  <T extends Client>(type: new (token?: string) => T, action: (client: T, dispatch: Dispatch, getState: () => State) => void) =>
  (dispatch: Dispatch, getState: () => State) => {
    let token;
    if (getState().transaction.token) {
      token = getState().transaction.token;
    }
    if (getState().session.isAuthenticated) {
      token = getState().session.token;
    }
    const clientInstance = token ? new type(token) : new type();
    action(clientInstance, dispatch, getState);
  };
