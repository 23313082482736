import { Participant } from './participant';
import { Certification } from './certification';

export interface ScheduledInvitation {
  id: number;
  reason: ScheduleReason;
  inviteAt: string;
  participant: Participant;
  certification: Certification;
  cancelledAt: string;
}

export enum ScheduleReason {
  expiration = 'expiration',
  failure = 'failure'
}
