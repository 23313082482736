import enUs from '../assets/i18n/en-us.json';
import deCh from '../assets/i18n/de-ch.json';
import de from 'antd/lib/locale/de_DE';
import en from 'antd/lib/locale/en_US';
import { Locale } from 'antd/lib/locale-provider';

type Language = {
  id: string;
  data: Record<string, any>;
  antd: Locale;
};

const languages: Language[] = [
  { id: 'en-us', data: enUs, antd: en },
  { id: 'de-ch', data: deCh, antd: de }
];

export default languages;

export const getMessages = (locale: string) => {
  const language = languages.find(l => l.id === locale);
  if(!language) {
    throw Error('Language for locale "' + locale + '" not found');
  }
  return language;
};
