/** @jsxImportSource @emotion/react */
import { Layout, Badge } from 'antd';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { PageHeader } from '@ant-design/pro-components';
import { css } from '@emotion/react';

const stageStyle = css`
  flex-grow: 1;
  padding: 0.5rem;

  .container-content {
    padding: 1rem;
  }
`;

interface Props {
  title: string;
  subTitle?: string;
  onBack?: () => void;
  onConfirmBack?: () => void;
  hasChanges?: boolean;
  headerActions?: ReactNode;
}

export const StageContainer: FC<PropsWithChildren<Props>> = (props) => {
  return (
    <main css={stageStyle}>
      <PageHeader
        title={props.title}
        subTitle={props.subTitle}
        tags={props.hasChanges && <Badge status="warning" />}
        extra={props.headerActions}
        onBack={props.hasChanges ? props.onConfirmBack : props.onBack}
      />
      <Layout.Content className="container-content">{props.children}</Layout.Content>
    </main>
  );
};
