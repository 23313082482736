import { Row, Spin } from 'antd';
import React from 'react';

export const Loading: React.FC = () => {
  return (
    <Row align='middle' justify='center' style={{ width: '100%' }}>
      <Spin size='large' />
    </Row>
  );
};
