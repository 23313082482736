import { RESTClient } from './client';
import { Bill, BillDraft } from '../models/bill';

export class BillClient extends RESTClient<Bill, BillDraft> {
  public constructor(token?: string) {
    super('bills', token);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public create(_entity: Bill): never {
    throw new Error('Not supported by API');
  }
}
