import React from 'react';
import { useIntl } from 'react-intl';
import { CertificationClient } from '../../client/certification';
import { Certification } from '../../models/certification';
import { Query } from '../../models/query';
import { APISearchableSelector } from './APISearchableSelector';
import { SelectorProps } from '../../services/selectorService';

type Props = {} & SelectorProps<Certification>;

export const CertificationSelector: React.FC<Props> = ({ value, onChange, selectProps }) => {
  const { formatMessage } = useIntl();

  return (
    <APISearchableSelector
      queryName={Query.CERTIFICATIONS}
      clientClass={CertificationClient}
      onChange={onChange}
      value={value}
      buildLabel={(c) => c.title}
      valueFieldName="rootId"
      selectProps={{ placeholder: formatMessage({ id: 'model.certification' }), ...selectProps }}
    />
  );
};
