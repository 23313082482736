import { Country, initialCountry } from './country';

interface CompanyBase {
  name: string;
  street: string;
  zip: string;
  town: string;
  administrativeEmail?: string;
  billable?: boolean;
  active: boolean;
}

export interface Company extends CompanyBase {
  id: number;
  country: Country;
}

export interface CompanyDraft extends CompanyBase {
  countryId: number;
}

export const initialCompany: Company = {
  id: undefined,
  name: undefined,
  street: undefined,
  zip: undefined,
  town: undefined,
  country: initialCountry,
  billable: true,
  active: true
};
