import { FileAddOutlined } from '@ant-design/icons';
import { Button, Modal, Form, InputNumber, Checkbox, Alert } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { createRequiredRule } from '../../config/validation';
import { Certification } from '../../models/certification';
import { Participant } from '../../models/participant';
import { ResultDraft } from '../../models/result';
import { CertificationSelector } from '../selectors/CertificationSelector';
import { ParticipantSelector } from '../selectors/ParticipantSelector';
import { addDays, endOfDay } from 'date-fns';
import { DatePicker } from '../DatePicker';

type FormModel = {
  participant: Participant;
  certification: Certification;
  achievedScore: number;
  reinvite: boolean;
  submittedAt: Date;
};

interface Props {
  addResult: (resultRequest: ResultDraft) => void;
}

export const ResultForm: React.FC<Props> = ({ addResult }) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const [form] = Form.useForm<FormModel>();

  const requiredRule = createRequiredRule(intl);
  const initialValues = {
    reinvite: true,
    submittedAt: new Date()
  };

  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);

  const actionButtonTitle = formatMessage({ id: 'action.add' }, { entity: formatMessage({ id: 'model.result' }) });

  const handleSubmit = (values: FormModel) => {
    addResult({
      ...values,
      certificationId: values.certification.id,
      participantId: values.participant.id,
      submittedAt: values.submittedAt.toISOString()
    });
    toggleModal();
  };

  return (
    <>
      <Button onClick={toggleModal} icon={<FileAddOutlined />}>
        {actionButtonTitle}
      </Button>
      <Modal
        open={open}
        onCancel={toggleModal}
        okText={formatMessage({ id: 'action.add' }, { entity: '' })}
        title={actionButtonTitle}
        onOk={() =>
          form
            .validateFields()
            .then(() => form.submit())
            .catch(() => null)
        }
        afterClose={form.resetFields}
      >
        <Form<FormModel> name="resultForm" layout="vertical" form={form} onFinish={handleSubmit} initialValues={initialValues}>
          <Form.Item name="participant" rules={[requiredRule]} label={formatMessage({ id: 'model.participant' })}>
            <ParticipantSelector activeParticipantsOnly />
          </Form.Item>
          <Form.Item name="certification" rules={[requiredRule]} label={formatMessage({ id: 'model.certification' })}>
            <CertificationSelector />
          </Form.Item>
          <Form.Item name="submittedAt" rules={[requiredRule]} label={formatMessage({ id: 'model.attribute.submittedAt' })}>
            <DatePicker disabledDate={(current) => current > endOfDay(addDays(new Date(), 1))} />
          </Form.Item>
          <Form.Item name="achievedScore" rules={[requiredRule]} label={formatMessage({ id: 'model.attribute.achievedScore' })}>
            <InputNumber
              placeholder={formatMessage({ id: 'model.attribute.passingScore' })}
              min={0}
              max={100}
              formatter={(value) => `${value}%`}
              parser={(value) => parseInt(value.replace('%', '')) as 0 | 100}
            />
          </Form.Item>
          <Form.Item<FormModel> noStyle shouldUpdate={(prev, curr) => prev.certification?.id !== curr.certification?.id || prev.achievedScore !== curr.achievedScore}>
            {({ getFieldsValue }) => {
              const { certification, achievedScore } = getFieldsValue();
              const hasPassed = achievedScore >= certification?.passingScore;
              return (
                certification &&
                !!achievedScore && (
                  <Alert
                    style={{ margin: '1rem 0' }}
                    message={formatMessage({ id: hasPassed ? 'alert.info.passedCertification' : 'alert.info.failedCertification' })}
                    type={hasPassed ? 'success' : 'warning'}
                    showIcon
                  />
                )
              );
            }}
          </Form.Item>
          <Form.Item name="reinvite" valuePropName="checked" label={formatMessage({ id: 'model.attribute.reinvite' })}>
            <Checkbox />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
