import * as React from 'react';
import { Form, Input, Divider } from 'antd';
import { useIntl } from 'react-intl';

type Props = { name: string[]; title: string };

export const TextTemplateForm: React.FC<Props> = (props) => {
  const intl = useIntl();
  return (
    <>
      <Divider>{props.title}</Divider>
      <Form.Item name={[...props.name, 'message']} label={intl.formatMessage({ id: 'model.attribute.message' })}>
        <Input.TextArea rows={5} maxLength={5000} placeholder={intl.formatMessage({ id: 'model.attribute.message' })} />
      </Form.Item>
    </>
  );
};
