import { Button, Tabs, TabsProps } from 'antd';
import * as React from 'react';
import { InvitationIndex } from '../../components/invitation/InvitationIndex';
import { FormattedMessage, useIntl } from 'react-intl';
import { useApiClient } from '../../hooks/client';
import { InvitationClient } from '../../client/invitation';
import { useIndexQuery } from '../../hooks/rootQueries';
import { Query } from '../../models/query';
import { ScheduledInvitationClient } from '../../client/scheduledInvitation';
import { createInitialParams, useQueryParams } from '../../models/queryParams';
import { ScheduledInvitationIndex } from '../../components/scheduledInvitation/ScheduledInvitationIndex';
import { Certification } from '../../models/certification';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import { RoutePath } from '../Router';
import { StageContainer } from '../../components/layout/StageContainer';
import { MailOutlined } from '@ant-design/icons';
import { useCancelMutation, useManuallyReinviteMutation } from '../../hooks/invitations';
interface Props {
  certifications: Certification[];
}

const TabbedInvitations: React.FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  const navigate = useNavigate();
  const params = useParams();

  const activeTab = params.activeTab !== 'scheduled' ? 'sent' : 'scheduled';

  const initialParams = createInitialParams('invitations.id');
  const [queryParams, setQueryParams] = useQueryParams(initialParams);

  const invitationClient = useApiClient(InvitationClient);
  const { data: invitationIndex } = useIndexQuery(Query.INVITATIONS, invitationClient, queryParams, activeTab === 'sent');

  const scheduledInvitationClient = useApiClient(ScheduledInvitationClient);
  const { data: scheduledInvitationIndex, isRefetching: isRefetchingScheduledInvitations } = useIndexQuery(
    Query.SCHEDULED_INVITATIONS,
    scheduledInvitationClient,
    queryParams,
    activeTab === 'scheduled'
  );

  const reinviteMutation = useManuallyReinviteMutation(invitationClient, queryParams);
  const cancelInvitationMutation = useCancelMutation(invitationClient, Query.INVITATIONS, queryParams);
  const cancelScheduledInvitationMutation = useCancelMutation(scheduledInvitationClient, Query.SCHEDULED_INVITATIONS, queryParams);

  const openInvite = () => {
    navigate(`${RoutePath.INVITATIONS}/invite`);
  };

  const handleTabChange = (activeKey: string) => {
    if (activeTab !== activeKey) {
      switch (activeKey) {
        case 'scheduled':
          setQueryParams(createInitialParams('scheduled_invitations.id'));
          navigate('scheduled');
          break;
        case 'sent':
          setQueryParams(initialParams);
          navigate(RoutePath.INVITATIONS);
          break;
        default:
          break;
      }
    }
  };

  const tabItems: TabsProps['items'] = [
    {
      label: formatMessage({ id: 'view.invitations.sentInvitations' }),
      key: 'sent',
      children: (
        <InvitationIndex
          index={invitationIndex}
          certifications={props.certifications}
          onReinvite={(id, validUntil) => reinviteMutation.mutate({ id, validUntil })}
          onCancel={cancelInvitationMutation.mutate}
        />
      )
    },
    {
      label: formatMessage({ id: 'model.scheduledInvitations' }),
      key: 'scheduled',
      children: (
        <ScheduledInvitationIndex
          index={scheduledInvitationIndex}
          loading={isRefetchingScheduledInvitations}
          certifications={props.certifications}
          onCancel={cancelScheduledInvitationMutation.mutate}
        />
      )
    }
  ];

  return (
    <StageContainer
      title={formatMessage({ id: 'model.invitations' })}
      headerActions={
        <Button onClick={openInvite} icon={<MailOutlined />}>
          <FormattedMessage id="action.invite" values={{ entity: formatMessage({ id: 'model.participants' }) }} children={(msg) => <span>{msg}</span>} />
        </Button>
      }
    >
      <Tabs defaultActiveKey={activeTab} onChange={handleTabChange} items={tabItems} />
    </StageContainer>
  );
};

export default TabbedInvitations;
