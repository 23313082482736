import * as React from 'react';
import styled from '@emotion/styled';
import { Descriptions, Alert, Button, Space } from 'antd';
import { useSelector } from 'react-redux';
import { Transaction, calculatePointMaximum, calculatePassingPoints, calculateAchievedPoints } from '../../models/transaction';
import { CertificationResult } from '../../components/certification/CertificationResults';
import { StageContainer } from '../../components/layout/StageContainer';
import { FormattedMessage, useIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { deleteTransaction } from '../../features/user/transaction';
import { Navigate, useNavigate } from 'react-router';
import Paragraph from 'antd/lib/typography/Paragraph';
import { State, useAppDispatch } from '../../store';

const Container = styled.div`
  margin: 30px auto 0 auto;
  padding: 20px;
  min-width: 800px;
  width: 80%;
  max-width: 1200px;
`;

const CustomAlert = styled(Alert)`
  .ant-alert-message {
    font-weight: bold;
  }

  margin-bottom: 50px;
`;

const VerticalSpace = styled.div`
  margin-bottom: 50px;
`;

const CustomParagraph = styled(Paragraph)`
  white-space: pre-line;
`;

export const TransactionResult: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const transactionState = useSelector<State, Transaction>((state) => state.transaction);
  const dispatch = useAppDispatch();
  const actions = bindActionCreators({ deleteTransaction }, dispatch);
  const [showResults, isShowResults] = React.useState(false);

  if (!transactionState.progress) {
    return <Navigate to="/participate" />;
  }

  const totalPoints = calculatePointMaximum(transactionState.progress.responseItems);
  const passingPoints = calculatePassingPoints(totalPoints, transactionState.progress.certification.passingScore);
  const achievedPoints = calculateAchievedPoints(transactionState.progress.responseItems);

  const handleLeave = () => {
    navigate('/participate/redirect');
    actions.deleteTransaction();
    window.location.assign('https://www.haslerrail.com/');
  };

  const toggleResults = () => isShowResults(!showResults);

  return (
    <StageContainer title={transactionState.progress.certification.title} subTitle={intl.formatMessage({ id: 'model.results' })}>
      <Container>
        {achievedPoints >= passingPoints ? (
          <CustomAlert
            message={intl.formatMessage({ id: 'view.result.passedTitle' })}
            description={intl.formatMessage({ id: 'view.result.passed' })}
            type="success"
            showIcon={true}
          />
        ) : (
          <CustomAlert
            message={intl.formatMessage({ id: 'view.result.failedTitle' })}
            description={intl.formatMessage({ id: 'view.result.failed' })}
            type="error"
            showIcon={true}
          />
        )}
        {transactionState.progress.certification.finalPageTextTemplate ? (
          <VerticalSpace>
            <CustomParagraph>{transactionState.progress.certification.finalPageTextTemplate.message}</CustomParagraph>
          </VerticalSpace>
        ) : null}
        {showResults ? (
          <VerticalSpace>
            <Descriptions title={intl.formatMessage({ id: 'model.results' })}>
              <Descriptions.Item label={intl.formatMessage({ id: 'model.attribute.score' })}>{achievedPoints}</Descriptions.Item>
              <Descriptions.Item label={intl.formatMessage({ id: 'model.attribute.passingScore' })}>
                {`${transactionState.progress.certification.passingScore}% (${passingPoints} ${intl.formatMessage({
                  id: 'model.attribute.points'
                })})`}
              </Descriptions.Item>
              <Descriptions.Item label={intl.formatMessage({ id: 'view.amountOfPoints' })}>{totalPoints}</Descriptions.Item>
            </Descriptions>
            <CertificationResult responseItems={transactionState.progress.responseItems} />
          </VerticalSpace>
        ) : null}
        <Space>
          <Button onClick={toggleResults} type="primary">
            <FormattedMessage id={showResults ? 'action.hide' : 'action.show'} values={{ entity: intl.formatMessage({ id: 'model.results' }).toLowerCase() }} />
          </Button>
          <Button onClick={handleLeave}>
            <FormattedMessage id="action.leave" values={{ entity: intl.formatMessage({ id: 'model.certification' }).toLowerCase() }} />
          </Button>
        </Space>
      </Container>
    </StageContainer>
  );
};
