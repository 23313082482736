import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import TabbedInvitations from './invitation/TabbedInvitations';
import { useApiClient } from '../hooks/client';
import { useIndexQuery } from '../hooks/rootQueries';
import { Query } from '../models/query';
import { CertificationClient } from '../client/certification';
import { InviteContainer } from './invitation/Invite';

const InvitationContainer: React.FC = () => {
  const certificationClient = useApiClient(CertificationClient);
  const { data: certificationIndex } = useIndexQuery(Query.CERTIFICATIONS, certificationClient, {});

  const Tabs = <TabbedInvitations certifications={certificationIndex.data} />;

  const Invite = <InviteContainer />;

  return (
    <Routes>
      <Route path="" element={Tabs} />
      <Route path="invite" element={Invite} />
      <Route path=":activeTab" element={Tabs} />
    </Routes>
  );
};

export default InvitationContainer;
