import * as React from 'react';
import { User } from '../../models/user';
import { Table, Button, Popconfirm, Space } from 'antd';
import { useIntl } from 'react-intl';
import { DeleteOutlined } from '@ant-design/icons';
import { UserForm } from './UserForm';
import { useDynamicTable, IndexProps } from '../../services/indexService';
import { paginationLayoutConfig } from '../../config/pagination';

interface Props extends IndexProps<User> {
  currentUser?: User;
  updateUser: (user: User) => void;
  deleteUser: (user: User) => void;
}

export const UserIndex: React.FC<Props> = (props) => {
  const intl = useIntl();

  const { handleTableChanges, getSortColumnProps } = useDynamicTable();

  const createDeleteAction = (user: User) => {
    return () => {
      props.deleteUser(user);
    };
  };

  const renderActions = (record: User) => {
    return (
      <>
        <Space.Compact>
          <UserForm saveUser={props.updateUser} user={record} />
          {props.currentUser && props.currentUser.id !== record.id ? (
            <Popconfirm title={intl.formatMessage({ id: 'action.confirm.deletion' })} onConfirm={createDeleteAction(record)} placement="left">
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
          ) : null}
        </Space.Compact>
      </>
    );
  };

  return (
    <Table dataSource={props.index.data} pagination={{ ...paginationLayoutConfig, ...props.index.meta }} onChange={handleTableChanges} rowKey="id">
      <Table.Column title={intl.formatMessage({ id: 'model.id' })} dataIndex="id" width={80} {...getSortColumnProps('users.id')} />
      <Table.Column title={intl.formatMessage({ id: 'model.attribute.email' })} dataIndex="email" key="email" />
      <Table.Column title={intl.formatMessage({ id: 'model.attribute.forename' })} dataIndex="forename" key="forename" />
      <Table.Column title={intl.formatMessage({ id: 'model.attribute.surname' })} dataIndex="surname" key="surname" />
      <Table.Column title={intl.formatMessage({ id: 'model.attribute.functionalRole' })} dataIndex="functionalRole" key="functionalRole" />
      <Table.Column title={intl.formatMessage({ id: 'actions' })} key="actions" render={renderActions} width="200px" align="center" />
    </Table>
  );
};
