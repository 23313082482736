import { User, UserDraft } from '../models/user';
import { RESTClient } from './client';

export class UserClient extends RESTClient<User> {
  public constructor(token?: string) {
    super('users', token);
  }

  public create(entity: UserDraft) {
    if (entity.signature) {
      return this.clientInstance.post<User>(`${this.path}`, this.getAsFormData(entity), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } else {
      return super.create(entity);
    }
  }

  public update(entity: User) {
    if (entity.signature) {
      return this.clientInstance.put<User>(`${this.path}/${entity.id}`, this.getAsFormData(entity), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } else {
      return super.update(entity);
    }
  }
}
