import { StageContainer } from '../components/layout/StageContainer';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { Route, Routes, useNavigate } from 'react-router';
import { CertificationIndex } from '../components/certification/CertificationIndex';
import CertificationSettingsContainer from './certification/CertificationSettings';
import { useApiClient } from '../hooks/client';
import { CertificationClient } from '../client/certification';
import { useDeleteMutation, useIndexQuery } from '../hooks/rootQueries';
import { Query } from '../models/query';
import { UserClient } from '../client/user';
import { createInitialParams, SortOrder, useQueryParams } from '../models/queryParams';
import { Certification } from '../models/certification';
import { CertificationCreateContainer } from './certification/CertificationCreate';
import { CertificationEditContainer } from './certification/CertificationEdit';
import { Button } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';

const CertificationContainer: React.FC = () => {
  const intl = useIntl();

  const navigate = useNavigate();

  const initialParams = createInitialParams('certifications.id');
  const [queryParams] = useQueryParams(initialParams);

  const certificationClient = useApiClient(CertificationClient);
  const { data: certificationIndex } = useIndexQuery(Query.CERTIFICATIONS, certificationClient, queryParams);

  const deleteMutation = useDeleteMutation(Query.CERTIFICATIONS, certificationClient, { attributeKey: 'title', attributeTranslation: 'model.attribute.title' });

  const userClient = useApiClient(UserClient);
  const { data: usersIndex } = useIndexQuery(Query.USERS, userClient, { sorting: { field: 'users.forename', order: SortOrder.ascend } });

  const openEdit = (certificationToEdit: Certification) => {
    navigate(`edit/${certificationToEdit.id}`);
  };

  const openSettings = (id: number) => {
    navigate(`settings/${id}`);
  };

  const openCreate = () => {
    navigate('new');
  };

  const Index = (
    <StageContainer
      title={intl.formatMessage({ id: 'view.certification' })}
      headerActions={
        <Button onClick={openCreate} icon={<FileAddOutlined />}>
          {intl.formatMessage({ id: 'action.create' }, { entity: intl.formatMessage({ id: 'model.certification' }) })}
        </Button>
      }
    >
      <CertificationIndex index={certificationIndex} deleteCertification={deleteMutation.mutate} openSettings={openSettings} openEdit={openEdit} users={usersIndex.data} />
    </StageContainer>
  );

  const Settings = <CertificationSettingsContainer />;

  const Create = <CertificationCreateContainer />;

  const Edit = <CertificationEditContainer />;

  return (
    <Routes>
      <Route path="/" element={Index} />
      <Route path="new" element={Create} />
      <Route path="edit/:id" element={Edit} />
      <Route path="settings/:id" element={Settings} />
    </Routes>
  );
};

export default CertificationContainer;
