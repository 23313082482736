import { IntlShape } from 'react-intl';
import { Rule } from 'antd/lib/form';
import { Participant } from '../models/participant';
import { User } from '../models/user';
import { isAfter, addDays } from 'date-fns';

export const createRequiredRule = (intl: IntlShape): Rule => ({
  required: true,
  message: intl.formatMessage({ id: 'validation.requiredButEmpty' })
});

export const createMinDateRule = (message: string, minDays: number): Rule => {
  const minValidator = (_rule: Rule, value: Date) => {
    console.log(value);

    return new Promise<void>((resolve, reject) => {
      if (isAfter(value, addDays(new Date(), minDays))) {
        resolve();
      } else {
        reject(message);
      }
    });
  };

  return {
    type: 'date',
    message: message,
    validator: minValidator
  };
};

export const createMinRule = (intl: IntlShape, min: number): Rule => {
  const minValidator = (_rule: Rule, value: string) => {
    const numberValue = Number(value);
    return new Promise<void>((resolve, reject) => {
      if (numberValue !== undefined && numberValue < min) {
        reject('Number not in range');
      } else {
        resolve();
      }
    });
  };

  return {
    type: 'number',
    message: intl.formatMessage({ id: 'validation.minNumber' }, { min }),
    validator: minValidator
  };
};

export const createMinMaxRule = (intl: IntlShape, min: number, max: number): Rule => {
  const minMaxValidator = (_rule: Rule, value: string) => {
    const numberValue = Number(value);
    return new Promise<void>((resolve, reject) => {
      if (numberValue !== undefined && (numberValue < min || numberValue > max)) {
        reject('Number not in range');
      } else {
        resolve();
      }
    });
  };

  return {
    type: 'number',
    message: intl.formatMessage({ id: 'validation.numberRange' }, { min, max }),
    validator: minMaxValidator
  };
};

export const createUniqueRule = (intl: IntlShape, existingValues: any[], caseSensitive = false, customMessage?: string): Rule => {
  const uniqueValidator = (_rule: Rule, value: any) => {
    return new Promise<void>((resolve, reject) => {
      if (caseSensitive ? existingValues.map((v) => v.toLowerCase()).includes(value.toLowerCase()) : existingValues.includes(value)) {
        reject('Not unique');
      } else {
        resolve();
      }
    });
  };

  return {
    type: 'string',
    message: customMessage ?? intl.formatMessage({ id: 'validation.unique' }),
    validator: uniqueValidator
  };
};

export const createEmailRule = (intl: IntlShape, participants: Participant[]) =>
  createUniqueRule(
    intl,
    participants.map((p) => p.email.toLowerCase()),
    true
  );

export const createRepresentativeValidationRule = (users: User[], formatMessage): Rule => {
  const representativeValidator = (_rule: Rule, userId: string) => {
    const userIdValue = Number(userId);
    return new Promise<void>((resolve, reject) => {
      const selectedRepresentative = users.find((u) => u.id === userIdValue);
      if (!userIdValue) {
        resolve();
      }
      if (selectedRepresentative.signatureUrl && selectedRepresentative.functionalRole) {
        resolve();
      } else {
        reject('Needs to have a signature and a functional role');
      }
    });
  };

  return {
    type: 'number',
    message: formatMessage({ id: 'validation.representativeNeedsSignatureAndRole' }),
    validator: representativeValidator
  };
};
