import { message } from 'antd';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { ItemClient } from '../client/item';
import { Item } from '../models/item';
import { Query } from '../models/query';

export const useMoveItemsMutation = (client: ItemClient, items: Item[]) => {
  const { formatMessage } = useIntl();

  return useMutation(
    [Query.ITEMS, 'move'],
    (itemsDestination: { certification?: number; itemGroup?: number }) =>
      itemsDestination.certification
        ? client.moveToCertification(
            items.map(({ id }) => id),
            itemsDestination.certification
          )
        : client.moveToItemGroup(
            items.map(({ id }) => id),
            itemsDestination.itemGroup
          ),
    {
      onSuccess: (_data, variables) => {
        message.success(
          formatMessage(
            { id: 'notification.success.movedItems' },
            {
              itemCollection: variables.certification ? formatMessage({ id: 'model.certification' }) : formatMessage({ id: 'model.itemGroup' }),
              destinationId: variables.certification ?? variables.itemGroup
            }
          )
        );
      }
    }
  );
};
