import { DescriptionItem } from '../../../models/item';
import * as React from 'react';
import Paragraph from 'antd/lib/typography/Paragraph';
import styled from '@emotion/styled';

const CustomParagraph = styled(Paragraph)`
  font-size: 1.2em;
  white-space: pre-line;
`;

interface Props {
  item: DescriptionItem;
}

export const DescriptionItemInput: React.FC<Props> = (props) => {
  return <CustomParagraph>{props.item.definition.description}</CustomParagraph>;
};
