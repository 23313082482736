import { StageContainer } from '../../components/layout/StageContainer';
import * as React from 'react';
import { showTransaction } from '../../features/user/transaction';
import { useSelector } from 'react-redux';
import { calculateAchievedPoints, calculatePassingPoints, calculatePointMaximum, Transaction } from '../../models/transaction';
import { Descriptions, Spin } from 'antd';
import { useIntl } from 'react-intl';
import { CertificationResult } from '../../components/certification/CertificationResults';
import { useNavigate, useParams } from 'react-router';
import { RoutePath } from '../Router';
import { State, useAppDispatch } from '../../store';

export const ResultDisplayContainer: React.FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const transactionState = useSelector<State, Transaction>((state) => state.transaction);
  const params = useParams();

  React.useEffect(() => {
    dispatch(showTransaction(parseInt(params.id)));
  }, [dispatch, params.id]);

  if (!transactionState.progress) {
    return <Spin />;
  }

  const totalPoints = calculatePointMaximum(transactionState.progress.responseItems);
  const passingPoints = calculatePassingPoints(totalPoints, transactionState.progress.certification.passingScore);
  const achievedPoints = calculateAchievedPoints(transactionState.progress.responseItems);

  return (
    <StageContainer
      title={intl.formatMessage({ id: 'model.results' })}
      subTitle={`${transactionState.progress?.participant.forename} ${transactionState.progress?.participant.surname}`}
      onBack={() => navigate(RoutePath.RESULTS)}
    >
      <Descriptions title={intl.formatMessage({ id: 'model.results' })}>
        <Descriptions.Item label={intl.formatMessage({ id: 'model.attribute.score' })}>{achievedPoints}</Descriptions.Item>
        <Descriptions.Item label={intl.formatMessage({ id: 'model.attribute.passingScore' })}>
          {`${transactionState.progress.certification.passingScore}% (${passingPoints} ${intl.formatMessage({
            id: 'model.attribute.points'
          })})`}
        </Descriptions.Item>
        <Descriptions.Item label={intl.formatMessage({ id: 'view.amountOfPoints' })}>{totalPoints}</Descriptions.Item>
      </Descriptions>
      <CertificationResult responseItems={transactionState.progress.responseItems} />
    </StageContainer>
  );
};
