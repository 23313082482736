import * as React from 'react';
import { Input, Button, Form } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { createRequiredRule } from '../../config/validation';

interface FormModel {
  email: string;
  password: string;
}

interface Props {
  signIn: (email: string, password: string) => void;
}

export const SessionForm: React.FC<Props> = (props) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const requiredRule = createRequiredRule(intl);

  const handleSubmit = (values: FormModel) => {
    props.signIn(values.email, values.password);
  };

  return (
    <Form name='createSession' form={form} onFinish={handleSubmit}>
      <Form.Item name='email' rules={[requiredRule]}>
        <Input prefix={<UserOutlined />} placeholder={intl.formatMessage({ id: 'model.attribute.email' })} />
      </Form.Item>
      <Form.Item name='password' rules={[requiredRule]}>
        <Input.Password
          prefix={<LockOutlined />}
          placeholder={intl.formatMessage({ id: 'model.attribute.password' })}
        />
      </Form.Item>
      <Form.Item>
        <Button type='primary' htmlType='submit'>
          <FormattedMessage id='action.signIn' />
        </Button>
      </Form.Item>
    </Form>
  );
};
