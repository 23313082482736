import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, Form, Modal, Radio, Space } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { CertificationSelector } from '../selectors/CertificationSelector';
import { useForm } from 'antd/lib/form/Form';
import { Certification } from '../../models/certification';
import { ItemGroup } from '../../models/itemGroup';
import { ItemGroupSelector } from '../selectors/ItemGroupSelector';
import { createRequiredRule } from '../../config/validation';
import { Item } from '../../models/item';

enum ItemCollectionType {
  certification = 'certification',
  itemGroup = 'itemGroup'
}

const FullWidthRadioGroup = styled(Radio.Group)`
  display: flex;
  > * {
    flex: 1;
    text-align: center;
  }
`;

type FormModel = {
  certification: Certification;
  itemGroup: ItemGroup;
};

type Props = {
  selectedItems: Item[];
  currentItemGroup?: ItemGroup;
  onSubmit: (itemDestination: { certification?: number; itemGroup?: number }) => void;
};

export const MoveItemsDialog: React.FC<Props> = ({ selectedItems, currentItemGroup, onSubmit }) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const [form] = useForm<FormModel>();
  const requiredRule = createRequiredRule(intl);

  const [showDialog, setShowDialog] = React.useState(false);
  const toggleShowDialog = () => setShowDialog(!showDialog);

  const [selectedCollectionType, setSelectedCollectionType] = React.useState(ItemCollectionType.itemGroup);

  const resolveSelector = () => {
    switch (selectedCollectionType) {
      case ItemCollectionType.certification:
        return (
          <Form.Item
            name="certification"
            rules={[requiredRule]}
            label={formatMessage({ id: 'view.chooseCollectionToMoveTo' }, { itemCollection: formatMessage({ id: 'model.certification' }) })}
          >
            <CertificationSelector />
          </Form.Item>
        );
      case ItemCollectionType.itemGroup:
        return (
          <Form.Item
            name="itemGroup"
            rules={[requiredRule]}
            label={formatMessage({ id: 'view.chooseCollectionToMoveTo' }, { itemCollection: formatMessage({ id: 'model.itemGroup' }) })}
          >
            <ItemGroupSelector />
          </Form.Item>
        );
      default:
        return <></>;
    }
  };

  const handleSubmit = (values: FormModel) => {
    onSubmit({ certification: values.certification?.id, itemGroup: values.itemGroup?.id });
    setShowDialog(false);
  };

  return (
    <>
      <Button type="primary" onClick={toggleShowDialog} icon={<SendOutlined />}>
        {formatMessage({ id: 'action.moveItems' })}
      </Button>
      <Modal
        title={formatMessage({ id: 'action.moveItems' })}
        open={showDialog}
        onCancel={toggleShowDialog}
        okText={formatMessage({ id: 'action.moveItems' })}
        onOk={() =>
          form
            .validateFields()
            .then(() => form.submit())
            .catch(() => null)
        }
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <FormattedMessage id="view.amountItemsSelected" values={{ amount: selectedItems.length }} />

          {currentItemGroup?.usedIn.length && (
            <Alert
              description={
                <>
                  <FormattedMessage id="view.itemGroupUsedInCertifications" />
                  <ul>
                    {currentItemGroup.usedIn.map((certification) => (
                      <li>
                        {certification.title} ({formatMessage({ id: 'model.id' })}: {certification.id})
                      </li>
                    ))}
                  </ul>
                  <FormattedMessage id="view.checkForConflictsAfterMoving" />
                </>
              }
              type="info"
              showIcon
            />
          )}

          <FullWidthRadioGroup
            options={[
              { label: formatMessage({ id: 'model.itemGroup' }), value: ItemCollectionType.itemGroup },
              { label: formatMessage({ id: 'model.certification' }), value: ItemCollectionType.certification }
            ]}
            optionType="button"
            value={selectedCollectionType}
            onChange={({ target: { value } }) => {
              setSelectedCollectionType(value);
              form.resetFields();
            }}
          />
          <Form<FormModel> form={form} layout="vertical" onFinish={handleSubmit}>
            {resolveSelector()}
          </Form>
        </Space>
      </Modal>
    </>
  );
};
