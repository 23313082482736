import React from 'react';
import { useIntl } from 'react-intl';
import { CountryClient } from '../../client/country';
import { Country } from '../../models/country';
import { Query } from '../../models/query';
import { SelectorProps } from '../../services/selectorService';
import { APISearchableSelector } from './APISearchableSelector';

type Props = {} & SelectorProps<Country>;

export const CountrySelector: React.FC<Props> = ({ onChange, value, selectProps }) => {
  const { formatMessage } = useIntl();

  return (
    <APISearchableSelector
      queryName={Query.COUNTRIES}
      clientClass={CountryClient}
      onChange={onChange}
      value={value}
      buildLabel={(c) => c.name}
      selectProps={{ placeholder: formatMessage({ id: 'model.country' }), ...selectProps }}
    />
  );
};
