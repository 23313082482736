import React from 'react';
import { useIntl } from 'react-intl';
import { CompanyClient } from '../../client/company';
import { Company } from '../../models/company';
import { Query } from '../../models/query';
import { SelectorProps } from '../../services/selectorService';
import { APISearchableSelector } from './APISearchableSelector';

type Props = {} & SelectorProps<Company>;

export const CompanySelector: React.FC<Props> = ({ value, onChange, selectProps }) => {
  const { formatMessage } = useIntl();

  return (
    <APISearchableSelector
      queryName={Query.COMPANIES}
      clientClass={CompanyClient}
      onChange={onChange}
      value={value}
      buildLabel={(c) => c.name}
      selectProps={{ placeholder: formatMessage({ id: 'model.company' }), ...selectProps }}
    />
  );
};
