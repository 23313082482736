import React from 'react';
import { Button, Form, InputNumber, Modal, Switch } from 'antd';
import { useIntl } from 'react-intl';
import { EditOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { createRequiredRule } from '../../config/validation';
import { useForm } from 'antd/lib/form/Form';
import { CertificationItemGroup, CertificationItemGroupDraft } from '../../models/certificationItemGroup';
import { ItemGroup } from '../../models/itemGroup';
import { ItemGroupSelector } from '../selectors/ItemGroupSelector';

type FormModel = {
  itemGroup: ItemGroup;
  randomize: boolean;
  amountOfToAsk: number;
};

type Props = {
  certificationItemGroup?: CertificationItemGroup;
  saveItemGroup: (certificationItemGroup: CertificationItemGroupDraft) => void;
};

export const CertificationItemGroupForm: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const isCreate = !props.certificationItemGroup;

  const [form] = useForm<FormModel>();

  const requiredRule = createRequiredRule(intl);

  const [showModal, setShowModal] = React.useState(false);
  const toggleModal = () => setShowModal(!showModal);

  React.useEffect(() => {
    if (showModal) {
      form.setFieldsValue({ ...props.certificationItemGroup });
    }
  }, [showModal, props.certificationItemGroup, form]);

  const handleSubmit = (values: FormModel) => {
    if (!values.randomize) {
      values.amountOfToAsk = undefined;
    }
    props.saveItemGroup({ ...props.certificationItemGroup, ...values, ...(isCreate && { uniqueKey: Math.random() }) });
    setShowModal(false);
  };

  const createTitle = () => formatMessage({ id: isCreate ? 'action.reference' : 'action.update' }, { entity: formatMessage({ id: 'model.itemGroup' }) });

  const createButton = () =>
    isCreate ? (
      <Button icon={<FolderOpenOutlined />} onClick={toggleModal}>
        {createTitle()}
      </Button>
    ) : (
      <Button icon={<EditOutlined />} onClick={toggleModal} />
    );

  return (
    <>
      {createButton()}
      <Modal title={createTitle()} open={showModal} onOk={form.submit} okText={formatMessage({ id: 'action.save' })} onCancel={toggleModal} afterClose={form.resetFields}>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item name="itemGroup" label={formatMessage({ id: 'model.itemGroup' })} rules={[requiredRule]}>
            <ItemGroupSelector />
          </Form.Item>
          <Form.Item name="randomize" label={formatMessage({ id: 'model.attribute.randomize' })} valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, curr) => prev.itemGroup?.id !== curr.itemGroup?.id || prev.randomize !== curr.randomize}>
            {({ getFieldsValue }) =>
              getFieldsValue().randomize && (
                <Form.Item name="amountOfToAsk" label={formatMessage({ id: 'model.attribute.amountOfToAsk' })}>
                  <InputNumber min={0} max={getFieldsValue().itemGroup?.totalItems} />
                </Form.Item>
              )
            }
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
