import { UnorderedListOutlined } from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ItemGroupClient } from '../../client/itemGroup';
import { useApiClient } from '../../hooks/client';
import { useReadQuery } from '../../hooks/rootQueries';
import { Item } from '../../models/item';
import { ItemGroup } from '../../models/itemGroup';
import { Query } from '../../models/query';
import { ItemGroupSelector } from '../selectors/ItemGroupSelector';
import { ItemsEditor } from './ItemsEditor';

type Props = {
  onSubmit: (items: Item[]) => void;
};

export const ReferenceItemsForm: React.FC<Props> = ({ onSubmit }) => {
  const { formatMessage } = useIntl();

  const [showModal, setShowModal] = React.useState(false);
  const toggleModal = () => setShowModal(!showModal);

  const [selectedItemGroup, setSelectedItemGroup] = React.useState<ItemGroup>();

  const itemGroupClient = useApiClient(ItemGroupClient);
  const { data } = useReadQuery(Query.ITEM_GROUPS, itemGroupClient, selectedItemGroup);

  const [selectedItems, setSelectedItems] = React.useState<Item[]>([]);

  const clearModal = () => {
    toggleModal();
    setSelectedItems(undefined);
    setSelectedItemGroup(undefined);
  };

  const handleSubmit = () => {
    onSubmit(selectedItems);
    clearModal();
  };

  const title = formatMessage({ id: 'action.reference' }, { entity: formatMessage({ id: 'model.items' }) });

  return (
    <>
      <Button onClick={toggleModal} icon={<UnorderedListOutlined />}>
        {title}
      </Button>
      <Modal title={title} width="80%" open={showModal} onCancel={clearModal} okText={formatMessage({ id: 'action.addReferences' })} onOk={handleSubmit}>
        <Space direction="vertical" size="middle">
          <ItemGroupSelector value={selectedItemGroup} selectProps={{ style: { width: '30rem' } }} onChange={(itemGroup: ItemGroup) => setSelectedItemGroup(itemGroup)} />

          {selectedItemGroup && (
            <>
              <ItemsEditor onSelectionChange={setSelectedItems} items={data?.items} selectable />
              <FormattedMessage id="view.amountItemsSelected" values={{ amount: selectedItems?.length }} children={(msg) => <p>{msg}</p>} />
            </>
          )}
        </Space>
      </Modal>
    </>
  );
};
