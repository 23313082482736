import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import { rootReducer } from './features/rootReducer';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import storage from 'redux-persist/es/storage';
import { appName, localStorageVersion } from './config/constants';

const applicationReducer = combineReducers(rootReducer);

const persistentApplicationStoreConfig: PersistConfig<never> = {
  key: appName,
  version: localStorageVersion,
  whitelist: ['transaction', 'session'],
  storage
};

const persistentReducer = persistReducer(persistentApplicationStoreConfig, applicationReducer);

export const store = configureStore({
  reducer: persistentReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export const persistor = persistStore(store);

export type State = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
