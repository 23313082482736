import { StageContainer } from '../components/layout/StageContainer';
import { useIntl } from 'react-intl';
import * as React from 'react';
import { CompanyIndex } from '../components/company/CompanyIndex';
import { CompanyForm } from '../components/company/CompanyForm';
import { CustomNotificationProps, useCreateMutation, useIndexQuery, useUpdateMutation } from '../hooks/rootQueries';
import { Query } from '../models/query';
import { useApiClient } from '../hooks/client';
import { CompanyClient } from '../client/company';
import { createInitialParams, useQueryParams } from '../models/queryParams';
import { Company } from '../models/company';

const CompanyContainer: React.FC = () => {
  const intl = useIntl();

  const initialParams = createInitialParams('companies.id');
  const [queryParams] = useQueryParams(initialParams);

  const companyClient = useApiClient(CompanyClient);
  const { data: companyIndex } = useIndexQuery(Query.COMPANIES, companyClient, queryParams);

  const notificationProps: CustomNotificationProps<Company> = { attributeKey: 'name', attributeTranslation: 'model.attribute.name' };

  const updateMutation = useUpdateMutation(Query.COMPANIES, companyClient, notificationProps);
  const createMutation = useCreateMutation(Query.COMPANIES, companyClient, initialParams, notificationProps);

  return (
    <StageContainer title={intl.formatMessage({ id: 'model.companies' })} headerActions={<CompanyForm saveCompany={createMutation.mutate} />}>
      <CompanyIndex index={companyIndex} updateCompany={updateMutation.mutate} />
    </StageContainer>
  );
};

export default CompanyContainer;
