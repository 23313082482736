import { User } from './user';

export interface Session {
  token: string;
  subject: string;
  expiration: number;
  isAuthenticated: boolean;
  currentUser?: User;
  isOffline?: boolean;
}

export const initialSession: Session = {
  token: '',
  subject: '',
  expiration: 0,
  isAuthenticated: false
};
