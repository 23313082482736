export interface PaginationMeta {
  current: number;
  total: number;
  pageSize: number;
}

export const initialPaginationMeta = {
  current: undefined,
  total: undefined,
  pageSize: undefined
}