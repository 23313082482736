import { Typography } from 'antd';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export const NoDataText: React.FC = () => {
  return (
    <Typography.Text disabled={true}>
      <FormattedMessage id='misc.undefined' />
    </Typography.Text>
  );
};
