import { useMutation } from 'react-query';
import { ReportClient, ReportType } from '../client/report';
import { saveAs } from 'file-saver';

const getFileName = (contentDisposition: string) => contentDisposition.split('filename=')[1].split(';')[0];

const encodeAsUTF16 = (value: string) => {
  const buffer = new ArrayBuffer(value.length * 2);
  const bufferView = new Uint16Array(buffer);
  
  value.split('').forEach((c, i) => bufferView[i] = c.charCodeAt(0));

  return bufferView;
}

export const useDownloadReportMutation = (client: ReportClient) => {
  return useMutation((reportType: ReportType) => client.getReportFile(reportType), {
    onSuccess: (response) => {
      const fileName = getFileName(response.headers['content-disposition']);
      const file = new File([encodeAsUTF16(response.data)], fileName, { type: response.headers['content-type'] });
      saveAs(file, fileName);
    }
  });
};
