import { Global } from '@emotion/react';
import { ConfigProvider, theme } from 'antd';
import { PropsWithChildren } from 'react';

export function ThemeProvider({ children }: PropsWithChildren<unknown>) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#f79239',
          fontSize: 16,
          fontSizeHeading2: 18,
          fontSizeHeading3: 20
        }
      }}
      space={{ size: 'middle' }}
    >
      <Global
        styles={`
          html {
            height: 100%;
            font-size: 16px;
          }

          body {
            display: flex;
            margin: 0;
            min-height: 100%;
            flex-direction: column;
          }

          #root {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
          }

          .ant-input-number,
          .ant-picker {
            width: 100%;
          }
      `}
      />
      <DetailThemeProvider>{children}</DetailThemeProvider>
    </ConfigProvider>
  );
}

function DetailThemeProvider({ children }: PropsWithChildren<unknown>) {
  const { token } = theme.useToken();
  return (
    <ConfigProvider
      theme={{
        token: {
          colorLink: token.colorPrimary,
          colorLinkHover: token.colorPrimaryHover,
          colorLinkActive: token.colorPrimaryActive
        },
        components: {
          Layout: {
            colorBgHeader: token.colorBgBase
          }
        }
      }}
    >
      {children}
    </ConfigProvider>
  );
}
