import { Notification } from '../models/notification';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type NotificationState = Notification[];

const initialState: NotificationState = [];

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification: (state, { payload }: PayloadAction<Notification>) => {
      if (!payload.time) {
        payload.time = new Date().getTime();
      }
      state.push(payload);
    }
  }
});

export const { addNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
