import { RESTClient } from './client';
import { Result, ResultDraft } from '../models/result';
import { Transaction } from '../models/transaction';

export class ResultClient extends RESTClient<Result, ResultDraft> {
  public constructor(token?: string) {
    super('results', token);
  }

  public show(entity: { id: number }) {
    return this.clientInstance.get<Transaction>(`${this.path}/${entity.id}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public read(_entity: { id: number }): never {
    throw new Error('Not supported by API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public update(_entity: Result): never {
    throw new Error('Not supported by API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public delete(_entity: Result): never {
    throw new Error('Not supported by API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public create(entity: ResultDraft, skipDuplicateValidation?: boolean) {
    return this.clientInstance.post(this.path, entity, { params: { skip_duplicate_validation: skipDuplicateValidation } });
  }

  public regenerateCertificate(entity: { id: number }) {
    // todo add type once redux is gone
    return this.clientInstance.patch<any>(`${this.path}/${entity.id}/regenerate_certificate`);
  }
}
