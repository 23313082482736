import { SingleChoiceItem } from '../../../models/item';
import { SingleChoiceAnswer } from '../../../models/answer';
import styled from '@emotion/styled';
import { Radio, Form } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { createRequiredRule } from '../../../config/validation';
import { InputHint } from '../InputHint';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const CustomRadio = styled(Radio)`
  display: block;
`;

interface Props {
  index: number;
  item: SingleChoiceItem;
  responseItemId: number;
  answer?: SingleChoiceAnswer;
  onChange: (value: SingleChoiceAnswer['value']) => void;
}

export const SingleChoiceItemInput: React.FC<Props> = (props) => {
  const intl = useIntl();
  const requiredRule = createRequiredRule(intl);

  const handleChange = (e: RadioChangeEvent) => {
    const newValue = e.target.value;
    props.onChange({ choice: newValue });
  };

  return (
    <Container>
      <Form.Item name={['answers', props.index, 'choice']} rules={[requiredRule]}>
        <Radio.Group onChange={handleChange}>
          {props.item.definition.choice.map((c, i) => {
            return (
              <CustomRadio key={i} value={c}>
                {c}
              </CustomRadio>
            );
          })}
        </Radio.Group>
      </Form.Item>
      <InputHint hint={intl.formatMessage({ id: 'validation.selectCorrectAnswer' })} />
    </Container>
  );
};
