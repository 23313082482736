import * as React from 'react';
import { useIntl } from 'react-intl';
import TextArea from 'antd/lib/input/TextArea';
import { createRequiredRule } from '../../../config/validation';
import { Form } from 'antd';

export const DescriptionItemForm: React.FC = () => {
  const intl = useIntl();
  const requiredRule = createRequiredRule(intl);

  return (
    <Form.Item name={['definition', 'description']} label={intl.formatMessage({ id: 'model.attribute.description' })} rules={[requiredRule]}>
      <TextArea rows={5} />
    </Form.Item>
  );
};
