import * as React from 'react';
import { Tag } from 'antd';
import { FormattedMessage, FormattedNumber } from 'react-intl';

interface Props {
  id: number;
  points: number;
  awardedPoints?: number;
}

export const ItemInputDescription: React.FC<Props> = (props) => {
  return (
    <span>
      <Tag>
        <FormattedMessage id='model.id' />: {props.id}
      </Tag>
      <Tag>
        <FormattedMessage id='model.attribute.points' />: {props.points}
      </Tag>
      {props.awardedPoints !== undefined && (
        <Tag>
          <FormattedMessage id='model.attribute.awardedPoints' />: <FormattedNumber value={props.awardedPoints} />
        </Tag>
      )}
    </span>
  );
};
