import { useSelector } from 'react-redux';
import { Notification, NotificationType } from '../models/notification';
import { message } from 'antd';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import * as React from 'react';
import { State } from '../store';

const NotificationContainer: React.FC = () => {
  const notifications = useSelector<State, Notification[]>((state) => state.notifications);
  const intl = useIntl();

  useEffect(() => {
    if (notifications.length) {
      const lastNotification = notifications[notifications.length - 1];
      const formattedMessage = intl.formatMessage({ id: lastNotification.message }, lastNotification.values);
      switch (lastNotification.type) {
        case NotificationType.SUCCESS:
          message.success(formattedMessage);
          break;
        case NotificationType.WARNING:
          message.warning(formattedMessage);
          break;
        case NotificationType.ERROR:
          message.error(formattedMessage);
          break;
        default:
          message.info(formattedMessage);
          break;
      }
    }
  });
  return null;
};

export default React.memo(NotificationContainer);
