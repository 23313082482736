import { SelectProps } from 'antd';

export type SelectorProps<T extends { id: number }> = {
  onChange?: (value: T | T[]) => void;
  value?: T | T[];
  selectProps?: Partial<SelectProps<number>>;
};

export const resolveSelectedValue = <T extends { id: number }>(value: number | number[], entities: T[]) =>
  Array.isArray(value) ? entities.filter((c) => value.includes(c.id)) : entities.find((c) => c.id === value);

export const resolveIdValues = <T extends { id: number }>(value: T | T[]) => (Array.isArray(value) ? value.map(v => v?.id) : value?.id);
