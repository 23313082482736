/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Table, theme } from 'antd';
import { GlobalToken } from 'antd/es/theme/interface';
import { TableProps } from 'antd/lib/table';
import React, { useRef } from 'react';
import { DndProvider, useDrop, useDrag } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const dndStyles = (token: GlobalToken) => css`
  tr {
    &.drop-over-downward td {
      border-bottom: 3px solid ${token.colorPrimaryHover} !important;
    }

    &.drop-over-upward td {
      border-top: 3px solid ${token.colorPrimaryHover} !important;
    }
  }
  .ant-table-expanded-row {
    td {
      border-bottom: 1px solid #f0f0f0 !important;
      border-top: none !important;
    }
  }
`;

interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  onDrag?: () => void;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

const type = 'DraggableBodyRow';

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }: DraggableBodyRowProps) => {
  const ref = useRef<HTMLTableRowElement>(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver({ shallow: true }),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      };
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index);
    }
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  drop(drag(ref));

  return <tr ref={ref} className={`${className}${isOver ? dropClassName : ''}`} style={{ cursor: 'move', ...style }} {...restProps} />;
};

interface DragSortingTableProps<T> extends TableProps<T> {
  onDragSort?: (sortedData: T[]) => void;
}

type extractTableType<T> = T extends DragSortingTableProps<infer S> ? S : never;

export const DragSortingTable: <T>(props: DragSortingTableProps<T>) => React.ReactElement<DragSortingTableProps<T>> = (props) => {
  const [dataSource, setDataSource] = React.useState(props.dataSource);

  React.useEffect(() => {
    setDataSource(props.dataSource);
  }, [props.dataSource]);

  const components: TableProps<extractTableType<typeof props>>['components'] = {
    body: {
      row: DraggableBodyRow
    }
  };

  const moveRow: DraggableBodyRowProps['moveRow'] = (dragIndex, hoverIndex) => {
    const dragRow = dataSource[dragIndex];
    const remaining = dataSource.filter((i) => i !== dragRow);
    const sorted = [...remaining.slice(0, hoverIndex), dragRow, ...remaining.slice(hoverIndex)];

    setDataSource(sorted);

    if (props.onDragSort) {
      props.onDragSort(sorted);
    }
  };

  const tableProps: TableProps<extractTableType<typeof props>> = {
    ...props,
    className: props.className ? props.className + ' drag-sorting-table' : 'drag-sorting-table',
    components,
    dataSource,
    onRow: (_record, index) => ({ index, moveRow } as unknown)
  };

  const { token } = theme.useToken();

  return (
    <DndProvider backend={HTML5Backend}>
      <Table<any> {...tableProps} css={dndStyles(token)}>
        {props.children}
      </Table>
    </DndProvider>
  );
};
