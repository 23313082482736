import { useSelector } from 'react-redux';
import { Client } from '../client/client';
import { Session } from '../models/session';
import { Transaction } from '../models/transaction';
import { State } from '../store';

export const useApiClient = <C extends Client>(client: new (token?: string) => C) => {
  const session = useSelector<State, Session>((state) => state.session);
  const transaction = useSelector<State, Transaction>((state) => state.transaction);

  let token: string;
  if (transaction?.token) {
    token = transaction.token;
  }
  if (session?.token) {
    token = session.token;
  }
  return new client(token);
};
