import * as React from 'react';
import { useSelector } from 'react-redux';
import { Transaction, calculatePointMaximum, calculatePassingPoints } from '../../models/transaction';
import { StageContainer } from '../../components/layout/StageContainer';
import { startTransaction, setAnswerToTransaction, submitTransaction, deleteTransaction } from '../../features/user/transaction';
import { bindActionCreators } from 'redux';
import { Descriptions, Divider } from 'antd';
import styled from '@emotion/styled';
import { CertificationInput } from '../../components/certification/CertificationInput';
import { useIntl } from 'react-intl';
import { Loading } from '../../components/Loading';
import { Navigate, useNavigate } from 'react-router';
import { State, useAppDispatch } from '../../store';

const Container = styled.div`
  margin: 30px auto 0 auto;
  padding: 20px;
  width: clamp(50rem, 80%, 75rem);
`;

const Paper = styled(Container)`
  margin-bottom: 30px;
  flex-grow: 1;
  background-color: #fafafa;
`;

const FillTransaction: React.FC = () => {
  const transactionState = useSelector<State, Transaction>((state) => state.transaction);
  const dispatch = useAppDispatch();
  const actions = bindActionCreators({ startTransaction, setAnswerToTransaction, submitTransaction, deleteTransaction }, dispatch);
  const intl = useIntl();
  const navigate = useNavigate();

  const handleSubmit = () => {
    actions.submitTransaction(navigate);
  };

  React.useEffect(() => {
    actions.startTransaction(navigate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!transactionState.progress) {
    return <Loading />;
  }

  if (new Date(transactionState.progress.expiration).getTime() <= new Date().getTime()) {
    actions.deleteTransaction();
    return <Navigate to="/participate" />;
  }

  const totalPoints = calculatePointMaximum(transactionState.progress.responseItems);
  const passingPoints = calculatePassingPoints(totalPoints, transactionState.progress.certification.passingScore);

  return (
    <StageContainer title={transactionState.progress.certification.title}>
      <Container>
        <Descriptions title={intl.formatMessage({ id: 'view.participantInformation' })}>
          <Descriptions.Item label={intl.formatMessage({ id: 'model.attribute.name' })}>
            {`${transactionState.progress.participant.forename} ${transactionState.progress.participant.surname}`}
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage({ id: 'model.company' })}>{transactionState.progress.participant.company.name}</Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage({ id: 'model.attribute.email' })}>{transactionState.progress.participant.email}</Descriptions.Item>
        </Descriptions>
        <Divider orientation="center" />
        <Descriptions title={intl.formatMessage({ id: 'view.certificationInformation' })}>
          <Descriptions.Item label={intl.formatMessage({ id: 'model.attribute.name' })} span={3}>
            {transactionState.progress.certification.title}
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage({ id: 'model.attribute.passingScore' })}>
            {`${transactionState.progress.certification.passingScore}% (${passingPoints} ${intl.formatMessage({
              id: 'model.attribute.points'
            })})`}
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage({ id: 'view.amountOfPoints' })}>{totalPoints}</Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage({ id: 'view.amountOfQuestions' })}>
            {transactionState.progress.responseItems.reduce((total, current) => (current.item.definition['question'] ? total + 1 : total), 0)}
          </Descriptions.Item>
        </Descriptions>
      </Container>
      <Paper>
        <CertificationInput responseItems={transactionState.progress.responseItems} onAnswerChange={actions.setAnswerToTransaction} onSubmit={handleSubmit} />
      </Paper>
    </StageContainer>
  );
};

export default FillTransaction;
