/** @jsxImportSource @emotion/react */
import { MultiChoiceItem } from '../../../models/item';
import * as React from 'react';
import { MultiChoiceAnswer } from '../../../models/answer';
import { Checkbox, Form } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useIntl } from 'react-intl';
import { createRequiredRule } from '../../../config/validation';
import { InputHint } from '../InputHint';
import { checkboxGroupStyle } from './MultiChoiceItemDisplay';

interface Props {
  index: number;
  item: MultiChoiceItem;
  responseItemId: number;
  answer?: MultiChoiceAnswer;
  onChange: (value: MultiChoiceAnswer['value']) => void;
}

export const MultiChoiceItemInput: React.FC<Props> = (props) => {
  const intl = useIntl();
  const requiredRule = createRequiredRule(intl);

  const handleChange = (checked: CheckboxValueType[]) => {
    const newValue = { choice: checked.map((c) => c.toString()) };
    props.onChange(newValue);
  };

  return (
    <>
      <Form.Item name={['answers', props.index, 'choice']} rules={[requiredRule]}>
        <Checkbox.Group onChange={handleChange} css={checkboxGroupStyle}>
          {props.item.definition.choice.map((c, i) => {
            return (
              <Checkbox key={i} value={c}>
                {c}
              </Checkbox>
            );
          })}
        </Checkbox.Group>
      </Form.Item>
      <InputHint hint={intl.formatMessage({ id: 'validation.selectAllCorrectAnswers' })} />
    </>
  );
};
