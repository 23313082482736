import { VideoItem } from '../../../models/item';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { VideoFinishingInput } from './VideoFinishingInput';
import { Rule } from 'antd/lib/form';

const commonConfig: Record<string, any> = { rel: 0, controls: 2 };
interface Props {
  item: VideoItem;
  responseItemId: number;
}

export const VideoItemInput: React.FC<Props> = (props) => {
  const {
    definition: { url, watchToEnd }
  } = props.item;

  const intl = useIntl();
  const customRequiredRule: Rule = {
    required: true,
    message: intl.formatMessage({ id: 'validation.watchToEnd' })
  };

  const playerConfig: Record<string, any> = watchToEnd ? { ...commonConfig, disablekb: 1, controls: 0 } : commonConfig;

  return (
    <Form.Item name={['answers', props.responseItemId]} rules={watchToEnd && [customRequiredRule]} style={{ textAlign: 'center' }}>
      <VideoFinishingInput url={url} playerConfig={playerConfig} />
    </Form.Item>
  );
};
