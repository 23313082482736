import { SettingOutlined } from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import { useIntl } from 'react-intl';
import { paginationLayoutConfig } from '../../config/pagination';
import { ItemGroup } from '../../models/itemGroup';
import { useDynamicTable, IndexProps } from '../../services/indexService';
import { ItemGroupForm } from './ItemGroupForm';

type Props = {
  updateItemGroup: (itemGroup: ItemGroup) => void;
  openSettings: (id: number) => void;
} & IndexProps<ItemGroup>;

export const ItemGroupIndex: React.FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  const { handleTableChanges, getSortColumnProps } = useDynamicTable();

  const renderActions = (record: ItemGroup) => (
    <Space.Compact>
      <ItemGroupForm itemGroup={record} saveItemGroup={props.updateItemGroup} />
      <Button icon={<SettingOutlined />} onClick={() => props.openSettings(record.id)} />
    </Space.Compact>
  );
  return (
    <Table<ItemGroup> dataSource={props.index.data} rowKey="id" pagination={{ ...paginationLayoutConfig, ...props.index.meta }} onChange={handleTableChanges}>
      <Table.Column dataIndex="id" title={formatMessage({ id: 'model.id' })} width="80px" {...getSortColumnProps('item_groups.id')} />
      <Table.Column dataIndex="name" title={formatMessage({ id: 'model.attribute.name' })} />
      <Table.Column dataIndex="totalItems" title={formatMessage({ id: 'model.attribute.totalItems' })} width="150px" />
      <Table.Column title={formatMessage({ id: 'actions' })} render={renderActions} width="200px" align="center" />
    </Table>
  );
};
