import { Select, Spin } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { ItemGroupClient } from '../../client/itemGroup';
import { useApiClient } from '../../hooks/client';
import { useIndexQuery } from '../../hooks/rootQueries';
import { ItemGroup } from '../../models/itemGroup';
import { Query } from '../../models/query';
import { resolveIdValues, resolveSelectedValue, SelectorProps } from '../../services/selectorService';

type Props = {} & SelectorProps<ItemGroup>;

export const ItemGroupSelector: React.FC<Props> = ({ onChange, value, selectProps }) => {
  const { formatMessage } = useIntl();

  const client = useApiClient(ItemGroupClient);
  const { data: itemGroupIndex, isLoading } = useIndexQuery(Query.ITEM_GROUPS, client, {});

  const handleChange = (value: number | number[]) => {
    onChange(resolveSelectedValue(value, itemGroupIndex.data));
  };

  return (
    <Select
      placeholder={formatMessage({ id: 'model.itemGroup' })}
      onSelect={handleChange}
      value={resolveIdValues(value)}
      options={itemGroupIndex.data.map((group) => ({ value: group.id, label: `${group.name} (${group.totalItems})` }))}
      notFoundContent={isLoading && <Spin size="small" />}
      {...selectProps}
    />
  );
};
