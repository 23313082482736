/** @jsxImportSource @emotion/react */
import { useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSession } from '../features/session';
import styled from '@emotion/styled';
import { Session } from '../models/session';
import * as React from 'react';
import { ReactComponent as AppIcon } from '../styles/app.svg';
import { SessionForm } from '../components/session/SessionForm';
import { Navigate } from 'react-router-dom';
import { State, useAppDispatch } from '../store';

const StyledSessionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  & > * {
    width: 300px;
  }
`;

const SessionContainer: React.FC = () => {
  const sessionState = useSelector<State, Session>((state) => state.session);
  const dispatch = useAppDispatch();

  const actions = bindActionCreators({ createSession }, dispatch);

  if (sessionState.isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <StyledSessionContainer>
      <AppIcon css={{ height: '150px', marginBottom: '2rem' }} />
      <SessionForm signIn={actions.createSession} />
    </StyledSessionContainer>
  );
};

export default SessionContainer;
