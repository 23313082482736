import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router';
import { useApiClient } from '../../hooks/client';
import { useIndexQuery, useReadQuery, useUpdateMutation } from '../../hooks/rootQueries';
import { Item, ItemDraft } from '../../models/item';
import { Query } from '../../models/query';
import { ItemForm } from '../item/ItemForm';
import { ItemsEditor } from '../item/ItemsEditor';
import { StageContainer } from '../layout/StageContainer';
import _ from 'lodash';
import { ItemTypeClient } from '../../client/itemType';
import { ItemGroupClient } from '../../client/itemGroup';
import { ConfirmLeaveDialog } from '../ConfirmLeaveDialog';
import { SaveButton } from '../SaveButton';
import { UpdateReferencesDialog } from '../UpdateReferencesDialog';
import { RoutePath } from '../../containers/Router';
import { Button } from 'antd';
import { MoveItemsDialog } from '../item/MoveItemsDialog';
import { ItemClient } from '../../client/item';
import { useMoveItemsMutation } from '../../hooks/items';

type Props = {};

export const ItemGroupSettings: React.FC<Props> = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const params = useParams();

  const goBack = () => {
    navigate(RoutePath.ITEM_GROUP);
  };

  const [selectedItems, setSelectedItems] = React.useState<Item[]>([]);
  const [itemsSelectable, setItemsSelectable] = React.useState(false);
  const toggleItemsSelectable = () => setItemsSelectable(!itemsSelectable);

  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const toggleConfirmDialog = () => setShowConfirmDialog(!showConfirmDialog);

  const [showUpdateReferencesDialog, setShowUpdateReferencesDialog] = React.useState(false);
  const toggleUpdateReferencesDialog = () => setShowUpdateReferencesDialog(!showUpdateReferencesDialog);

  const itemGroupClient = useApiClient(ItemGroupClient);
  const { data: itemGroup, isSuccess, refetch } = useReadQuery(Query.ITEM_GROUPS, itemGroupClient, { id: parseInt(params.id) });
  const updateMutation = useUpdateMutation(Query.ITEM_GROUPS, itemGroupClient);

  const itemClient = useApiClient(ItemClient);
  const moveMutation = useMoveItemsMutation(itemClient, selectedItems);

  const itemTypeClient = useApiClient(ItemTypeClient);
  const { data: itemTypeIndex } = useIndexQuery(Query.ITEM_TYPES, itemTypeClient);

  const [items, setItems] = React.useState<Array<Item | ItemDraft>>();
  React.useEffect(() => setItems(itemGroup?.items), [isSuccess, itemGroup?.items]);

  const addItem = (item: ItemDraft) => {
    setItems([...items, item]);
  };

  const updateItemGroup = (updateReferences?: boolean) => {
    updateMutation.mutate({ ...itemGroup, items: items as Item[], additionalParams: { update_references: updateReferences } }, { onSuccess: goBack });
  };

  const handleMoveItems = (itemDestination: { certification?: number; itemGroup?: number }) => {
    moveMutation.mutate(itemDestination, {
      onSuccess: () => {
        refetch();
        setItemsSelectable(false);
      }
    });
  };

  const handleSave = () => {
    if (itemGroup.usedIn?.length) {
      toggleUpdateReferencesDialog();
    } else {
      updateItemGroup();
    }
  };

  const hasChanges = () => !_.isEqual(items, itemGroup?.items);

  return (
    <>
      <StageContainer
        title={formatMessage({ id: 'model.itemGroup' })}
        subTitle={itemGroup?.name}
        hasChanges={hasChanges()}
        onBack={goBack}
        onConfirmBack={toggleConfirmDialog}
        headerActions={
          itemsSelectable ? (
            <>
              <Button onClick={toggleItemsSelectable}>
                <FormattedMessage id="action.cancel" />
              </Button>
              <MoveItemsDialog selectedItems={selectedItems} currentItemGroup={itemGroup} onSubmit={handleMoveItems} />
            </>
          ) : (
            <>
              <Button onClick={toggleItemsSelectable}>
                <FormattedMessage id="action.moveItems" />
              </Button>
              <ItemForm saveItem={addItem} itemTypes={itemTypeIndex.data} />
              <SaveButton hasChanges={hasChanges()} onSave={handleSave} />
            </>
          )
        }
      >
        <ItemsEditor
          itemTypes={itemTypeIndex.data}
          items={items}
          onChange={(items) => {
            setItems(items as Array<Item | ItemDraft>);
          }}
          selectable={itemsSelectable}
          onSelectionChange={setSelectedItems}
        />
      </StageContainer>
      <UpdateReferencesDialog
        show={showUpdateReferencesDialog}
        onCancel={toggleUpdateReferencesDialog}
        onSaveWithoutUpdate={() => updateItemGroup()}
        onConfirmUpdateReferences={() => updateItemGroup(true)}
      />
      <ConfirmLeaveDialog show={showConfirmDialog} onCancel={toggleConfirmDialog} onLeaveAnyway={goBack} onSaveAndLeave={handleSave} />
    </>
  );
};
