import { RESTClient } from './client';
import { Gender } from '../models/gender';

export class GenderClient extends RESTClient<Gender> {
  public constructor(token?: string) {
    super('genders', token);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public read(_entity: Gender): never {
    throw new Error('Not supported by API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public update(_entity: Gender): never {
    throw new Error('Not supported by API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public delete(_entity: Gender): never {
    throw new Error('Not supported by API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public create(_entity: Gender): never {
    throw new Error('Not supported by API');
  }
}
