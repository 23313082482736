import * as React from 'react';
import { Progress } from '../../models/transaction';
import { ResultDisplay } from '../item/ResultDisplay';

interface Props {
  responseItems: Progress['responseItems'];
}

export const CertificationResult: React.FC<Props> = (props) => {
  return (
    <div className="certification">
      {props.responseItems.map((responseItem) => (
        <ResultDisplay responseItemId={responseItem.id} item={responseItem.item} answer={responseItem.answer} key={responseItem.item.id} />
      ))}
    </div>
  );
};
