import { JSONSchema7Type } from 'json-schema';
import { useQuery } from 'react-query';
import { DefaultValueClient } from '../client/defaultValue';
import { DefaultValueJSONSchema } from '../models/defaultValue';
import { Query } from '../models/query';

export const useDefaultValueSchema = (client: DefaultValueClient) => {
  return useQuery<DefaultValueJSONSchema>(`${Query.DEFAULT_VALUES}/schema`, async () => client.schema().then(({ data }) => data));
};

export const useDefaultValues = (client: DefaultValueClient) => {
  return useQuery<Record<string, JSONSchema7Type>>(Query.DEFAULT_VALUES, async () => client.index().then(({ data }) => data), {
    initialData: {}
  });
};
