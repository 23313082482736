import * as React from 'react';
import {
  DSVImport as Import,
  ColumnType,
  useDSVImport,
  Transformer,
  ValidationError,
  GenericColumnType
} from 'react-dsv-import';
import { Form, Input, Table, Alert, Row, Col, Tooltip, Typography } from 'antd';
import { useIntl } from 'react-intl';
import { WarningOutlined } from '@ant-design/icons';

export interface Props<T> {
  columns: ColumnType<T>[];
  onChange?: (value: T[]) => void;
  onValidation?: (errors: ValidationError<T>[]) => void;
  transformers?: Transformer[];
}

const TextareaInput: React.FC = () => {
  const [, dispatch] = useDSVImport();

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch({ type: 'setRaw', raw: event.target.value });
  };

  return <Input.TextArea rows={15} onChange={handleChange} />;
};

const TablePreview: React.FC = () => {
  const [context] = useDSVImport();

  const getCellValidationError = (columnKey: string, rowIndex: number) => {
    if (context.validation) {
      return context.validation.filter((e) => e.column === columnKey && e.row === rowIndex);
    }
  };

  const getRowKey = (record: GenericColumnType) => {
    return context.parsed.indexOf(record);
  };

  const renderCell = (columnKey: string) => (value: string, record: GenericColumnType, index: number) => {
    const errors = getCellValidationError(columnKey, index);
    if (errors.length > 0) {
      return (
        <Alert
          message={
            <Row>
              <Col flex='auto' style={{ maxWidth: 'calc(100% - 16px)' }}>
                <Typography.Text ellipsis={true} style={{ width: '100%' }}>
                  {value}
                </Typography.Text>
              </Col>
              <Col flex='16px'>
                <Tooltip
                  title={errors.map((e) => (
                    <Row key={`${columnKey}${index}`}>{e.message}</Row>
                  ))}
                >
                  <WarningOutlined />
                </Tooltip>
              </Col>
            </Row>
          }
          type='error'
        />
      );
    }
    return value;
  };

  return (
    <Table pagination={false} dataSource={context.parsed} rowKey={getRowKey}>
      {context.columns.map((r) => {
        return (
          <Table.Column<GenericColumnType>
            key={r.key}
            dataIndex={r.key}
            title={r.label ? r.label : r.key}
            render={renderCell(r.key.toString())}
            ellipsis={true}
          />
        );
      })}
    </Table>
  );
};

export const DSVImport = <T extends GenericColumnType>(props: Props<T>): React.ReactElement<Props<T>> => {
  const intl = useIntl();

  return (
    <Form layout='vertical'>
      <Import<T>
        columns={props.columns}
        onChange={props.onChange}
        transformers={props.transformers}
        onValidation={props.onValidation}
      >
        <Form.Item label={intl.formatMessage({ id: 'misc.dsvData' })}>
          <TextareaInput />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'misc.preview' })}>
          <TablePreview />
        </Form.Item>
      </Import>
    </Form>
  );
};
