import * as React from 'react';
import { FormMode } from '../../models/form';
import { Modal, Button, Input, Form, Checkbox, Divider, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Company, CompanyDraft, initialCompany } from '../../models/company';
import { FileAddOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { createRequiredRule } from '../../config/validation';
import { CountrySelector } from '../selectors/CountrySelector';
import { Country } from '../../models/country';

interface FormModel {
  name: string;
  street: string;
  zip: string;
  town: string;
  administrativeEmail?: string;
  billable?: boolean;
  country: Country;
  active: boolean;
}

interface Props {
  company?: Company;
  mode?: FormMode;
  saveCompany: (company: Company | CompanyDraft) => void;
}

export const CompanyForm: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const [form] = Form.useForm<FormModel>();
  const requiredRule = createRequiredRule(intl);

  let currentMode = FormMode.CREATE;
  if (props.mode) {
    currentMode = props.mode;
  } else if (props.company) {
    currentMode = FormMode.UPDATE;
  }
  const [loading, isLoading] = React.useState(false);
  const [open, isOpen] = React.useState(false);
  const toggleModal = () => isOpen(!open);

  React.useEffect(() => {
    if (open) {
      form.setFieldsValue(props.company || initialCompany);
    }
  }, [form, open, props.company]);

  const handleSubmit = (values: FormModel) => {
    isLoading(true);
    const { country, ...company } = values;
    props.saveCompany({ ...props.company, ...company, countryId: country.id });
    isLoading(false);
    toggleModal();
  };

  const createButtons = () => {
    switch (currentMode) {
      case FormMode.CREATE:
        return (
          <Button onClick={toggleModal} icon={<FileAddOutlined />}>
            <FormattedMessage id="action.create" values={{ entity: formatMessage({ id: 'model.company' }) }} children={(msg) => <span>{msg}</span>} />
          </Button>
        );
      case FormMode.UPDATE:
        return <Button onClick={toggleModal} icon={<EditOutlined />} />;
      default:
        return;
    }
  };

  const createTitle = () => {
    switch (currentMode) {
      case FormMode.CREATE:
        return formatMessage({ id: 'action.create' }, { entity: formatMessage({ id: 'model.company' }) });
      case FormMode.READ:
        return formatMessage({ id: 'action.read' }, { entity: formatMessage({ id: 'model.company' }) });
      case FormMode.UPDATE:
        return formatMessage({ id: 'action.update' }, { entity: formatMessage({ id: 'model.company' }) });
      default:
        return;
    }
  };

  return (
    <>
      {createButtons()}
      <Modal
        title={createTitle()}
        open={open}
        onCancel={toggleModal}
        onOk={() =>
          form
            .validateFields()
            .then(() => form.submit())
            .catch(() => null)
        }
        okText={formatMessage({ id: 'action.save' })}
        maskClosable={false}
        afterClose={form.resetFields}
        centered={true}
        closable={!loading}
        okButtonProps={{ loading: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form name="companyForm" form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item name="name" rules={[requiredRule]} label={formatMessage({ id: 'model.attribute.name' })}>
            <Input placeholder={formatMessage({ id: 'model.attribute.name' })} />
          </Form.Item>
          <Form.Item name="street" rules={[requiredRule]} label={formatMessage({ id: 'model.attribute.street' })}>
            <Input placeholder={formatMessage({ id: 'model.attribute.street' })} />
          </Form.Item>
          <Form.Item name="zip" rules={[requiredRule]} label={formatMessage({ id: 'model.attribute.zip' })}>
            <Input placeholder={formatMessage({ id: 'model.attribute.zip' })} />
          </Form.Item>
          <Form.Item name="town" rules={[requiredRule]} label={formatMessage({ id: 'model.attribute.town' })}>
            <Input placeholder={formatMessage({ id: 'model.attribute.town' })} />
          </Form.Item>
          <Form.Item name={'country'} rules={[requiredRule]} label={formatMessage({ id: 'model.country' })}>
            <CountrySelector />
          </Form.Item>
          <Form.Item name="administrativeEmail" label={formatMessage({ id: 'model.attribute.administrativeEmail' })}>
            <Input placeholder={formatMessage({ id: 'model.attribute.administrativeEmail' })} type="email" />
          </Form.Item>
          <Divider />
          <Form.Item name={'billable'} valuePropName="checked">
            <Checkbox>{formatMessage({ id: 'model.attribute.billable' })}</Checkbox>
          </Form.Item>
          <Form.Item name={'active'} valuePropName="checked">
            <Checkbox>
              <FormattedMessage id="model.attribute.active" />{' '}
              <Tooltip title={formatMessage({ id: 'view.deactivateCompanyInfo' })}>
                <QuestionCircleOutlined />
              </Tooltip>
            </Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
