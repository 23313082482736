import { useCallback, useEffect } from 'react';

interface Props {
  setConnectionState: (isOffline: boolean) => void;
}

const ConnectionDetection: React.FC<Props> = ({ setConnectionState }) => {
  const onOfflineEvent = useCallback(() => setConnectionState(true), [setConnectionState]);

  const onOnlineEvent = useCallback(() => setConnectionState(false), [setConnectionState]);

  useEffect(() => {
    window.addEventListener('offline', onOfflineEvent);
    window.addEventListener('online', onOnlineEvent);

    return () => {
      window.removeEventListener('offline', onOfflineEvent);
      window.removeEventListener('online', onOnlineEvent);
    };
  }, [onOfflineEvent, onOnlineEvent]);
  return null;
};

export default ConnectionDetection;
