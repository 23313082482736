import * as React from 'react';
import { Item, ItemImplementation, MultiChoiceItem, SingleChoiceItem, VideoItem } from '../../models/item';
import { DescriptionItemInput } from './itemType/DescriptionItemInput';
import { MultiChoiceItemInput } from './itemType/MultiChoiceItemInput';
import { SingleChoiceItemInput } from './itemType/SingleChoiceItemInput';
import { VideoItemInput } from './itemType/VideoItemInput';
import { AnswerDraft, Answer, AnswerImplementation, MultiChoiceAnswer, SingleChoiceAnswer } from '../../models/answer';
import styled from '@emotion/styled';
import { Card } from 'antd';
import { ItemInputDescription } from './ItemInputDescription';
import { ConfirmationItemInput } from './itemType/ConfirmationItemInput';
import { useIntl } from 'react-intl';

const Container = styled.div`
  margin-bottom: 60px;
`;

export const TaskContainer = styled(Card)`
  margin-bottom: 30px;
`;

interface Props {
  item: Omit<Item, 'id'>;
  answer?: Omit<Answer, 'id' | 'certificationItem'>;
  responseItemId: number;
  index: number;
  onChange: (value: AnswerDraft['value']) => void;
}

export const ItemInput: React.FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  const createItemProps = <T extends ItemImplementation, A extends AnswerImplementation>() => ({
    ...props,
    item: props.item as T,
    answer: props.answer as A
  });

  const renderItem = (item: Props['item']) => {
    switch (item.itemType.kind) {
      case 'descriptionItem': {
        return (
          <Container>
            <DescriptionItemInput {...createItemProps()} />
          </Container>
        );
      }
      case 'confirmationItem': {
        return (
          <TaskContainer>
            <ConfirmationItemInput {...createItemProps()} />
          </TaskContainer>
        );
      }
      case 'multiChoiceItem': {
        const itemProps = createItemProps<MultiChoiceItem, MultiChoiceAnswer>();
        return (
          <TaskContainer title={itemProps.item.definition.question} extra={<ItemInputDescription id={props.responseItemId} points={itemProps.item.definition.points} />}>
            <MultiChoiceItemInput {...itemProps} />
          </TaskContainer>
        );
      }
      case 'singleChoiceItem': {
        const itemProps = createItemProps<SingleChoiceItem, SingleChoiceAnswer>();
        return (
          <TaskContainer title={itemProps.item.definition.question} extra={<ItemInputDescription id={props.responseItemId} points={itemProps.item.definition.points} />}>
            <SingleChoiceItemInput {...itemProps} />
          </TaskContainer>
        );
      }
      case 'videoItem': {
        const itemProps = createItemProps<VideoItem, never>();
        return (
          <TaskContainer title={itemProps.item.definition.caption || formatMessage({ id: 'view.videoCard.title' })}>
            <VideoItemInput {...createItemProps()} />
          </TaskContainer>
        );
      }
      default:
        throw new Error('Item type is not implemented');
    }
  };

  return renderItem(props.item);
};
