import * as React from 'react';
import { setTransactionToken } from '../../features/user/transaction';
import { bindActionCreators } from 'redux';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { Transaction } from '../../models/transaction';
import { useNavigate, useParams } from 'react-router';
import { State, useAppDispatch } from '../../store';

const InitializeTransaction: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const transactionState = useSelector<State, Transaction>((state) => state.transaction);
  const actions = bindActionCreators({ setTransactionToken }, dispatch);

  // Set transaction token and redirect
  React.useEffect(() => {
    if (params.token !== transactionState.token) {
      actions.setTransactionToken(params.token);
    } else {
      navigate('/participate');
    }
  }, [transactionState.token, actions, params.token, navigate]);

  return <Spin />;
};

export default InitializeTransaction;
