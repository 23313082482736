import * as React from 'react';
import { deleteSession } from '../../features/session';
import { useSelector } from 'react-redux';
import { Session } from '../../models/session';
import { Route, Routes } from 'react-router';
import InitializeTransaction from './InitializeTransaction';
import FillTransaction from './FillTransaction';
import { TransactionResult } from './TransactionResult';
import { Loading } from '../../components/Loading';
import { StageContainer } from '../../components/layout/StageContainer';
import { Alert } from 'antd';
import { useIntl } from 'react-intl';
import { State, useAppDispatch } from '../../store';

const ParticipateContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const sessionState = useSelector<State, Session>((state) => state.session);
  const intl = useIntl();

  // Sign out if still authenticated
  React.useEffect(() => {
    if (sessionState.isAuthenticated) {
      dispatch(deleteSession());
    }
  }, [dispatch, sessionState.isAuthenticated]);

  const Error = () => (
    <StageContainer title={intl.formatMessage({ id: 'error.invalidRequest' })}>
      <Alert message={intl.formatMessage({ id: 'error.invalidInvite' })} type="error" showIcon />
    </StageContainer>
  );

  return (
    <Routes>
      <Route path="/" element={<FillTransaction />} />
      <Route path="/result" element={<TransactionResult />} />
      <Route path="/redirect" element={<Loading />} />
      <Route path="/error" element={<Error />} />
      <Route path=":token" element={<InitializeTransaction />} />
    </Routes>
  );
};

export default ParticipateContainer;
