import styled from '@emotion/styled';
import { Layout, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Logo } from '../../styles/logo.svg';

const StyledHeader = styled(Layout.Header)`
  display: flex;
  align-items: center;

  > svg {
    display: block;
    height: 1.6rem;
    margin-right: 3rem;
  }
`;

const HeaderMenuContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
`;

const SubTitleText = styled(Typography.Text)`
  && {
    font-weight: bold;
    font-size: 1.1em;
  }
`;

type HeaderProps = {
  subTitle?: JSX.Element;
  logo?: JSX.Element;
  menu?: JSX.Element;
};

export default function Header({ subTitle = <FormattedMessage id="name" />, logo = <Logo />, menu }: HeaderProps) {
  return (
    <StyledHeader>
      {logo}
      <HeaderMenuContainer>
        <SubTitleText>{subTitle}</SubTitleText>
        {menu}
      </HeaderMenuContainer>
    </StyledHeader>
  );
}
