import { Item, DescriptionItem, MultiChoiceItem, SingleChoiceItem, VideoItem, ConfirmationItem } from '../../models/item';
import * as React from 'react';

interface Props {
  item: Item;
}
export const ItemDescription: React.FC<Props> = (props) => {
  const createDescription = (): string => {
    switch (props.item.itemType.kind) {
      case 'descriptionItem':
        return (props.item as DescriptionItem).definition.description;
      case 'confirmationItem':
        return (props.item as ConfirmationItem).definition.value;
      case 'multiChoiceItem':
        return (props.item as MultiChoiceItem).definition.question;
      case 'singleChoiceItem':
        return (props.item as SingleChoiceItem).definition.question;
      case 'videoItem':
        return `${(props.item as VideoItem).definition.caption || ''} ${(props.item as VideoItem).definition.url}`;
      default:
        throw new Error('Item type is not implemented');
    }
  };

  return <>{createDescription()}</>;
};
