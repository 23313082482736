import { Input } from 'antd';
import { SearchProps } from 'antd/lib/input';

type Props = {
  onDebounceSearch: (value: string) => void;
} & SearchProps;

export const DebounceSearch: React.FC<Props> = (props) => {
  let timeout: ReturnType<typeof setTimeout>;

  const handleClear = () => props.onDebounceSearch(undefined);

  const handleSearch = ({ target: { value } }) => {
    if (value) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }

      const triggerSearch = () => props.onDebounceSearch(value);
      timeout = setTimeout(triggerSearch, 200);
    } else {
      handleClear();
      clearTimeout(timeout);
      timeout = null;
    }
  };
  return <Input.Search {...props} onChange={handleSearch} allowClear />;
};
