import * as React from 'react';
import { Item, ItemDraft } from '../../models/item';
import { FormMode } from '../../models/form';
import { Modal, Button, Select, Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { ItemType } from '../../models/itemType';
import { ItemDefinitionForm } from './ItemDefinitionForm';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { createRequiredRule } from '../../config/validation';
import { useCallback } from 'react';

interface FormModel {
  itemTypeId: number;
  definition: Item['definition'];
}

interface Props {
  itemTypes: ItemType[];
  item?: Item | ItemDraft;
  mode?: FormMode;
  saveItem: (item: ItemDraft | Item) => void;
}

export const ItemForm: React.FC<Props> = (props) => {
  const intl = useIntl();
  const requiredRule = createRequiredRule(intl);
  const [form] = Form.useForm<FormModel>();

  let currentMode = FormMode.CREATE;
  if (props.mode) {
    currentMode = props.mode;
  } else {
    if (props.item) {
      currentMode = FormMode.UPDATE;
    }
  }

  const [open, isOpen] = React.useState(false);
  const [loading, isLoading] = React.useState(false);
  const toggleModal = useCallback(() => isOpen(!open), [open]);

  React.useEffect(() => {
    if (open) {
      form.setFieldsValue({ ...props.item });
    }
  }, [open, form, props.item]);

  const [selectedItemType, setSelectedItemType] = React.useState<ItemType>(props.item?.itemType);
  const handleItemTypeSelection = (value: number) => {
    setSelectedItemType(props.itemTypes.find((it) => it.id === value));
  };

  const handleSubmit = ({ definition }: FormModel) => {
    isLoading(true);
    props.saveItem({ ...props.item, definition, itemType: selectedItemType, uniqueKey: Math.random() });
    isLoading(false);
    toggleModal();
  };

  const createButtons = () => {
    switch (currentMode) {
      case FormMode.CREATE:
        return (
          <Button onClick={toggleModal} icon={<PlusOutlined />}>
            <FormattedMessage id="action.addNew" values={{ entity: intl.formatMessage({ id: 'model.item' }) }} />
          </Button>
        );
      case FormMode.UPDATE:
        return <Button onClick={toggleModal} icon={<EditOutlined />} />;
      default:
        return;
    }
  };

  const createTitle = () => {
    switch (currentMode) {
      case FormMode.CREATE:
        return intl.formatMessage({ id: 'action.create' }, { entity: intl.formatMessage({ id: 'model.item' }) });
      case FormMode.READ:
        return intl.formatMessage({ id: 'action.read' }, { entity: intl.formatMessage({ id: 'model.item' }) });
      case FormMode.UPDATE:
        return intl.formatMessage({ id: 'action.update' }, { entity: intl.formatMessage({ id: 'model.item' }) });
      default:
        return;
    }
  };

  const afterClose = () => {
    form.resetFields();
    if (currentMode === FormMode.CREATE) {
      setSelectedItemType(undefined);
    }
  };

  return (
    <>
      {createButtons()}
      <Modal
        title={createTitle()}
        open={open}
        onCancel={toggleModal}
        onOk={() => form.validateFields().then(() => form.submit())}
        okText={intl.formatMessage({ id: 'action.save' })}
        maskClosable={false}
        afterClose={afterClose}
        centered={true}
        width="80%"
        closable={!loading}
        okButtonProps={{ loading: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form name="certificationItemForm" form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item name="itemTypeId" label={intl.formatMessage({ id: 'model.itemType' })} rules={[requiredRule]}>
            <Select onChange={handleItemTypeSelection}>
              {props.itemTypes?.map((i) => (
                <Select.Option value={i.id} key={i.id}>
                  <FormattedMessage id={`model.itemType.${i.kind}`} />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <ItemDefinitionForm itemType={selectedItemType} form={form} />
        </Form>
      </Modal>
    </>
  );
};
