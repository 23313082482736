import Axios, { AxiosInstance } from 'axios';
import { apiBase } from '../config/constants';
import { QueryParams } from '../models/queryParams';

export class Client {
  protected clientInstance: AxiosInstance;

  public constructor(token?: string) {
    this.clientInstance = this.initializeClient(token);
  }

  protected createHeaders(token?: string) {
    const headers: Record<string, string> = {};

    if (token) {
      headers['Authorization'] = token;
    }

    return headers;
  }

  private initializeClient(token?: string) {
    const headers = this.createHeaders(token);

    return Axios.create({
      baseURL: apiBase,
      headers
    });
  }
}

/**
 * @type E defines the entity
 * @type D defines an entity draft
 */
export abstract class RESTClient<E extends { id: number }, D = Omit<E, 'id'>> extends Client {
  protected path: string;
  public constructor(path: string, token?: string) {
    super(token);
    this.path = path;
  }

  public index(queryParams?: QueryParams) {
    let params = {};
    if (queryParams) {
      params = {
        page: queryParams.page,
        sort_by: queryParams.sorting?.field,
        order_by: queryParams.sorting?.order,
        search: queryParams.search || undefined,
        ...queryParams.filters
      };
    }

    return this.clientInstance.get<any>(this.path, { params });
  }

  public create(entity: D) {
    return this.clientInstance.post<E>(this.path, entity);
  }

  public read(entity: { id: number }) {
    return this.clientInstance.get<E>(`${this.path}/${entity.id}`);
  }

  public update(entity: E, additionalParams?: Record<string, any>) {
    return this.clientInstance.put<E>(`${this.path}/${entity.id}`, entity, { params: additionalParams });
  }

  public delete(entity: E) {
    return this.clientInstance.delete<E>(`${this.path}/${entity.id}`);
  }

  protected getAsFormData(entity: E | D) {
    const formData = new FormData();
    Object.keys(entity).forEach((key) => {
      if ((entity as any)[key]) {
        formData.append(key, (entity as any)[key]);
      }
    });
    return formData;
  }
}
