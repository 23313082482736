import { StageContainer } from '../components/layout/StageContainer';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { SettingsIndex } from '../components/setting/SettingsIndex';
import { useApiClient } from '../hooks/client';
import { DefaultValueClient } from '../client/defaultValue';
import { Query } from '../models/query';
import { useUpdateMutation } from '../hooks/rootQueries';
import { message } from 'antd';
import { useDefaultValues, useDefaultValueSchema } from '../hooks/defaultValues';
import { DefaultValueRequest } from '../models/defaultValue';
import { useQueryClient } from 'react-query';

const SettingContainer: React.FC = () => {
  const { formatMessage } = useIntl();

  const queryClient = useQueryClient();

  const client = useApiClient(DefaultValueClient);
  const { data: schema } = useDefaultValueSchema(client);
  const { data: defaultValues } = useDefaultValues(client);

  const updateMutation = useUpdateMutation(Query.DEFAULT_VALUES, client);

  const handleDefaultValueChange = (record: DefaultValueRequest) => {
    updateMutation.mutate(record, {
      onError: (_data, variables) => {
        message.error(formatMessage({ id: 'notification.error.invalidValue' }, { entity: variables.id }));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(Query.DEFAULT_VALUES);
      }
    });
  };

  return (
    <StageContainer title={formatMessage({ id: 'view.settings' })}>
      <SettingsIndex schema={schema} defaultValues={defaultValues} onDefaultValueChange={handleDefaultValueChange} />
    </StageContainer>
  );
};

export default SettingContainer;
