import * as React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { UserIndex } from '../components/user/UserIndex';
import { StageContainer } from '../components/layout/StageContainer';
import { Session } from '../models/session';
import { UserForm } from '../components/user/UserForm';
import { useApiClient } from '../hooks/client';
import { UserClient } from '../client/user';
import { CustomNotificationProps, useCreateMutation, useDeleteMutation, useIndexQuery, useUpdateMutation } from '../hooks/rootQueries';
import { Query } from '../models/query';
import { createInitialParams, useQueryParams } from '../models/queryParams';
import { User } from '../models/user';
import { State } from '../store';

const UserContainer: React.FC = () => {
  const intl = useIntl();

  const sessionState = useSelector<State, Session>((state) => state.session);

  const initialParams = createInitialParams('users.id');
  const [queryParams] = useQueryParams(initialParams);

  const userClient = useApiClient(UserClient);
  const { data: userIndex } = useIndexQuery(Query.USERS, userClient, queryParams);

  const notificationProps: CustomNotificationProps<User> = { attributeKey: 'email', attributeTranslation: 'model.attribute.email' };

  const createMutation = useCreateMutation(Query.USERS, userClient, initialParams, notificationProps);
  const updateMutation = useUpdateMutation(Query.USERS, userClient, notificationProps);
  const deleteMutation = useDeleteMutation(Query.USERS, userClient, notificationProps);

  return (
    <StageContainer title={intl.formatMessage({ id: 'model.users' })} headerActions={<UserForm saveUser={createMutation.mutate} />}>
      <UserIndex currentUser={sessionState.currentUser} index={userIndex} updateUser={updateMutation.mutate} deleteUser={deleteMutation.mutate} />
    </StageContainer>
  );
};

export default UserContainer;
