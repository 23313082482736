import { Participant, initialParticipant } from './participant';
import { Certification } from './certification';
import { InvitationStatus } from './invitation';

export type ResultStatus = Extract<InvitationStatus, InvitationStatus.passed | InvitationStatus.failed | InvitationStatus.passed_manually | InvitationStatus.failed_manually>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultStatus = {
  passed: InvitationStatus.passed,
  failed: InvitationStatus.failed,
  passed_manually: InvitationStatus.passed_manually,
  failed_manually: InvitationStatus.failed_manually
} as const;

export interface Result {
  id: number;
  status: ResultStatus;
  invitation: {
    id: number;
    expiresAt: string;
    validUntil: string;
  };
  participant: Participant;
  certification: Certification;
  response?: {
    id: number;
    submittedAt?: string;
    credential?: string;
    certificationPdfUrl?: string;
  };
}

export const isManualResult = (result: Result) => !result.invitation?.validUntil;

export const initialResult: Result = {
  id: undefined,
  invitation: undefined,
  participant: initialParticipant,
  certification: undefined,
  status: undefined
};
export interface ResultDraft {
  participantId: number;
  certificationId: number;
  submittedAt: string;
  achievedScore: number;
  reinvite: boolean;
}
