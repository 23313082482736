import * as React from 'react';
import { Certification, CertificationDraft } from '../../models/certification';
import { FormMode } from '../../models/form';
import { Input, InputNumber, Tabs, Form, Checkbox, FormInstance, TabsProps } from 'antd';
import { useIntl } from 'react-intl';
import { createRequiredRule, createMinMaxRule, createMinRule, createRepresentativeValidationRule } from '../../config/validation';
import { Rule, ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { NotificationTemplate } from '../../models/notificationTemplate';
import { NotificationTemplateForm } from './NotificationTemplateForm';
import { User } from '../../models/user';
import { TextTemplate } from '../../models/textTemplate';
import { TextTemplateForm } from './TextTemplateForm';
import { useDefaultValues } from '../../hooks/defaultValues';
import { DefaultValueClient } from '../../client/defaultValue';
import { useApiClient } from '../../hooks/client';
import { PartialDeep } from 'type-fest';
import { UserSelector } from '../selectors/UserSelector';

interface FormModel {
  general: {
    title: string;
    description?: string;
    primaryRepresentative: User;
    secondaryRepresentative?: User;
    administrativeRepresentative: User;
    passingScore: number;
    active: boolean;
    invoiceDispatch: boolean;
    price: number;
  };
  scheduling: {
    periodOfValidity: number;
    reinvitePeriodAfterFailure: number;
    reinvitePeriodBeforeInvalidity: number;
    gracePeriodAfterExpiration: number;
  };
  textTemplates: {
    inviteNotificationTemplate?: NotificationTemplate;
    reinviteNotificationTemplate?: NotificationTemplate;
    failureNotificationTemplate?: NotificationTemplate;
    cancelNotificationTemplate?: NotificationTemplate;
    successNoReinviteTextTemplate: NotificationTemplate;
    successNotificationTemplate?: NotificationTemplate;
    finalPageTextTemplate?: TextTemplate;
  };
}

const mapToFormModel = (certification: Certification | CertificationDraft): FormModel => ({
  general: {
    title: certification.title,
    description: certification.description,
    primaryRepresentative: certification.primaryRepresentative,
    secondaryRepresentative: certification.secondaryRepresentative,
    administrativeRepresentative: certification.administrativeRepresentative,
    passingScore: certification.passingScore,
    active: certification.active,
    invoiceDispatch: certification.invoiceDispatch,
    price: certification.price
  },
  scheduling: {
    periodOfValidity: certification.periodOfValidity,
    reinvitePeriodAfterFailure: certification.reinvitePeriodAfterFailure,
    reinvitePeriodBeforeInvalidity: certification.reinvitePeriodBeforeInvalidity,
    gracePeriodAfterExpiration: certification.gracePeriodAfterExpiration
  },
  textTemplates: {
    inviteNotificationTemplate: certification.inviteNotificationTemplate,
    reinviteNotificationTemplate: certification.reinviteNotificationTemplate,
    successNotificationTemplate: certification.successNotificationTemplate,
    failureNotificationTemplate: certification.failureNotificationTemplate,
    cancelNotificationTemplate: certification.cancelNotificationTemplate,
    successNoReinviteTextTemplate: certification.successNoReinviteTextTemplate,
    finalPageTextTemplate: certification.finalPageTextTemplate
  }
});

interface Props {
  certification?: Certification;
  users: User[];
  form: FormInstance<FormModel>;
  saveCertification: (certification: Certification | CertificationDraft) => void;
  onChange?: () => void;
}

export const CertificationForm: React.FC<Props> = (props) => {
  const intl = useIntl();
  const requiredRule = createRequiredRule(intl);

  const currentMode: FormMode = props.certification ? FormMode.UPDATE : FormMode.CREATE;

  const client = useApiClient(DefaultValueClient);
  const { data: defaultValues } = useDefaultValues(client);

  const [initialValues, setInitialValues] = React.useState<PartialDeep<FormModel>>({
    general: {
      passingScore: defaultValues['certification.passingScore'] as number,
      price: defaultValues['certification.price'] as number,
      active: false,
      invoiceDispatch: false
    },
    scheduling: {
      periodOfValidity: defaultValues['certification.periodOfValidity'] as number,
      reinvitePeriodAfterFailure: defaultValues['certification.reinvitePeriodAfterFailure'] as number,
      reinvitePeriodBeforeInvalidity: defaultValues['certification.reinvitePeriodBeforeInvalidity'] as number,
      gracePeriodAfterExpiration: defaultValues['certification.gracePeriodAfterExpiration'] as number
    },
    textTemplates: {
      inviteNotificationTemplate: {
        message: defaultValues['certification.inviteNotification']?.['message'] as string,
        subject: defaultValues['certification.inviteNotification']?.['subject'] as string
      },
      reinviteNotificationTemplate: {
        message: defaultValues['certification.reinviteNotification']?.['message'] as string,
        subject: defaultValues['certification.reinviteNotification']?.['subject'] as string
      },
      successNotificationTemplate: {
        message: defaultValues['certification.successNotification']?.['message'] as string,
        subject: defaultValues['certification.successNotification']?.['subject'] as string
      },
      successNoReinviteTextTemplate: {
        message: defaultValues['certification.successNoReinviteNotification']?.['message'] as string,
        subject: defaultValues['certification.successNoReinviteNotification']?.['subject'] as string
      },
      failureNotificationTemplate: {
        message: defaultValues['certification.failureNotification']?.['message'] as string,
        subject: defaultValues['certification.failureNotification']?.['subject'] as string
      },
      cancelNotificationTemplate: {
        message: defaultValues['certification.cancelNotification']?.['message'] as string,
        subject: defaultValues['certification.cancelNotification']?.['subject'] as string
      },
      finalPageTextTemplate: { message: defaultValues['certification.finalPageText'] as string }
    }
  });

  React.useEffect(() => {
    if (props.certification) {
      setInitialValues({ ...initialValues, ...mapToFormModel(props.certification) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.certification]);

  React.useEffect(() => {
    props.form.resetFields();
  }, [initialValues, props.form]);

  const handleSubmit = (values: FormModel) => {
    const newCertification = {
      ...props.certification,
      ...values.general,
      primaryRepresentativeId: values.general.primaryRepresentative.id,
      secondaryRepresentativeId: values.general.secondaryRepresentative?.id,
      administrativeRepresentativeId: values.general.administrativeRepresentative.id,
      ...values.scheduling,
      ...values.textTemplates
    };
    props.saveCertification(newCertification);
  };

  const [activeTab, setActiveTab] = React.useState<string>('general');
  const handleSubmitFailed = (errorInfo: ValidateErrorEntity) => {
    if (errorInfo.errorFields.length > 0) {
      setActiveTab(errorInfo.errorFields[0].name[0].toString());
    }
  };

  const representativeValidationRule: Rule = createRepresentativeValidationRule(props.users, intl.formatMessage);

  const uniqueRepresentativeValidator = () => {
    return new Promise<void>((resolve, reject) => {
      const primary: User = props.form.getFieldValue(['general', 'primaryRepresentative']);
      const secondary: User = props.form.getFieldValue(['general', 'secondaryRepresentative']);
      if (primary?.id !== secondary?.id) {
        resolve();
      } else {
        reject('Needs to be unique');
      }
    });
  };

  const uniqueRepresentativeValidationRule: Rule = {
    type: 'number',
    message: intl.formatMessage({ id: 'validation.unique' }),
    validator: uniqueRepresentativeValidator
  };

  const tabItems: TabsProps['items'] = [
    {
      label: intl.formatMessage({ id: 'model.attribute.general' }),
      key: 'general',
      children: (
        <>
          <Form.Item name={['general', 'title']} rules={[requiredRule]} label={intl.formatMessage({ id: 'model.attribute.title' })}>
            <Input placeholder={intl.formatMessage({ id: 'model.attribute.title' })} />
          </Form.Item>
          <Form.Item name={['general', 'description']} label={intl.formatMessage({ id: 'model.attribute.description' })}>
            <Input.TextArea rows={3} maxLength={500} />
          </Form.Item>
          <Form.Item
            name={['general', 'primaryRepresentative']}
            rules={[requiredRule, representativeValidationRule, uniqueRepresentativeValidationRule]}
            label={intl.formatMessage({ id: 'model.attribute.primaryRepresentative' })}
          >
            <UserSelector label={intl.formatMessage({ id: 'model.attribute.primaryRepresentative' })} />
          </Form.Item>
          <Form.Item
            name={['general', 'secondaryRepresentative']}
            rules={[representativeValidationRule, uniqueRepresentativeValidationRule]}
            label={intl.formatMessage({ id: 'model.attribute.secondaryRepresentative' })}
          >
            <UserSelector label={intl.formatMessage({ id: 'model.attribute.secondaryRepresentative' })} selectProps={{ allowClear: true }} />
          </Form.Item>
          <Form.Item name={['general', 'administrativeRepresentative']} rules={[requiredRule]} label={intl.formatMessage({ id: 'model.attribute.administrativeRepresentative' })}>
            <UserSelector label={intl.formatMessage({ id: 'model.attribute.administrativeRepresentative' })} />
          </Form.Item>
          <Form.Item name={['general', 'passingScore']} rules={[requiredRule]} label={intl.formatMessage({ id: 'model.attribute.passingScore' })}>
            <InputNumber
              placeholder={intl.formatMessage({ id: 'model.attribute.passingScore' })}
              min={0}
              max={100}
              formatter={(value) => `${value}%`}
              parser={(value) => parseInt(value.replace('%', '')) as 0 | 100}
            />
          </Form.Item>
          <Form.Item name={['general', 'price']} rules={[requiredRule]} label={intl.formatMessage({ id: 'model.attribute.price' })}>
            <InputNumber placeholder={intl.formatMessage({ id: 'model.attribute.price' })} min={0} step={0.01} />
          </Form.Item>
          {currentMode === FormMode.UPDATE && (
            <Form.Item name={['general', 'active']} label={intl.formatMessage({ id: 'model.attribute.active' })} valuePropName="checked">
              <Checkbox />
            </Form.Item>
          )}
          <Form.Item name={['general', 'invoiceDispatch']} label={intl.formatMessage({ id: 'model.attribute.invoiceDispatch' })} valuePropName="checked">
            <Checkbox />
          </Form.Item>
        </>
      )
    },
    {
      label: intl.formatMessage({ id: 'view.scheduling' }),
      key: 'scheduling',
      children: (
        <>
          <Form.Item name={['scheduling', 'periodOfValidity']} rules={[requiredRule]} label={intl.formatMessage({ id: 'model.attribute.periodOfValidity' })}>
            <Input
              type="number"
              addonAfter={intl.formatMessage({ id: 'misc.periods.days' })}
              placeholder={intl.formatMessage({ id: 'model.attribute.periodOfValidity' })}
              min={1}
            />
          </Form.Item>
          <Form.Item<FormModel> noStyle shouldUpdate={(prev, curr) => prev.scheduling?.periodOfValidity !== curr.scheduling?.periodOfValidity}>
            {({ getFieldsValue, validateFields }) => {
              validateFields([
                ['scheduling', 'reinvitePeriodAfterFailure'],
                ['scheduling', 'reinvitePeriodBeforeInvalidity']
              ]);
              return (
                <>
                  <Form.Item
                    name={['scheduling', 'reinvitePeriodAfterFailure']}
                    rules={[requiredRule, createMinMaxRule(intl, 0, getFieldsValue().scheduling?.periodOfValidity)]}
                    label={intl.formatMessage({ id: 'model.attribute.reinvitePeriodAfterFailure' })}
                  >
                    <Input
                      type="number"
                      addonAfter={intl.formatMessage({ id: 'misc.periods.days' })}
                      placeholder={intl.formatMessage({ id: 'model.attribute.reinvitePeriodAfterFailure' })}
                      min={0}
                      max={getFieldsValue().scheduling?.periodOfValidity}
                    />
                  </Form.Item>
                  <Form.Item
                    name={['scheduling', 'reinvitePeriodBeforeInvalidity']}
                    rules={[requiredRule, createMinMaxRule(intl, 1, getFieldsValue().scheduling?.periodOfValidity)]}
                    label={intl.formatMessage({ id: 'model.attribute.reinvitePeriodBeforeInvalidity' })}
                  >
                    <Input
                      type="number"
                      addonAfter={intl.formatMessage({ id: 'misc.periods.days' })}
                      placeholder={intl.formatMessage({ id: 'model.attribute.reinvitePeriodBeforeInvalidity' })}
                      min={1}
                      max={getFieldsValue().scheduling?.periodOfValidity}
                    />
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
          <Form.Item
            name={['scheduling', 'gracePeriodAfterExpiration']}
            rules={[requiredRule, createMinRule(intl, 0)]}
            label={intl.formatMessage({ id: 'model.attribute.gracePeriodAfterExpiration' })}
          >
            <Input
              type="number"
              addonAfter={intl.formatMessage({ id: 'misc.periods.hours' })}
              placeholder={intl.formatMessage({ id: 'model.attribute.gracePeriodAfterExpiration' })}
              min={0}
            />
          </Form.Item>
        </>
      )
    },
    {
      label: intl.formatMessage({ id: 'view.textTemplates' }),
      key: 'textTemplates',
      children: (
        <>
          <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'view.textTemplatePlaceholder' }) }}></div>
          <NotificationTemplateForm name={['textTemplates', 'inviteNotificationTemplate']} title={intl.formatMessage({ id: 'model.attribute.inviteNotificationTemplate' })} />
          <NotificationTemplateForm name={['textTemplates', 'reinviteNotificationTemplate']} title={intl.formatMessage({ id: 'model.attribute.reinviteNotificationTemplate' })} />
          <NotificationTemplateForm name={['textTemplates', 'successNotificationTemplate']} title={intl.formatMessage({ id: 'model.attribute.successNotificationTemplate' })} />
          <NotificationTemplateForm
            name={['textTemplates', 'successNoReinviteTextTemplate']}
            title={intl.formatMessage({ id: 'model.attribute.successNoReinviteTextTemplate' })}
            required={false}
          />
          <NotificationTemplateForm name={['textTemplates', 'failureNotificationTemplate']} title={intl.formatMessage({ id: 'model.attribute.failureNotificationTemplate' })} />
          <NotificationTemplateForm name={['textTemplates', 'cancelNotificationTemplate']} title={intl.formatMessage({ id: 'model.attribute.cancelNotificationTemplate' })} />
          <TextTemplateForm name={['textTemplates', 'finalPageTextTemplate']} title={intl.formatMessage({ id: 'model.attribute.finalPageTextTemplate' })} />
        </>
      )
    }
  ];

  return (
    <Form
      form={props.form}
      name="certificationForm"
      onFinish={handleSubmit}
      onFinishFailed={handleSubmitFailed}
      onValuesChange={props.onChange}
      layout="vertical"
      initialValues={initialValues}
    >
      <Tabs activeKey={activeTab} onChange={setActiveTab} items={tabItems} />
    </Form>
  );
};
