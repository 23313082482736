import { ConfigProvider } from 'antd';
import { FC, PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { getMessages } from '../config/translation';
import { Translation } from '../models/translation';
import { State } from '../store';

export const TranslationProvider: FC<PropsWithChildren<unknown>> = (props) => {
  const translation = useSelector<State, Translation>((state) => state.translation);

  return (
    <IntlProvider locale={translation.locale} key={translation.locale} messages={getMessages(translation.locale).data}>
      <ConfigProvider locale={getMessages(translation.locale).antd}>{props.children}</ConfigProvider>
    </IntlProvider>
  );
};
