import React from 'react';
import { Button, Modal } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {
  show: boolean;
  onLeaveAnyway: () => void;
  onSaveAndLeave: () => void;
  onCancel: () => void;
};

export const ConfirmLeaveDialog: React.FC<Props> = ({ show, onCancel, onLeaveAnyway, onSaveAndLeave }) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      open={show}
      title={formatMessage({ id: 'view.confirmDialog.leaveCertificationPage.title' })}
      onCancel={onCancel}
      footer={
        <>
          <Button onClick={onLeaveAnyway} danger>
            <FormattedMessage id="action.leaveWithoutSaving" />
          </Button>
          <Button onClick={onSaveAndLeave} type="primary">
            <FormattedMessage id="action.saveAndClose" />
          </Button>
        </>
      }
    >
      <FormattedMessage id="view.confirmDialog.leaveCertificationPage.description" />
    </Modal>
  );
};
