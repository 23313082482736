import { Progress, initialTransaction } from '../../models/transaction';
import { createClientAction } from '../rootAction';
import { TransactionClient } from '../../client/user/transaction';
import { AnswerDraft } from '../../models/answer';
import { ResultClient } from '../../client/result';
import { AxiosError } from 'axios';
import { addNotification } from '../notification';
import { NotificationType } from '../../models/notification';
import { NavigateFunction } from 'react-router';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const startTransaction = (navigate: NavigateFunction) => {
  return createClientAction(TransactionClient, (client, dispatch) => {
    client
      .start()
      .then((response) => {
        dispatch(updateProgress(response.data.progress));
      })
      .catch((error: AxiosError<{ error: string }>) => {
        if (error.response.status === 405) {
          dispatch(
            addNotification({
              message: 'notification.error.invitationError',
              details: error.response.statusText,
              type: NotificationType.ERROR
            })
          );
        } else {
          const errorMessageMap = {
            'participant inactive': 'notification.error.participantInactive',
            'invitation cancelled': 'notification.error.invitationCancelled',
            'invitation expired': 'notification.error.invitationExpired'
          };

          dispatch(
            addNotification({
              message: errorMessageMap[error.response.data.error] ?? 'notification.error.invitationExpired',
              type: NotificationType.ERROR
            })
          );
        }
        navigate('/participate/error');
        dispatch(deleteTransaction());
      });
  });
};

export const setAnswerToTransaction = (answer: AnswerDraft) => {
  return createClientAction(TransactionClient, (client, dispatch) => {
    client.answer(answer).catch(() => {
      dispatch(
        addNotification({
          message: 'notification.error.invitationExpired',
          type: NotificationType.ERROR
        })
      );
    });
  });
};

export const submitTransaction = (navigate: NavigateFunction) =>
  createClientAction(TransactionClient, (client, dispatch) => {
    client
      .submit()
      .then((response) => {
        navigate('result');
        dispatch(finishTransaction(response.data.progress));
      })
      .catch((e: AxiosError<{ error: string }>) => {
        dispatch(
          addNotification({
            message: e.message,
            type: NotificationType.ERROR
          })
        );
      });
  });

export const showTransaction = (id: number) => {
  return createClientAction(ResultClient, (client, dispatch) => {
    client.show({ id }).then((response) => {
      dispatch(updateProgress(response.data.progress));
    });
  });
};

const transactionSlice = createSlice({
  name: 'transaction',
  initialState: initialTransaction,
  reducers: {
    deleteTransaction: () => {
      return initialTransaction;
    },
    updateProgress: (state, { payload }: PayloadAction<Progress>) => {
      return { ...state, progress: payload };
    },
    setTransactionToken: (state, { payload }: PayloadAction<string>) => {
      state.token = payload;
      return state;
    },
    finishTransaction: (state, { payload }: PayloadAction<Progress>) => {
      delete state.token;
      state.progress = payload;
      return state;
    }
  }
});

export const { deleteTransaction, updateProgress, setTransactionToken, finishTransaction } = transactionSlice.actions;

export default transactionSlice.reducer;
