import { ProtectedOutlet, ProtectedOutletProps } from '../components/ProtectedRoute';
import DashboardContainer from './Dashboard';
import UserContainer from './User';
import InvitationContainer from './Invitation';
import ParticipateContainer from './user/Participate';
import SessionContainer from './Session';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Session } from '../models/session';
import ResultContainer from './Result';
import CertificationContainer from './Certification';
import SettingContainer from './Setting';
import ParticipantContainer from './Participant';
import CompanyContainer from './Company';
import BillContainer from './Bill';
import { ReportingContainer } from './Reporting';
import { ItemGroupContainer } from './ItemGroup';
import { State } from '../store';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import App from './App';

export enum RoutePath {
  DASHBOARD = '/',
  USERS = '/users',
  COMPANIES = '/companies',
  PARTICIPANTS = '/participants',
  CERTIFICATION = '/certification',
  RESULTS = '/results',
  BILLING = '/billing',
  SETTINGS = '/settings',
  INVITATIONS = '/invitations',
  REPORTING = '/reporting',
  ITEM_GROUP = '/item-groups'
}

const Router: React.FC = () => {
  const sessionState = useSelector<State, Session>((state) => state.session);

  const defaultProtectedRouteProps: ProtectedOutletProps = {
    isAuthenticated: sessionState.isAuthenticated,
    isAllowed: true,
    authenticationPath: '/session',
    restrictedPath: '/'
  };

  const router = createBrowserRouter([
    {
      element: <App />,
      children: [
        {
          element: <ProtectedOutlet {...defaultProtectedRouteProps} />,
          children: [
            {
              path: RoutePath.DASHBOARD,
              element: <DashboardContainer />
            },
            {
              path: RoutePath.USERS,
              element: <UserContainer />
            },
            {
              path: `${RoutePath.CERTIFICATION}/*`,
              element: <CertificationContainer />
            },
            {
              path: RoutePath.SETTINGS,
              element: <SettingContainer />
            },
            {
              path: `${RoutePath.RESULTS}/*`,
              element: <ResultContainer />
            },
            {
              path: RoutePath.COMPANIES,
              element: <CompanyContainer />
            },
            {
              path: RoutePath.PARTICIPANTS,
              element: <ParticipantContainer />
            },
            {
              path: `${RoutePath.INVITATIONS}/*`,
              element: <InvitationContainer />
            },
            {
              path: RoutePath.BILLING,
              element: <BillContainer />
            },
            {
              path: RoutePath.REPORTING,
              element: <ReportingContainer />
            },
            {
              path: `${RoutePath.ITEM_GROUP}/*`,
              element: <ItemGroupContainer />
            }
          ]
        },
        {
          path: '/participate/*',
          element: <ParticipateContainer />
        },
        {
          path: '/session',
          element: <SessionContainer />
        },
        {
          element: <Navigate to="/" />
        }
      ]
    }
  ]);

  return <RouterProvider router={router} />;
};

export default Router;
