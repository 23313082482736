import { RESTClient } from './client';

export class DefaultValueClient extends RESTClient<any, any> {
  public constructor(token?: string) {
    super('default_values', token);
  }

  public index() {
    return this.clientInstance.get(this.path)
  }

  public schema() {
    return this.clientInstance.get(`${this.path}/schema`)
  }

  public delete(_entity: any): never {
    throw new Error('Not supported by API');
  }

  public read(_entity: { id: number }): never {
    throw new Error('Not supported by API');
  }

  public create(_entity: any): never {
    throw new Error('Not supported by API');
  }
}
