import { Form, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { addWeeks, endOfDay } from 'date-fns';
import { useIntl } from 'react-intl';
import { DatePicker } from '../DatePicker';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (validUntil?: Date) => void;
};

export function ReinviteDialog({ open, onClose, onSubmit }: Props) {
  const { formatMessage } = useIntl();
  const [reinviteForm] = useForm<{ validUntil?: Date }>();

  return (
    <Modal
      title={formatMessage({ id: 'action.manuallyReinvite' })}
      open={open}
      onCancel={onClose}
      okText={formatMessage({ id: 'model.attribute.reinvite' })}
      onOk={reinviteForm.submit}
    >
      <Form
        layout="vertical"
        form={reinviteForm}
        onFinish={(values) => {
          console.log(values);

          onSubmit(values.validUntil);
          onClose();
        }}
      >
        <Form.Item
          name="validUntil"
          label={`${formatMessage({ id: 'model.attribute.validUntil' })} ${formatMessage({ id: 'view.optional' })}`}
          extra={formatMessage({ id: 'view.defaultValidUntilDateDescription' })}
        >
          <DatePicker disabledDate={(current) => current < endOfDay(addWeeks(new Date(), 1))} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
