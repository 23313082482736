import { useForm } from 'antd/lib/form/Form';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { CertificationClient } from '../../client/certification';
import { useApiClient } from '../../hooks/client';
import { useReadQuery, useUpdateMutation } from '../../hooks/rootQueries';
import { Certification } from '../../models/certification';
import { Query } from '../../models/query';
import { RoutePath } from '../Router';
import { CertificationFormContainer } from './CertificationFormContainer';

export const CertificationEditContainer: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [form] = useForm();

  const certificationClient = useApiClient(CertificationClient);
  const { data: certification } = useReadQuery(Query.CERTIFICATIONS, certificationClient, { id: parseInt(params.id) });
  const updateMutation = useUpdateMutation(Query.CERTIFICATIONS, certificationClient, { attributeKey: 'title', attributeTranslation: 'model.attribute.title' });

  const handleSave = (certification: Certification) => {
    updateMutation.mutate(certification, {
      onSuccess: () => {
        navigate(RoutePath.CERTIFICATION);
        form.resetFields();
      }
    });
  };

  return <CertificationFormContainer form={form} onSaveCertification={handleSave} certification={certification} />;
};
