import { Participant } from './participant';
import { Certification } from './certification';

export enum InvitationStatus {
  invited = 'invited',
  invited_and_expired = 'invitedAndExpired',
  started = 'started',
  started_and_expired = 'startedAndExpired',
  passed = 'passed',
  passed_manually = 'passedManually',
  failed = 'failed',
  failed_manually = 'failedManually'
}

export const isSubmitted = (status: InvitationStatus) => [InvitationStatus.passed, InvitationStatus.failed].includes(status);
export const isExpired = (status: InvitationStatus) => [InvitationStatus.invited_and_expired, InvitationStatus.started_and_expired].includes(status);
export const isOpen = (status: InvitationStatus) => [InvitationStatus.started, InvitationStatus.invited].includes(status);

export interface Invitation {
  id: number;
  token?: string;
  validUntil: string;
  cancelledAt?: string;
  status: InvitationStatus;
  reinvite: boolean;
  createdAt?: number;
  participant: Participant;
  certification: Certification;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InvitationDraft extends Omit<Invitation, 'id' | 'participant' | 'certification' | 'status'> {
  participantId: number;
  certificationId: number;
  temporarySecondaryRepresentativeId?: number;
  customInviteNotificationTemplate?: string;
}
