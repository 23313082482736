import * as React from 'react';
import { Layout, Tag } from 'antd';
import { env } from '../config/constants';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router';
import { bindActionCreators } from 'redux';
import { deleteSession, updateSession } from '../features/session';
import { HeaderMenu } from '../components/layout/HeaderMenu';
import { Sidebar } from '../components/layout/Sidebar';
import styled from '@emotion/styled';
import NotificationDisplay from './Notification';
import { Session } from '../models/session';
import ConnectionDetection from '../components/ConnectionDetection';
import Header from '../components/layout/Header';
import { State, useAppDispatch } from '../store';

const Footer = styled(Layout.Footer)`
  && {
    display: flex;
    align-items: center;
    height: 64px;
  }
`;

const FooterTagContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const App: React.FC = () => {
  const sessionState = useSelector<State, Session>((state) => state.session);
  const dispatch = useAppDispatch();
  const actions = bindActionCreators({ deleteSession, updateSession }, dispatch);
  const navigate = useNavigate();
  const location = useLocation();
  const setConnectionState = (isOffline: boolean) => actions.updateSession({ isOffline });

  if (sessionState.isAuthenticated && sessionState.expiration <= new Date().getTime() / 1000) {
    actions.deleteSession();
  }

  return (
    <Layout>
      <ConnectionDetection setConnectionState={setConnectionState} />
      <NotificationDisplay />
      <Header menu={<HeaderMenu isAuthenticated={sessionState.isAuthenticated} signOut={actions.deleteSession} />} />
      <Layout>
        {sessionState.isAuthenticated && <Sidebar currentPath={location.pathname} navigateTo={navigate} />}
        <Layout>
          <Outlet />
          <Footer>
            <FooterTagContainer>
              {sessionState.isOffline ? <Tag color="orange">Offline</Tag> : <Tag color="green">Online</Tag>}
              {env === 'development' ? <Tag color="orange">Development mode</Tag> : null}
            </FooterTagContainer>
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default App;
