import React from 'react';
import styled from '@emotion/styled';
import { Button, Form, Space } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { FormattedMessage } from 'react-intl';
import { SelectorProps } from '../services/selectorService';

const DropdownFormItem = styled(Form.Item)`
  padding: 0.5rem;
  width: 22rem;
  margin: 0;
`;

const ButtonBar = styled(Space)`
  width: 100%;
  border-top: 1px solid #f0f0f0;
  justify-content: space-between;
  padding: 0.5rem;
`;

type FilterForm<T extends { id: number }> = {
  filters: T[];
};

type Props<T extends { id: number }> = {
  Selector: React.FunctionComponent<SelectorProps<T>>;
  filterDropdownProps: FilterDropdownProps;
  filterAttributeName?: keyof T;
};

export function MultiSelectFilter<T extends { id: number }>({ Selector, filterDropdownProps, filterAttributeName = 'id' }: Props<T>) {
  const [form] = useForm<FilterForm<T>>();

  React.useEffect(() => {
    const filters = filterDropdownProps.selectedKeys.map((s) => ({ id: parseInt(s.toString()) }));
    // TODO find possible typing
    form.setFieldsValue({ filters: filters as any });
  }, [filterDropdownProps.selectedKeys, form]);

  const handleSubmit = ({ filters }: FilterForm<T>) => {
    filterDropdownProps.setSelectedKeys(filters.map((f) => f[filterAttributeName.toString()]));
    filterDropdownProps.confirm();
  };

  const handleReset = () => {
    filterDropdownProps.clearFilters();
    form.resetFields();
  };

  const filters = useWatch('filters', form);

  return (
    <Form form={form} onFinish={handleSubmit}>
      <DropdownFormItem name="filters" style={{ flex: 1 }}>
        <Selector selectProps={{ mode: 'multiple', allowClear: false, style: { width: '85%' } }} />
      </DropdownFormItem>
      <ButtonBar>
        <Button type="link" size="small" onClick={handleReset} disabled={!filters?.length}>
          <FormattedMessage id="action.reset" />
        </Button>
        <Button type="primary" onClick={form.submit} size="small">
          <FormattedMessage id="action.ok" />
        </Button>
      </ButtonBar>
    </Form>
  );
}
