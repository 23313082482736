import { RESTClient } from './client';
import { Item } from '../models/item';

export class ItemClient extends RESTClient<Item> {
  public constructor(token?: string) {
    super('items', token);
  }

  public moveToCertification(items: number[], certificationId: number) {
    return this.clientInstance.put(`${this.path}/moveToCertification/${certificationId}`, { items })
  }

  public moveToItemGroup(items: number[], itemGroupId: number) {
    return this.clientInstance.put(`${this.path}/moveToItemGroup/${itemGroupId}`, { items })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public read(_entity: Item): never {
    throw new Error('Not supported by API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public update(_entity: Item): never {
    throw new Error('Not supported by API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public delete(_entity: Item): never {
    throw new Error('Not supported by API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public create(_entity: Item): never {
    throw new Error('Not supported by API');
  }
}
