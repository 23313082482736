export interface User {
  id: number;
  email: string;
  forename: string;
  password?: string;
  surname: string;
  functionalRole?: string;
  signature?: File;
  signatureUrl?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UserDraft extends Omit<User, 'id'> {}

export const initialUser: User = {
  id: undefined,
  email: undefined,
  forename: undefined,
  surname: undefined
};
