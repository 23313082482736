import { Client } from '../client';
import { Transaction } from '../../models/transaction';
import { AnswerDraft } from '../../models/answer';

export class TransactionClient extends Client {
  protected path = 'user/transaction';
  public constructor(token?: string) {
    super(token);
  }

  public start() {
    return this.clientInstance.post<Transaction>(`${this.path}/start`);
  }

  public answer(answer: AnswerDraft) {
    return this.clientInstance.post<unknown>(`${this.path}/answer`, answer);
  }

  public submit() {
    return this.clientInstance.post<Transaction>(`${this.path}/submit`, undefined, { params: { timezone_offset: new Date().getTimezoneOffset() } });
  }
}
