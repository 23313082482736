import * as React from 'react';
import { ItemType } from '../../models/itemType';
import { FormInstance } from 'antd/lib/form';
import { ConfirmationItemForm } from './itemType/ConfirmationItemForm';
import { DescriptionItemForm } from './itemType/DescriptionItemForm';
import { MultiChoiceItemForm } from './itemType/MultiChoiceItemForm';
import { SingleChoiceItemForm } from './itemType/SingleChoiceItemForm';
import { VideoItemForm } from './itemType/VideoItemForm';

interface Props {
  itemType?: ItemType;
  form: FormInstance;
}

export const ItemDefinitionForm: React.FC<Props> = (props) => {
  if (!props.itemType) {
    return null;
  }

  const createItemProps = () => {
    return {
      form: props.form
    };
  };

  switch (props.itemType.kind) {
    case 'descriptionItem':
      return <DescriptionItemForm />;
    case 'confirmationItem':
      return <ConfirmationItemForm />;
    case 'multiChoiceItem':
      return <MultiChoiceItemForm {...createItemProps()} />;
    case 'singleChoiceItem':
      return <SingleChoiceItemForm {...createItemProps()} />;
    case 'videoItem':
      return <VideoItemForm />;
    default:
      throw new Error('Item type is not implemented');
  }
};
