import { initialPaginationMeta, PaginationMeta } from './paginationMeta';

export interface Index<T> {
  data: T[];
  meta: PaginationMeta;
}

export const initialIndex = {
  data: [],
  meta: initialPaginationMeta
}
