/** @jsxImportSource @emotion/react */
import { SingleChoiceItem } from '../../../models/item';
import { SingleChoiceAnswer } from '../../../models/answer';
import styled from '@emotion/styled';
import { Radio, theme } from 'antd';
import * as React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { getCorrectOrWrongStyle } from './MultiChoiceItemDisplay';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const CustomRadio = styled(Radio)`
  display: block;
`;

interface Props {
  item: SingleChoiceItem;
  answer?: SingleChoiceAnswer;
  responseItemId: number;
}

export const SingleChoiceItemDisplay: React.FC<Props> = ({ item, answer }) => {
  const { token } = theme.useToken();

  const renderCorrection = (choice: string) => {
    const isSelected = answer?.value.choice === choice;
    const isCorrect = answer?.value.choice === item.definition.solution;

    if (isSelected) {
      return isCorrect ? <CheckCircleOutlined /> : <CloseCircleOutlined />;
    }
  };

  return (
    <Container>
      <Radio.Group disabled={true} value={answer?.value.choice}>
        {item.definition.choice.map((c, i) => (
          <CustomRadio key={i} value={c} css={getCorrectOrWrongStyle(item.definition.solution === c)(token)}>
            {c} {renderCorrection(c)}
          </CustomRadio>
        ))}
      </Radio.Group>
    </Container>
  );
};
