/** @jsxImportSource @emotion/react */

import * as React from 'react';
import { Menu, Layout, Typography } from 'antd';
import { useIntl } from 'react-intl';
import {
  DashboardOutlined,
  ControlOutlined,
  AuditOutlined,
  SafetyCertificateOutlined,
  ShopOutlined,
  MailOutlined,
  SmileOutlined,
  SlidersOutlined,
  UserOutlined,
  SettingOutlined,
  DollarOutlined,
  ExportOutlined,
  TagsOutlined
} from '@ant-design/icons';
import { RoutePath } from '../../containers/Router';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { css } from '@emotion/react';
import { version } from '../../config/constants';

const sidebarStyle = css`
  > * {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;

    > span {
      margin-top: auto;
      text-align: center;
    }
  }
`;

enum MenuKeys {
  CERTIFICATION = 'certification',
  SYSTEM = 'system'
}

interface Props {
  currentPath: string;
  navigateTo: (path: string) => void;
}

export const Sidebar: React.FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  const menuItems: ItemType[] = [
    {
      key: RoutePath.DASHBOARD,
      label: formatMessage({ id: 'view.dashboard' }),
      icon: <DashboardOutlined />
    },
    {
      key: MenuKeys.CERTIFICATION,
      label: formatMessage({ id: 'view.configuration' }),
      icon: <ControlOutlined />,
      children: [
        {
          key: RoutePath.BILLING,
          label: formatMessage({ id: 'view.billing' }),
          icon: <DollarOutlined />
        },
        {
          key: RoutePath.CERTIFICATION,
          label: formatMessage({ id: 'view.certification' }),
          icon: <AuditOutlined />
        },
        {
          key: RoutePath.ITEM_GROUP,
          label: formatMessage({ id: 'model.itemGroups' }),
          icon: <TagsOutlined />
        },
        {
          key: RoutePath.COMPANIES,
          label: formatMessage({ id: 'model.companies' }),
          icon: <ShopOutlined />
        },
        {
          key: RoutePath.INVITATIONS,
          label: formatMessage({ id: 'model.invitations' }),
          icon: <MailOutlined />
        },
        {
          key: RoutePath.PARTICIPANTS,
          label: formatMessage({ id: 'model.participants' }),
          icon: <SmileOutlined />
        },
        {
          key: RoutePath.RESULTS,
          label: formatMessage({ id: 'model.results' }),
          icon: <SafetyCertificateOutlined />
        }
      ]
    },
    {
      key: MenuKeys.SYSTEM,
      label: formatMessage({ id: 'app.system' }),
      icon: <SlidersOutlined />,
      children: [
        {
          key: RoutePath.SETTINGS,
          label: formatMessage({ id: 'view.settings' }),
          icon: <SettingOutlined />
        },
        {
          key: RoutePath.REPORTING,
          label: formatMessage({ id: 'view.reporting' }),
          icon: <ExportOutlined />
        },
        {
          key: RoutePath.USERS,
          label: formatMessage({ id: 'model.users' }),
          icon: <UserOutlined />
        }
      ]
    }
  ];

  const getSelectedKeysFromPath = React.useCallback(
    () =>
      Object.values(RoutePath).map((m) => {
        if ((props.currentPath.startsWith(m) && m.length > 1) || m === props.currentPath) {
          return m.toString();
        }
        return undefined;
      }),
    [props.currentPath]
  );

  const [selectedKeys, setSelectedKeys] = React.useState(getSelectedKeysFromPath());

  React.useEffect(() => {
    setSelectedKeys(getSelectedKeysFromPath());
  }, [props.currentPath, getSelectedKeysFromPath]);

  const handleMenuClick = (item: { key: React.Key }) => {
    props.navigateTo(item.key.toString());
  };

  return (
    <Layout.Sider width="280" css={sidebarStyle}>
      <Menu mode="inline" selectedKeys={selectedKeys} defaultOpenKeys={Object.values(MenuKeys)} onClick={handleMenuClick} items={menuItems} />
      <Typography.Text>Version {version}</Typography.Text>
    </Layout.Sider>
  );
};
