/** @jsxImportSource @emotion/react */
import { MultiChoiceItem } from '../../../models/item';
import * as React from 'react';
import { MultiChoiceAnswer } from '../../../models/answer';
import { Checkbox, theme } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { GlobalToken } from 'antd/lib/theme/interface';

export const checkboxGroupStyle = css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  > label.ant-checkbox-wrapper {
    margin-inline-start: 0;
  }
`;

export const getCorrectOrWrongStyle = (isCorrect: boolean) => (theme: GlobalToken) =>
  css`
    span {
      color: ${isCorrect ? theme.colorSuccess : theme.colorError};
    }
  `;

interface Props {
  item: MultiChoiceItem;
  answer?: MultiChoiceAnswer;
  responseItemId: number;
}

export const MultiChoiceItemDisplay: React.FC<Props> = ({ answer, item }) => {
  const { token } = theme.useToken();

  const renderCorrection = (current: string) => {
    const isSelected = answer?.value.choice.indexOf(current) !== -1;
    const isCorrect = item.definition.solution?.indexOf(current) !== -1;

    if (isSelected) {
      return isCorrect ? <CheckCircleOutlined /> : <CloseCircleOutlined />;
    }
  };

  return (
    <Checkbox.Group disabled={true} value={answer?.value.choice} css={checkboxGroupStyle}>
      {item.definition.choice.map((c, i) => {
        const isCorrect = item.definition.solution?.includes(c);
        return (
          <Checkbox key={i} value={c} css={getCorrectOrWrongStyle(isCorrect)(token)}>
            {c} {renderCorrection(c)}
          </Checkbox>
        );
      })}
    </Checkbox.Group>
  );
};
