import { Divider, Typography } from 'antd';
import React from 'react';

type InputHintProps = {
  hint: string;
};

export function InputHint({ hint }: InputHintProps) {
  return (
    <Typography>
      <Divider />
      {hint}
    </Typography>
  );
}
