export enum Query {
  BILLS = 'bills',
  COMPANIES = 'companies',
  COUNTRIES = 'countries',
  PARTICIPANTS = 'participants',
  GENDERS = 'genders',
  RESULTS = 'results',
  CERTIFICATIONS = 'certifications',
  INVITATIONS = 'invitations',
  SCHEDULED_INVITATIONS = 'scheduledInvitations',
  USERS = 'users',
  ITEM_TYPES = 'itemTypes',
  DEFAULT_VALUES = 'defaultValues',
  ITEM_GROUPS = 'itemGroups',
  ITEMS = 'items',
}

export const queryKeyTranslationsMap: { [key in Query]: string } = {
  [Query.BILLS]: 'model.bill',
  [Query.COMPANIES]: 'model.company',
  [Query.COUNTRIES]: 'model.country',
  [Query.PARTICIPANTS]: 'model.participant',
  [Query.GENDERS]: 'model.gender',
  [Query.RESULTS]: 'model.result',
  [Query.CERTIFICATIONS]: 'model.certification',
  [Query.INVITATIONS]: 'model.invitation',
  [Query.USERS]: 'model.user',
  [Query.SCHEDULED_INVITATIONS]: 'model.scheduledInvitation',
  [Query.ITEM_TYPES]: 'model.itemType',
  [Query.DEFAULT_VALUES]: 'model.defaultValue',
  [Query.ITEM_GROUPS]: 'model.itemGroup',
  [Query.ITEMS]: 'model.item'
};
