import { useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { RESTClient } from '../client/client';
import { setupDefaultNotificationMessage } from '../services/notificationService';
import { Query } from '../models/query';
import { Index, initialIndex } from '../models';
import { QueryParams, useQueryParams } from '../models/queryParams';
import { message } from 'antd';

type BaseEntity = { id: number };

export type CustomNotificationProps<T extends BaseEntity> = {
  attributeKey: keyof T;
  attributeTranslation: string;
};

const getDefaultNotificationProps: <T extends BaseEntity>() => CustomNotificationProps<T> = () => ({
  attributeKey: 'id',
  attributeTranslation: 'model.id'
});

export const buildQueryKeys = (queryKey: Query, queryParams: QueryParams) => {
  let queryKeys: string[] = [queryKey];
  if (queryParams) {
    if (queryParams.page) {
      queryKeys.push(queryParams.page.toString());
    }
    if (queryParams.sorting) {
      queryKeys.push(queryParams.sorting.field);
      queryKeys.push(queryParams.sorting.order);
    }
    if (queryParams.filters) {
      queryKeys = [...queryKeys, ...Object.entries(queryParams.filters).map(([key, value]) => `${key}:${value}`)];
    }
    if (queryParams.search) {
      queryKeys.push(queryParams.search);
    }
  }
  return queryKeys;
};

export const useIndexQuery = <T extends BaseEntity, D>(queryKey: Query, client: RESTClient<T, D>, queryParams?: QueryParams, enabled?: boolean) => {
  const queryKeys = buildQueryKeys(queryKey, queryParams);
  return useQuery<Index<T>>(queryKeys, async () => client.index(queryParams).then(({ data }) => data), {
    initialData: initialIndex,
    enabled
  });
};

export const useReadQuery = <T extends BaseEntity, D>(queryKey: Query, client: RESTClient<T, D>, entity: BaseEntity) => {
  return useQuery<T>([queryKey, entity?.id], async () => client.read(entity).then(({ data }) => data), {
    enabled: !!entity?.id
  });
};

export const useCreateMutation = <T extends BaseEntity, D>(
  queryKey: Query,
  client: RESTClient<T, D>,
  queryParams?: QueryParams,
  customNotificationProps: CustomNotificationProps<T> = getDefaultNotificationProps()
) => {
  const queryClient = useQueryClient();
  const [searchQueryParams, setQueryParams] = useQueryParams();
  queryParams = queryParams || searchQueryParams;
  const intl = useIntl();
  return useMutation((entity: D) => client.create(entity).then(({ data }) => data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(buildQueryKeys(queryKey, queryParams));
      setQueryParams(queryParams);

      message.success(
        setupDefaultNotificationMessage(
          intl,
          'notification.success.createdWithValue',
          queryKey,
          data[customNotificationProps.attributeKey].toString(),
          customNotificationProps.attributeTranslation
        )
      );
    }
  });
};

export const useUpdateMutation = <T extends BaseEntity, D>(
  queryKey: Query,
  client: RESTClient<T, D>,
  customNotificationProps: CustomNotificationProps<T> = getDefaultNotificationProps()
) => {
  const queryClient = useQueryClient();
  const [queryParams] = useQueryParams();
  const intl = useIntl();
  return useMutation((entity: T & { additionalParams?: Record<string, any> }) => client.update(entity, entity.additionalParams).then(({ data }) => data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(buildQueryKeys(queryKey, queryParams));

      message.success(
        setupDefaultNotificationMessage(
          intl,
          'notification.success.updatedWithValue',
          queryKey,
          data[customNotificationProps.attributeKey].toString(),
          customNotificationProps.attributeTranslation
        )
      );
    }
  });
};

export const useDeleteMutation = <T extends BaseEntity, D>(
  queryKey: Query,
  client: RESTClient<T, D>,
  customNotificationProps: CustomNotificationProps<T> = getDefaultNotificationProps()
) => {
  const queryClient = useQueryClient();
  const [queryParams] = useQueryParams();
  const intl = useIntl();
  return useMutation((entity: T) => client.delete(entity), {
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries(buildQueryKeys(queryKey, queryParams));

      message.success(
        setupDefaultNotificationMessage(
          intl,
          'notification.success.deletedWithValue',
          queryKey,
          variables[customNotificationProps.attributeKey].toString(),
          customNotificationProps.attributeTranslation
        )
      );
    }
  });
};
