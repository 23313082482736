import { Card, Progress, Typography } from 'antd';
import * as React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

export const ClientStorageCard: React.FC = () => {
  const intl = useIntl();

  const getLocalStorageSize = () => {
    let allLocalStorages = '';
    for (let i = 0; i < localStorage.length; i++) {
      allLocalStorages += localStorage.getItem(localStorage.key(i));
    }
    return 3 + (allLocalStorages.length * 16) / (8 * 1024);
  };

  const getLocalStorageMaximum = () => {
    if (navigator.userAgent.indexOf('Safari') < 0) {
      return 5 * 1024;
    } else {
      return 10 * 1024;
    }
  };

  const localStoragePercentage = (100 / getLocalStorageMaximum()) * getLocalStorageSize();

  return (
    <Card title={intl.formatMessage({ id: 'view.clientStorageCard.title' })}>
      <Typography.Text>
        <FormattedMessage
          id='view.clientStorageCard.localStorage'
          values={{ size: Math.round(getLocalStorageSize()) }}
        />
      </Typography.Text>
      <Progress percent={Math.round(localStoragePercentage)}></Progress>
    </Card>
  );
};
