import * as React from 'react';
import { Checkbox, Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { createRequiredRule } from '../../../config/validation';

export const VideoItemForm: React.FC = () => {
  const intl = useIntl();
  const requiredRule = createRequiredRule(intl);

  return (
    <>
      <Form.Item name={['definition', 'caption']} label={intl.formatMessage({ id: 'model.attribute.videoCaption' })}>
        <Input />
      </Form.Item>
      <Form.Item name={['definition', 'url']} label={intl.formatMessage({ id: 'model.attribute.videoUrl' })} rules={[requiredRule]}>
        <Input />
      </Form.Item>
      <Form.Item name={['definition', 'watchToEnd']} valuePropName="checked">
        <Checkbox>
          <FormattedMessage id="view.watchToEnd.label" />
        </Checkbox>
      </Form.Item>
    </>
  );
};
