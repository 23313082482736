import * as React from 'react';
import { Item, ItemImplementation, MultiChoiceItem, SingleChoiceItem } from '../../models/item';
import { Answer, AnswerImplementation, MultiChoiceAnswer, SingleChoiceAnswer } from '../../models/answer';
import styled from '@emotion/styled';
import { Card } from 'antd';
import { ItemInputDescription } from './ItemInputDescription';
import { MultiChoiceItemDisplay } from './itemType/MultiChoiceItemDisplay';
import { SingleChoiceItemDisplay } from './itemType/SingleChoiceItemDisplay';
import { calculateMultiChoicePoints, calculateSingleChoicePoints } from '../../models/transaction';

export const TaskContainer = styled(Card)`
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

interface Props {
  item: Omit<Item, 'id'>;
  answer?: Omit<Answer, 'id' | 'certificationItem'>;
  responseItemId: number;
}

export const ResultDisplay: React.FC<Props> = (props) => {
  const createItemProps = <T extends ItemImplementation, A extends AnswerImplementation>() => {
    return {
      item: props.item as T,
      answer: props.answer as A,
      responseItemId: props.responseItemId
    };
  };

  const renderItem = (item: Props['item']) => {
    switch (item.itemType.kind) {
      case 'multiChoiceItem': {
        const itemProps = createItemProps<MultiChoiceItem, MultiChoiceAnswer>();
        const awardedPoints = calculateMultiChoicePoints({
          item: itemProps.item,
          answer: itemProps.answer
        });
        return (
          <TaskContainer
            title={itemProps.item.definition.question}
            extra={<ItemInputDescription id={props.responseItemId} points={itemProps.item.definition.points} awardedPoints={awardedPoints} />}
          >
            <MultiChoiceItemDisplay {...itemProps} />
          </TaskContainer>
        );
      }
      case 'singleChoiceItem': {
        const itemProps = createItemProps<SingleChoiceItem, SingleChoiceAnswer>();
        const awardedPoints = calculateSingleChoicePoints({
          item: itemProps.item,
          answer: itemProps.answer
        });
        return (
          <TaskContainer
            title={itemProps.item.definition.question}
            extra={<ItemInputDescription id={props.responseItemId} points={itemProps.item.definition.points} awardedPoints={awardedPoints} />}
          >
            <SingleChoiceItemDisplay {...itemProps} />
          </TaskContainer>
        );
      }
      default:
        return null;
    }
  };

  return renderItem(props.item);
};
