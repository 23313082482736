import { Client } from "./client";

export enum ReportType {
    ALL_CERTIFICATIONS = 'all_certifications',
    LAST_CERTIFICATION_PER_PARTICIPANT = 'last_certification_per_participant',
    CURRENTLY_VALID_CERTIFICATIONS = 'currently_valid_certifications'
}

export class ReportClient extends Client {

    public constructor(token?: string) {
        super(token);
      }

    public getReportFile(reportType: ReportType) {
        return this.clientInstance.get(`/reports/${reportType}`);
    }
}