import * as React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { StageContainer } from '../components/layout/StageContainer';
import { useSelector } from 'react-redux';
import { Session } from '../models/session';
import styled from '@emotion/styled';
import { ClientStorageCard } from '../components/ClientStorageCard';
import { Typography } from 'antd';
import { State } from '../store';

const WelcomeContainer = styled.div`
  width: 100%;
  font-weight: bold;
`;

const WelcomeText = styled(Typography.Text)`
  display: block;
  margin-bottom: 6px;
`;

const DashboardContainer: React.FC = () => {
  const intl = useIntl();
  const sessionState = useSelector<State, Session>((state) => state.session);

  return (
    <StageContainer title={intl.formatMessage({ id: 'view.dashboard' })}>
      <WelcomeContainer>
        <WelcomeText>
          <FormattedMessage id="view.welcome" values={{ user: sessionState.currentUser.forename }} />
        </WelcomeText>
        <ClientStorageCard />
      </WelcomeContainer>
    </StageContainer>
  );
};

export default DashboardContainer;
