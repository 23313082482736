import * as React from 'react';
import { Checkbox, Form } from 'antd';
import { useIntl } from 'react-intl';
import { createRequiredRule } from '../../../config/validation';
import { ConfirmationItem } from '../../../models/item';

interface Props {
  item: ConfirmationItem;
  responseItemId: number;
}

export const ConfirmationItemInput: React.FC<Props> = (props) => {
  const intl = useIntl();
  const requiredRule = createRequiredRule(intl);

  return (
    <Form.Item name={['answers', props.responseItemId]} rules={[requiredRule]} valuePropName="checked">
      <Checkbox>{props.item.definition.value}</Checkbox>
    </Form.Item>
  );
};
