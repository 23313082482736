import * as React from 'react';
import { Certification } from '../../models/certification';
import { Table, Button, Popconfirm, Space } from 'antd';
import { useIntl } from 'react-intl';
import { NoDataText } from '../NoDataText';
import { SettingOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { User } from '../../models/user';
import { paginationLayoutConfig } from '../../config/pagination';
import { getActiveFilters, useDynamicTable, IndexProps, renderActiveCell } from '../../services/indexService';

interface Props extends IndexProps<Certification> {
  users: User[];
  deleteCertification: (certification: Certification) => void;
  openSettings: (id: number) => void;
  openEdit: (certification: Certification) => void;
}

export const CertificationIndex: React.FC<Props> = (props) => {
  const intl = useIntl();

  const { handleTableChanges, getFilterColumnProps, getSortColumnProps } = useDynamicTable();

  const createDeleteAction = (certification: Certification) => {
    return () => {
      props.deleteCertification(certification);
    };
  };

  const renderActions = (record: Certification) => (
    <Space.Compact>
      <Button onClick={() => props.openEdit(record)} icon={<EditOutlined />} />
      <Button onClick={() => props.openSettings(record.id)} icon={<SettingOutlined />} />
      {record.invitationsCount <= 0 && (
        <Popconfirm title={intl.formatMessage({ id: 'action.confirm.deletion' })} onConfirm={createDeleteAction(record)} placement="left">
          <Button icon={<DeleteOutlined />} />
        </Popconfirm>
      )}
    </Space.Compact>
  );

  const renderDescriptionCell = (_text: string, record: Certification) => {
    return record.description ? record.description : <NoDataText />;
  };

  return (
    <Table dataSource={props.index.data} pagination={{ ...paginationLayoutConfig, ...props.index.meta }} onChange={handleTableChanges} rowKey="id">
      <Table.Column title={intl.formatMessage({ id: 'model.id' })} dataIndex="id" {...getSortColumnProps('certifications.id')} width={80} />
      <Table.Column title={intl.formatMessage({ id: 'model.attribute.title' })} dataIndex="title" key="title" />
      <Table.Column title={intl.formatMessage({ id: 'model.attribute.description' })} dataIndex="description" render={renderDescriptionCell} />
      <Table.Column title={intl.formatMessage({ id: 'model.attribute.count' }, { entity: intl.formatMessage({ id: 'model.invitations' }) })} dataIndex="invitationsCount" />
      <Table.Column
        title={intl.formatMessage({ id: 'model.attribute.active' })}
        dataIndex="active"
        {...getFilterColumnProps('active', getActiveFilters(intl))}
        render={renderActiveCell}
      />
      <Table.Column title={intl.formatMessage({ id: 'actions' })} key="actions" render={renderActions} width="200px" align="center" />
    </Table>
  );
};
