import * as React from 'react';
import { Progress } from '../../models/transaction';
import { ItemInput } from '../item/ItemInput';
import { AnswerDraft } from '../../models/answer';
import styled from '@emotion/styled';
import { Button, Form } from 'antd';
import { FormattedMessage } from 'react-intl';

const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px;
`;
interface Props {
  responseItems: Progress['responseItems'];
  onAnswerChange: (answer: AnswerDraft) => void;
  onSubmit: () => void;
}

export const CertificationInput: React.FC<Props> = (props) => {
  const [form] = Form.useForm();
  React.useEffect(() => {
    const currentFormValues = {
      answers: props.responseItems.map((item) => item.answer && item.answer.value)
    };
    form.setFieldsValue(currentFormValues);
  }, [props.responseItems, form]);

  const createOnItemInputChange = (responseItemId: number) => {
    return (value: AnswerDraft['value']) => {
      props.onAnswerChange({
        responseItemId,
        value
      });
    };
  };

  return (
    <Form name="certificationInputForm" form={form} onFinish={props.onSubmit} className="certification" scrollToFirstError>
      {props.responseItems.map((responseItem, index) => {
        return (
          <ItemInput
            key={responseItem.id}
            responseItemId={responseItem.id}
            item={responseItem.item}
            answer={responseItem.answer}
            index={index}
            onChange={createOnItemInputChange(responseItem.id)}
          />
        );
      })}
      <SubmitContainer>
        <Button type="primary" size="large" htmlType="submit">
          <FormattedMessage id="action.submit" />
        </Button>
      </SubmitContainer>
    </Form>
  );
};
