import { FC, PropsWithChildren } from 'react';
import { Navigate, Outlet } from 'react-router';

export interface ProtectedOutletProps {
  isAuthenticated: boolean;
  isAllowed: boolean;
  restrictedPath: string;
  authenticationPath: string;
}

export const ProtectedOutlet: FC<PropsWithChildren<ProtectedOutletProps>> = ({ isAuthenticated, isAllowed, restrictedPath, authenticationPath, children }) => {
  let redirectPath = '';
  if (!isAuthenticated) {
    redirectPath = authenticationPath;
  }
  if (isAuthenticated && !isAllowed) {
    redirectPath = restrictedPath;
  }

  return redirectPath ? <Navigate to={{ pathname: redirectPath }} /> : <Outlet />;
};

export default ProtectedOutlet;
