import { StageContainer } from '../../components/layout/StageContainer';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { isGroup, ItemOrGroup, ItemsEditor } from '../../components/item/ItemsEditor';
import { useNavigate, useParams } from 'react-router';
import { CertificationItemDraft } from '../../models/certificationItem';
import _ from 'lodash';
import { useApiClient } from '../../hooks/client';
import { ItemTypeClient } from '../../client/itemType';
import { useIndexQuery, useReadQuery, useUpdateMutation } from '../../hooks/rootQueries';
import { Query } from '../../models/query';
import { CertificationClient } from '../../client/certification';
import { ItemForm } from '../../components/item/ItemForm';
import { SaveButton } from '../../components/SaveButton';
import { ConfirmLeaveDialog } from '../../components/ConfirmLeaveDialog';
import { CertificationItemGroupForm } from '../../components/item/CertificationItemGroupForm';
import { RoutePath } from '../Router';
import { ItemClient } from '../../client/item';
import { useMoveItemsMutation } from '../../hooks/items';
import { Button } from 'antd';
import { MoveItemsDialog } from '../../components/item/MoveItemsDialog';
import { Certification } from '../../models/certification';
import { ReferenceItemsForm } from '../../components/item/ReferenceItemsForm';
import { Item } from '../../models/item';

const CertificationSettingsContainer: React.FC = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const params = useParams();

  const goBack = React.useCallback(() => {
    navigate(RoutePath.CERTIFICATION);
  }, [navigate]);

  const [selectedItems, setSelectedItems] = React.useState<Item[]>([]);
  const [itemsSelectable, setItemsSelectable] = React.useState(false);
  const toggleItemsSelectable = () => setItemsSelectable(!itemsSelectable);

  const certificationClient = useApiClient(CertificationClient);
  const { data: certificationData, refetch } = useReadQuery(Query.CERTIFICATIONS, certificationClient, { id: parseInt(params.id) });
  const updateMutation = useUpdateMutation(Query.CERTIFICATIONS, certificationClient, { attributeKey: 'title', attributeTranslation: 'model.attribute.title' });

  const itemTypeClient = useApiClient(ItemTypeClient);
  const { data: itemTypeIndex } = useIndexQuery(Query.ITEM_TYPES, itemTypeClient);

  const itemClient = useApiClient(ItemClient);
  const moveMutation = useMoveItemsMutation(itemClient, selectedItems);

  const [certification, setCertification] = React.useState<Certification>();

  const getItems = () => certification?.certificationItems.map(({ item, certificationItemGroup }) => item || certificationItemGroup);

  const setItems = (items: ItemOrGroup[]) => {
    setCertification({
      ...certification,
      certificationItems: items.map(
        (item, index) =>
          ({
            ...(isGroup(item) ? { certificationItemGroup: item } : { item }),
            order: index
          } as CertificationItemDraft)
      )
    });
  };

  const addItem = (item: ItemOrGroup) => {
    setItems([...getItems(), item]);
  };

  const addItems = (items: Item[]) => {
    setItems([...getItems(), ...items]);
  };

  React.useEffect(() => {
    if (certificationData?.id !== certification?.id) {
      setCertification(certificationData);
    }
  }, [certification, certificationData]);

  const hasChanges = () => !_.isEqual(certificationData, certification);

  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const toggleConfirmDialog = () => setShowConfirmDialog(!showConfirmDialog);

  const handleMoveItems = (itemDestination: { certification?: number; itemGroup?: number }) => {
    moveMutation.mutate(itemDestination, {
      onSuccess: () => {
        refetch().then(({ data }) => setCertification(data));
      }
    });
  };

  const updateCertification = () => {
    updateMutation.mutate(certification, {
      onSuccess: () => {
        goBack();
        setItemsSelectable(false);
      }
    });
  };

  return (
    <>
      <StageContainer
        title={formatMessage({ id: 'view.certification' })}
        subTitle={certificationData ? certificationData.title : formatMessage({ id: 'misc.loading' })}
        hasChanges={hasChanges()}
        onBack={goBack}
        onConfirmBack={toggleConfirmDialog}
        headerActions={
          itemsSelectable ? (
            <>
              <Button onClick={toggleItemsSelectable}>{formatMessage({ id: 'action.cancel' })}</Button>
              <MoveItemsDialog selectedItems={selectedItems} onSubmit={handleMoveItems} />
            </>
          ) : (
            <>
              <Button onClick={toggleItemsSelectable}>{formatMessage({ id: 'action.moveItems' })}</Button>
              <CertificationItemGroupForm saveItemGroup={addItem} />
              <ReferenceItemsForm onSubmit={addItems} />
              <ItemForm saveItem={addItem} itemTypes={itemTypeIndex.data} />
              <SaveButton onSave={updateCertification} hasChanges={hasChanges()} />
            </>
          )
        }
      >
        <ItemsEditor itemTypes={itemTypeIndex.data} items={getItems()} onChange={setItems} selectable={itemsSelectable} onSelectionChange={setSelectedItems} isCertification />
      </StageContainer>
      <ConfirmLeaveDialog onCancel={toggleConfirmDialog} onLeaveAnyway={goBack} onSaveAndLeave={updateCertification} show={showConfirmDialog} />
    </>
  );
};

export default CertificationSettingsContainer;
