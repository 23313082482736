import { MailOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { AxiosError } from 'axios';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { InvitationClient } from '../../client/invitation';
import { UserClient } from '../../client/user';
import { InvitationForm } from '../../components/invitation/InvitationForm';
import { StageContainer } from '../../components/layout/StageContainer';
import { useApiClient } from '../../hooks/client';
import { useCreateMultipleInvitationsMutation } from '../../hooks/invitations';
import { useIndexQuery } from '../../hooks/rootQueries';
import { InvitationDraft } from '../../models/invitation';
import { Query } from '../../models/query';
import { createInitialParams, SortOrder } from '../../models/queryParams';
import { RoutePath } from '../Router';

export const InviteContainer: React.FC = () => {
  const { formatMessage } = useIntl();

  const navigate = useNavigate();

  const [form] = useForm();

  const [tempInvitations, setTempInvitations] = React.useState<InvitationDraft[]>();

  const [showDuplicatesDialog, setShowDuplicatesDialog] = React.useState(false);
  const toggleDuplicatesDialog = () => setShowDuplicatesDialog(!showDuplicatesDialog);

  const initialParams = createInitialParams('invitations.id');

  const invitationClient = useApiClient(InvitationClient);
  const createMutation = useCreateMultipleInvitationsMutation(invitationClient, initialParams);

  const userClient = useApiClient(UserClient);
  const { data: usersIndex } = useIndexQuery(Query.USERS, userClient, { sorting: { field: 'users.forename', order: SortOrder.ascend } });

  const goBack = () => {
    navigate(RoutePath.INVITATIONS);
  };

  const createInvitations = (invitations: InvitationDraft[], skipDuplicateValidation?: boolean) => {
    setTempInvitations(invitations);
    return createMutation.mutate(
      { invitations, skipDuplicateValidation },
      {
        onError: (error: AxiosError) => {
          if (error.response.status === 422) {
            setShowDuplicatesDialog(true);
          }
        },
        onSuccess: () => {
          setShowDuplicatesDialog(false);
          form.resetFields();
          goBack();
        }
      }
    );
  };

  return (
    <>
      <StageContainer
        title={formatMessage({ id: 'action.invite' }, { entity: formatMessage({ id: 'model.participants' }) })}
        headerActions={
          <Button type="primary" icon={<MailOutlined />} onClick={form.submit}>
            <FormattedMessage id="action.sendInvitations" children={(msg) => <span>{msg}</span>} />
          </Button>
        }
        onBack={goBack}
      >
        <InvitationForm onInvite={createInvitations} users={usersIndex.data} form={form} />
      </StageContainer>
      <Modal
        open={showDuplicatesDialog}
        title={formatMessage({ id: 'view.hasDuplicatesDialog.title' })}
        okText={formatMessage({ id: 'action.invite' }, { entity: '' })}
        onOk={() => {
          createInvitations(tempInvitations, true);
        }}
        onCancel={toggleDuplicatesDialog}
      >
        <FormattedMessage id="view.hasDuplicatesDialog.description" />
      </Modal>
    </>
  );
};
