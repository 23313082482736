import * as React from 'react';
import { Modal, Button, Form } from 'antd';
import { useIntl } from 'react-intl';
import { CheckOutlined } from '@ant-design/icons';
import { Bill } from '../../models/bill';
import { DatePicker } from '../DatePicker';
import { defaultDateTimeFormat } from '../../config/constants';

interface FormModel {
  paidOn?: Date;
}

interface Props {
  bill: Bill;
  saveBill: (bill: Bill) => void;
}

export const BillForm: React.FC<Props> = ({ bill, saveBill }) => {
  const intl = useIntl();
  const [form] = Form.useForm<FormModel>();
  const [loading, isLoading] = React.useState(false);
  const [open, isOpen] = React.useState(false);
  const toggleModal = () => isOpen(!open);

  React.useEffect(() => {
    if (open) {
      form.setFieldsValue({ paidOn: bill.paidOn && new Date() });
    }
  }, [bill, form, open]);

  const handleSubmit = ({ paidOn }: FormModel) => {
    isLoading(true);
    const newValue: Pick<Bill, 'paidOn'> = {
      paidOn: paidOn?.toISOString()
    };
    saveBill({ ...bill, ...newValue });
    isLoading(false);
    toggleModal();
  };

  const createButton = () => {
    return <Button onClick={toggleModal} icon={<CheckOutlined />} />;
  };

  const createTitle = () => {
    return intl.formatMessage({ id: 'action.update' }, { entity: intl.formatMessage({ id: 'model.bill' }) });
  };

  return (
    <>
      {createButton()}
      <Modal
        title={createTitle()}
        open={open}
        onCancel={toggleModal}
        onOk={() => form.validateFields().then(() => form.submit())}
        okText={intl.formatMessage({ id: 'action.save' })}
        maskClosable={false}
        afterClose={form.resetFields}
        centered={true}
        closable={!loading}
        okButtonProps={{ loading: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form name="billForm" form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item name="paidOn" label={intl.formatMessage({ id: 'model.attribute.paidOn' })}>
            <DatePicker placeholder={intl.formatMessage({ id: 'model.attribute.paidOn' })} format={defaultDateTimeFormat} showNow showTime />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
