import { Select } from 'antd';
import { UserClient } from '../../client/user';
import { useApiClient } from '../../hooks/client';
import { useIndexQuery } from '../../hooks/rootQueries';
import { Query } from '../../models/query';
import { User } from '../../models/user';
import { resolveIdValues, resolveSelectedValue, SelectorProps } from '../../services/selectorService';

type Props = { label: string } & SelectorProps<User>;

export const UserSelector: React.FC<Props> = ({ value, onChange, label, selectProps }) => {
  const client = useApiClient(UserClient);
  const { data: userIndex } = useIndexQuery(Query.USERS, client, {});

  const handleChange = (value: number | number[]) => {
    onChange(resolveSelectedValue(value, userIndex.data));
  };

  return (
    <Select
      placeholder={label}
      onSelect={handleChange}
      value={resolveIdValues(value)}
      options={userIndex.data.map((u) => ({ value: u.id, label: `${u.forename} ${u.surname}` }))}
      onClear={() => handleChange(undefined)}
      {...selectProps}
    />
  );
};
