import { Select, SelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';

interface Props<T> extends SelectProps<T> {
  onDebounceSearch: (value: string) => void;
}

export function DebounceSelect<T extends SelectValue = number>(props: Props<T>) {
  let timeout: NodeJS.Timeout;

  const handleClear = () => props.onDebounceSearch(undefined);

  const handleSearch = (value: string) => {
    if (value) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }

      const triggerSearch = () => props.onDebounceSearch(value);
      timeout = setTimeout(triggerSearch, 300);
    } else {
      handleClear();
      clearTimeout(timeout);
      timeout = null;
    }
  };

  const filteredProps = Object.keys(props)
    .filter((key) => key !== 'onDebounceSearch')
    .reduce((prev, key) => {
      prev[key] = props[key];
      return prev;
    }, {});

  return <Select<T> onSearch={handleSearch} onClear={handleClear} filterOption={false} allowClear showSearch {...filteredProps} />;
}
