import * as React from 'react';
import { Form, Input, Divider } from 'antd';
import { useIntl } from 'react-intl';
import { createRequiredRule } from '../../config/validation';

type Props = { name: string[]; title: string; required?: boolean };

export const NotificationTemplateForm: React.FC<Props> = ({ title, name, required = true }) => {
  const intl = useIntl();

  const requiredRule = createRequiredRule(intl);

  return (
    <>
      <Divider>{title}</Divider>
      <Form.Item name={[...name, 'subject']} label={intl.formatMessage({ id: 'model.attribute.subject' })} rules={required && [requiredRule]}>
        <Input placeholder={intl.formatMessage({ id: 'model.attribute.subject' })} />
      </Form.Item>
      <Form.Item name={[...name, 'message']} label={intl.formatMessage({ id: 'model.attribute.message' })} rules={required && [requiredRule]}>
        <Input.TextArea rows={5} maxLength={5000} placeholder={intl.formatMessage({ id: 'model.attribute.message' })} />
      </Form.Item>
    </>
  );
};
