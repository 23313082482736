import { Popconfirm, PopconfirmProps } from 'antd';
import React from 'react';

interface Props extends PopconfirmProps {
  shouldConfirm: boolean;
}

export const ConditionalPopconfirm: React.FC<Props> = (props) => {
  const [visible, setVisible] = React.useState(false);

  const handleVisibilityChange = () => {
    if (props.shouldConfirm) {
      setVisible(!visible);
    } else {
      props.onConfirm();
    }
  };

  return (
    <Popconfirm {...props} open={visible} onOpenChange={handleVisibilityChange}>
      {props.children}
    </Popconfirm>
  );
};
