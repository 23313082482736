import { AxiosError, AxiosResponse } from 'axios';
import { Session, initialSession } from '../models/session';
import { User } from '../models/user';
import { SessionClient } from '../client/session';
import { createClientAction } from './rootAction';
import { addNotification } from './notification';
import { NotificationType } from '../models/notification';
import jwt_decode from 'jwt-decode';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Helper
const createNewSession = (response: AxiosResponse<User>) => {
  const decodedToken: { sub: string; exp: number } = jwt_decode(response.headers.authorization);
  const currentUser = response.data;
  return {
    token: response.headers.authorization,
    subject: decodedToken.sub,
    expiration: decodedToken.exp,
    isAuthenticated: true,
    currentUser
  };
};

export const createSession = (email: string, password: string) =>
  createClientAction(SessionClient, (client, dispatch) => {
    client
      .signIn(email, password)
      .then((response) => {
        dispatch(updateSession(createNewSession(response)));
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          dispatch(
            addNotification({
              message: 'notification.error.invalidData',
              details: error.response.statusText,
              type: NotificationType.ERROR
            })
          );
        } else {
          dispatch(
            addNotification({
              message: 'notification.error.noConnection',
              type: NotificationType.ERROR
            })
          );
        }
      });
  });

export const sessionSlice = createSlice({
  name: 'session',
  initialState: initialSession,
  reducers: {
    updateSession: (state, action: PayloadAction<Partial<Session>>) => {
      return { ...state, ...action.payload };
    },
    deleteSession: () => {
      return initialSession;
    }
  }
});

export const { updateSession, deleteSession } = sessionSlice.actions;

export default sessionSlice.reducer;
