import React from 'react';
import { Button, Divider, Form, Input, InputProps, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { useForm } from 'antd/lib/form/Form';
import { DefaultValueRecord } from './SettingsIndex';
import { DefaultValueJsonSchemaDefinition, DefaultValueRequest } from '../../models/defaultValue';

interface Props {
  onSave: (record: DefaultValueRequest) => void;
  record: DefaultValueRecord;
}

export const SettingsForm: React.FC<Props> = (props) => {
  const { formatMessage } = useIntl();
  const { id, definition, value, children } = props.record;

  const [form] = useForm<Record<string, string>>();

  const [open, setOpen] = React.useState(false);
  const toggleModal = () => setOpen(!open);

  React.useEffect(() => {
    if (open) {
      form.setFieldsValue(children ? children.reduce((prev, curr) => ({ ...prev, [curr.id]: curr.value }), {}) : { [id]: value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleSubmit = (values: Record<string, string>) => {
    values = Object.entries(values).reduce((prev, [key, value]) => {
      const recordDefinition = children ? children.find((record) => record.id === key).definition : definition;
      return { ...prev, [key]: recordDefinition.type === 'number' ? parseInt(value) : value };
    }, {});
    props.onSave({ id, value: children ? values : values[id] });
    toggleModal();
  };

  const createInputField = (name: string, definition: DefaultValueJsonSchemaDefinition) => {
    const inputProps: React.ComponentProps<typeof Input | typeof Input.TextArea> = { placeholder: name, maxLength: definition.maxLength };
    switch (definition.type) {
      case 'string':
        return definition.maxLength >= 10000 ? <Input.TextArea {...(inputProps as typeof Input.TextArea)} rows={8} /> : <Input {...(inputProps as InputProps)} />;
      case 'number':
        return <Input {...(inputProps as typeof Input)} type="number" max={definition.maximum} addonAfter={definition.unit} />;
      default:
        break;
    }
  };

  return (
    <>
      <Button icon={<EditOutlined />} onClick={toggleModal} />
      <Modal
        title={formatMessage({ id: 'view.updateDefaultValue' }, { id })}
        open={open}
        onCancel={toggleModal}
        onOk={() => form.validateFields().then(() => form.submit())}
        okText={formatMessage({ id: 'action.save' })}
        afterClose={form.resetFields}
      >
        <Form<Record<string, string>> form={form} onFinish={handleSubmit}>
          {definition.description}
          <Divider />
          {children?.map((record) => (
            <Form.Item name={record.id} key={record.id}>
              {createInputField(record.id, record.definition)}
            </Form.Item>
          )) ?? <Form.Item name={id}>{createInputField(id, definition)}</Form.Item>}
        </Form>
      </Modal>
    </>
  );
};
