import React from 'react';
import { useIntl } from 'react-intl';
import { ParticipantClient } from '../../client/participant';
import { Participant } from '../../models/participant';
import { Query } from '../../models/query';
import { SelectorProps } from '../../services/selectorService';
import { APISearchableSelector } from './APISearchableSelector';

type Props = {
  activeParticipantsOnly?: boolean;
} & SelectorProps<Participant>;

export const ParticipantSelector: React.FC<Props> = ({ onChange, value, selectProps, activeParticipantsOnly }) => {
  const { formatMessage } = useIntl();

  const filters = activeParticipantsOnly && { active: 'true', company_active: 'true' };
  const buildLabel = (participant: Participant) => `${participant.forename} ${participant.surname} (${participant.company.name})`;

  return (
    <APISearchableSelector
      queryName={Query.PARTICIPANTS}
      clientClass={ParticipantClient}
      filters={filters}
      buildLabel={buildLabel}
      value={value}
      onChange={onChange}
      selectProps={{ placeholder: formatMessage({ id: 'model.participant' }), ...selectProps }}
    />
  );
};
