import * as React from 'react';
import { Button, Space, Table, Tag } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { ItemType } from '../../models/itemType';
import { Item, ItemDraft } from '../../models/item';
import { ItemDescription } from './ItemDescription';
import { DragSortingTable } from '../DragSortingTable';
import { DeleteOutlined } from '@ant-design/icons';
import { ItemForm } from './ItemForm';
import { CertificationItemGroup, CertificationItemGroupDraft } from '../../models/certificationItemGroup';
import { useReadQuery } from '../../hooks/rootQueries';
import { useApiClient } from '../../hooks/client';
import { ItemGroupClient } from '../../client/itemGroup';
import { Query } from '../../models/query';
import { CertificationItemGroupForm } from './CertificationItemGroupForm';
import { TableRowSelection } from 'antd/lib/table/interface';

export type ItemOrGroup = Item | ItemDraft | CertificationItemGroup | CertificationItemGroupDraft;

export const isGroup = (item: ItemOrGroup) => !item['itemType'];
export const isNew = (item: ItemOrGroup) => !item['id'];

const getRowKey = (record: ItemOrGroup) => record['id'] || record['uniqueKey'];

interface Props {
  itemTypes?: ItemType[];
  items: ItemOrGroup[];
  selectable?: boolean;
  onSelectionChange?: (ids: Item[]) => void;
  onChange?: (items: ItemOrGroup[]) => void;
  isCertification?: boolean;
}

export const ItemsEditor: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const [rowSelection, setRowSelection] = React.useState<number[]>([]);

  const [expandedRowKey, setExpandedRowKey] = React.useState<React.Key>();
  const [expandedGroupId, setExpandedGroupId] = React.useState<number>();

  const itemGroupClient = useApiClient(ItemGroupClient);
  const { data: expandedItemGroup, isLoading: isLoadingItemGroup } = useReadQuery(Query.ITEM_GROUPS, itemGroupClient, { id: expandedGroupId });

  const isReferencedItem = (item: ItemOrGroup) => !!item.itemGroup && props.isCertification;

  const renderItemType = (_text: string, record: ItemOrGroup) => (
    <Space>
      {isGroup(record) ? (record as CertificationItemGroup).itemGroup.name : <FormattedMessage id={`model.itemType.${(record as Item).itemType.kind}`} />}
      {isNew(record) && (
        <Tag color="success">
          <FormattedMessage id="view.new" />
        </Tag>
      )}
    </Space>
  );

  const renderDescription = (_text: string, record: ItemOrGroup) => {
    if (isGroup(record)) {
      record = record as CertificationItemGroup;
      return (
        <Space>
          <Tag>{record.amountOfToAsk ? formatMessage({ id: 'view.amountOfItems' }, { amount: record.amountOfToAsk }) : formatMessage({ id: 'view.all' })}</Tag>
          {record.randomize && (
            <Tag>
              <FormattedMessage id="model.attribute.randomize" />
            </Tag>
          )}
        </Space>
      );
    }

    const { itemGroup } = record as Item;

    return (
      <Space>
        <ItemDescription item={record as Item} />
        {itemGroup && props.isCertification && (
          <Tag>
            {itemGroup.name} ({formatMessage({ id: 'model.id' })}: {itemGroup.id})
          </Tag>
        )}
      </Space>
    );
  };

  const renderActions = (_value: string, record: ItemOrGroup, index: number) => {
    const createDeleteAction = () => {
      props.onChange(props.items.filter((_item, i) => i !== index));
    };
    const createUpdateAction = (updatedRecord: ItemOrGroup) => {
      props.onChange(props.items.map((record, i) => (i === index ? { ...record, ...updatedRecord } : record)));
    };

    return (
      <Space.Compact>
        {isGroup(record) ? (
          <CertificationItemGroupForm certificationItemGroup={record as CertificationItemGroup} saveItemGroup={createUpdateAction} />
        ) : (
          !isReferencedItem(record) && <ItemForm item={record as Item} itemTypes={props.itemTypes} saveItem={createUpdateAction} />
        )}
        <Button icon={<DeleteOutlined />} onClick={createDeleteAction} />
      </Space.Compact>
    );
  };

  const handleDragSort = (rows: Item[]) => {
    const orderedRows = rows.map((r, i) => ({ ...r, order: i }));
    props.onChange(orderedRows);
  };

  const getRowSelectionConfig = (): TableRowSelection<ItemOrGroup> =>
    props.selectable && {
      selectedRowKeys: rowSelection,
      onChange: (ids: number[], selectedRows: Item[]) => {
        props.onSelectionChange?.(selectedRows);
        setRowSelection(ids);
      },
      getCheckboxProps: (record) => ({
        disabled: isGroup(record) || isNew(record) || isReferencedItem(record)
      }),
      preserveSelectedRowKeys: true
    };

  const createItemTypeColumn = () => <Table.Column title={formatMessage({ id: 'model.itemType' })} key="kind" width="240px" render={renderItemType} />;
  const createDescriptionColumn = () => <Table.Column title={formatMessage({ id: 'model.attribute.description' })} key="description" ellipsis={true} render={renderDescription} />;

  return props.selectable ? (
    <Table dataSource={props.items} pagination={false} rowKey={getRowKey} rowSelection={getRowSelectionConfig()}>
      {createItemTypeColumn()}
      {createDescriptionColumn()}
    </Table>
  ) : (
    <DragSortingTable<ItemOrGroup>
      dataSource={props.items}
      pagination={false}
      rowKey={getRowKey}
      onDragSort={handleDragSort}
      expandable={{
        rowExpandable: isGroup,
        expandedRowKeys: [expandedRowKey],
        onExpand: (expanded, record) => {
          if (expanded) {
            setExpandedGroupId((record as CertificationItemGroup).itemGroup.id);
            setExpandedRowKey(getRowKey(record));
          } else {
            setExpandedRowKey(undefined);
          }
        },
        expandedRowRender: () => (
          <Table dataSource={expandedItemGroup?.items} showHeader={false} pagination={false} loading={isLoadingItemGroup} rowKey="id">
            {createItemTypeColumn()}
            {createDescriptionColumn()}
          </Table>
        )
      }}
    >
      {createItemTypeColumn()}
      {createDescriptionColumn()}
      <Table.Column title={formatMessage({ id: 'actions' })} key="actions" render={renderActions} width="200px" align="center" />
    </DragSortingTable>
  );
};
