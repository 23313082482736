import { RESTClient } from './client';
import { ScheduledInvitation } from '../models/scheduledInvitation';

export class ScheduledInvitationClient extends RESTClient<ScheduledInvitation> {
  public constructor(token?: string) {
    super('scheduledInvitations', token);
  }

  public cancel(id: number) {
    return this.clientInstance.put<ScheduledInvitation>(`${this.path}/${id}/cancel`);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public create(_entity: ScheduledInvitation): never {
    throw new Error('Not supported by API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public read(_entity: ScheduledInvitation): never {
    throw new Error('Not supported by API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public update(_entity: ScheduledInvitation): never {
    throw new Error('Not supported by API');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public delete(_entity: ScheduledInvitation): never {
    throw new Error('Not supported by API');
  }
}
