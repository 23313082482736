import * as React from 'react';
import { User, initialUser, UserDraft } from '../../models/user';
import { FormMode } from '../../models/form';
import { Modal, Button, Input, Form, Upload, Image, Space } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { UserAddOutlined, EditOutlined } from '@ant-design/icons';
import { Rule } from 'antd/lib/form';
import { createRequiredRule } from '../../config/validation';
import { useRef } from 'react';
import { useCallback } from 'react';

interface FormModel {
  forename: string;
  surname: string;
  functionalRole?: string;
  signature?: File;
  email: string;
  password: string;
  passwordConfirmation: string;
}

interface Props {
  user?: User;
  mode?: FormMode;
  saveUser: (user: User | UserDraft) => void;
}

export const UserForm: React.FC<Props> = ({ user, mode, saveUser }) => {
  const intl = useIntl();
  const requiredRule = createRequiredRule(intl);
  const [form] = Form.useForm();
  const currentUser = useRef<User>();
  const setCurrentUser = useCallback(() => {
    currentUser.current = user || initialUser;
  }, [user]);
  setCurrentUser();
  let currentMode = FormMode.CREATE;
  if (mode) {
    currentMode = mode;
  } else {
    if (user) {
      currentMode = FormMode.UPDATE;
    }
  }

  const [loading, isLoading] = React.useState(false);
  const [open, isOpen] = React.useState(false);
  const [newSignature, setNewSignature] = React.useState('');
  const toggleModal = useCallback(() => isOpen(!open), [open]);

  React.useEffect(() => {
    if (loading && open) {
      setCurrentUser();
      isLoading(false);
      toggleModal();
    }
  }, [user, loading, open, setCurrentUser, toggleModal]);

  const handleSubmit = (values: FormModel) => {
    isLoading(true);
    saveUser({ ...currentUser.current, ...values });
    if (currentMode === FormMode.CREATE) {
      isLoading(false);
      toggleModal();
    }
  };

  const createButtons = () => {
    switch (currentMode) {
      case FormMode.CREATE:
        return (
          <Button onClick={toggleModal} icon={<UserAddOutlined />}>
            {intl.formatMessage({ id: 'action.create' }, { entity: intl.formatMessage({ id: 'model.user' }) })}
          </Button>
        );
      case FormMode.UPDATE:
        return <Button onClick={toggleModal} icon={<EditOutlined />} />;
      default:
        return;
    }
  };

  const createTitle = () => {
    switch (currentMode) {
      case FormMode.CREATE:
        return intl.formatMessage({ id: 'action.create' }, { entity: intl.formatMessage({ id: 'model.user' }) });
      case FormMode.READ:
        return intl.formatMessage({ id: 'action.read' }, { entity: intl.formatMessage({ id: 'model.user' }) });
      case FormMode.UPDATE:
        return intl.formatMessage({ id: 'action.update' }, { entity: intl.formatMessage({ id: 'model.user' }) });
      default:
        return;
    }
  };

  const validatePasswordConfirmation = (_rule: Rule, value: string) => {
    return new Promise<void>((resolve, reject) => {
      if (value && value !== form.getFieldValue('password')) {
        reject("Password confirmation doesn't match");
      } else {
        resolve();
      }
    });
  };

  const handleUpload = (file: File) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setNewSignature(fileReader.result.toString());
    };
    fileReader.readAsDataURL(file);
    return false;
  };

  const getValueFromEvent = (props: React.ChangeEvent<HTMLInputElement>) => {
    return props.target.files.length > 0 ? props.target.files[0] : false;
  };

  const resolveSignatureSource = () => newSignature || currentUser.current.signatureUrl;

  return (
    <>
      {createButtons()}
      <Modal
        title={createTitle()}
        open={open}
        onCancel={toggleModal}
        onOk={() =>
          form
            .validateFields()
            .then(() => form.submit())
            .catch(() => null)
        }
        okText={intl.formatMessage({ id: 'action.save' })}
        maskClosable={false}
        afterClose={() => {
          form.resetFields();
          setNewSignature('');
        }}
        centered={true}
        closable={!loading}
        okButtonProps={{ loading: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form name="userForm" form={form} onFinish={handleSubmit} autoComplete="new-password" initialValues={currentUser.current} layout="vertical" encType="multipart/form-data">
          <Form.Item name="forename" rules={[requiredRule]} label={intl.formatMessage({ id: 'model.attribute.forename' })}>
            <Input placeholder={intl.formatMessage({ id: 'model.attribute.forename' })} />
          </Form.Item>
          <Form.Item name="surname" rules={[requiredRule]} label={intl.formatMessage({ id: 'model.attribute.surname' })}>
            <Input placeholder={intl.formatMessage({ id: 'model.attribute.surname' })} />
          </Form.Item>
          <Form.Item name="functionalRole" label={intl.formatMessage({ id: 'model.attribute.functionalRole' })}>
            <Input placeholder={intl.formatMessage({ id: 'model.attribute.functionalRole' })} />
          </Form.Item>
          <Form.Item name="signature" label={intl.formatMessage({ id: 'model.attribute.signature' })} getValueFromEvent={getValueFromEvent}>
            <Space size="small">
              <Upload beforeUpload={handleUpload} showUploadList={false} multiple={false} accept="image/png">
                <Button>
                  <>
                    <FormattedMessage id="action.upload" values={{ entity: intl.formatMessage({ id: 'model.attribute.signature' }) }} />
                    {() => form.getFieldValue('signature')}
                  </>
                </Button>
              </Upload>
              {resolveSignatureSource() ? <Image width={200} preview={false} src={resolveSignatureSource()} /> : <FormattedMessage id="warning.noSignature" />}
            </Space>
          </Form.Item>
          <Form.Item name="email" rules={[requiredRule]} label={intl.formatMessage({ id: 'model.attribute.email' })}>
            <Input placeholder={intl.formatMessage({ id: 'model.attribute.email' })} type="email" autoComplete="new-password" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { ...requiredRule, required: currentMode === FormMode.CREATE },
              {
                min: 6,
                message: intl.formatMessage({ id: 'validation.minimumLength' }, { length: '6' })
              }
            ]}
            label={intl.formatMessage({ id: 'model.attribute.password' })}
          >
            <Input.Password placeholder={intl.formatMessage({ id: 'model.attribute.password' })} autoComplete="new-password" />
          </Form.Item>
          <Form.Item
            name="passwordConfirmation"
            rules={[
              { ...requiredRule, required: currentMode === FormMode.CREATE },
              {
                message: intl.formatMessage({ id: 'validation.confirmPassword' }),
                validator: validatePasswordConfirmation
              }
            ]}
            label={intl.formatMessage({ id: 'model.attribute.passwordConfirmation' })}
          >
            <Input.Password placeholder={intl.formatMessage({ id: 'model.attribute.passwordConfirmation' })} autoComplete="new-password" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
