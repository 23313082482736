import { message } from 'antd';
import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { ParticipantClient } from '../client/participant';
import { ParticipantDraft } from '../models/participant';
import { Query } from '../models/query';
import { QueryParams, useQueryParams } from '../models/queryParams';
import { buildQueryKeys } from './rootQueries';

export const useCreateMultipleParticipantsMutation = (client: ParticipantClient, queryParams: QueryParams) => {
  const queryClient = useQueryClient();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_queryParams, setQueryParams] = useQueryParams();
  const { formatMessage } = useIntl();
  return useMutation((participants: ParticipantDraft[]) => client.createMultiple(participants).then(({ data }) => data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(buildQueryKeys(Query.PARTICIPANTS, queryParams))
      setQueryParams(queryParams)

      message.success(formatMessage({ id: 'notification.success.created' }, { entity: `${data.length} ${formatMessage({ id: 'model.participants' })}` }));
    }
  });
};
