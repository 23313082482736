import { message } from 'antd';
import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { ResultClient } from '../client/result';
import { Query } from '../models/query';
import { useQueryParams } from '../models/queryParams';
import { Result, ResultDraft } from '../models/result';
import { setupDefaultNotificationMessage } from '../services/notificationService';
import { buildQueryKeys } from './rootQueries';

export const useRegenerateCertificateMutation = (client: ResultClient) => {
  const [queryParams] = useQueryParams();
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  return useMutation((result: Result) => client.regenerateCertificate(result).then(({ data }) => data), {
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries(buildQueryKeys(Query.RESULTS, queryParams));
      message.success(formatMessage({ id: 'notification.success.regenerateCertificate' }, { id: variables.id }));
    }
  });
};

export const useCreateResultMutation = (client: ResultClient) => {
  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] = useQueryParams();
  const intl = useIntl();
  return useMutation(
    (resultRequest: { result: ResultDraft; skipDuplicateValidation?: boolean }) =>
      client.create(resultRequest.result, resultRequest.skipDuplicateValidation).then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(buildQueryKeys(Query.INVITATIONS, queryParams));
        setQueryParams(queryParams);

        message.success(setupDefaultNotificationMessage(intl, 'notification.success.createdWithValue', Query.RESULTS, data.id));
      }
    }
  );
};
