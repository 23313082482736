import * as React from 'react';
import { useIntl } from 'react-intl';
import { ReportClient, ReportType } from '../client/report';
import { StageContainer } from '../components/layout/StageContainer';
import { ReportingCard } from '../components/reporting/ReportingCard';
import { useApiClient } from '../hooks/client';
import { useDownloadReportMutation } from '../hooks/report';

export type ReportingCardContent = {
  titleKey: string;
  options: {
    titleKey: string;
    descriptionKey: string;
    onExport: () => void;
  }[];
};

const createReportingCards = (downloadReport: (reportType: ReportType) => void): ReportingCardContent[] => [
  {
    titleKey: 'view.participantCertifications',
    options: [
      {
        titleKey: 'view.reporting.allCertifications.title',
        descriptionKey: 'view.reporting.allCertifications.description',
        onExport: () => downloadReport(ReportType.ALL_CERTIFICATIONS)
      },
      {
        titleKey: 'view.reporting.lastCertifications.title',
        descriptionKey: 'view.reporting.lastCertifications.description',
        onExport: () => downloadReport(ReportType.LAST_CERTIFICATION_PER_PARTICIPANT)
      },
      {
        titleKey: 'view.reporting.currentlyValidCertifications.title',
        descriptionKey: 'view.reporting.currentlyValidCertifications.description',
        onExport: () => downloadReport(ReportType.CURRENTLY_VALID_CERTIFICATIONS)
      }
    ]
  }
];

export const ReportingContainer: React.FC = () => {
  const { formatMessage } = useIntl();

  const reportClient = useApiClient(ReportClient);
  const downloadReportMutation = useDownloadReportMutation(reportClient);

  return (
    <StageContainer title={formatMessage({ id: 'view.reporting' })}>
      {createReportingCards(downloadReportMutation.mutate).map((cardContent) => (
        <ReportingCard cardContent={cardContent} key={cardContent.titleKey} />
      ))}
    </StageContainer>
  );
};
