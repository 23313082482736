import * as React from 'react';
import { FormMode } from '../../models/form';
import { Modal, Button, Input, Select, Spin, Form, Checkbox, Divider } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Participant, ParticipantDraft } from '../../models/participant';
import { Company, CompanyDraft } from '../../models/company';
import { Gender } from '../../models/gender';
import { UserAddOutlined, EditOutlined } from '@ant-design/icons';
import { createEmailRule, createRequiredRule } from '../../config/validation';
import styled from '@emotion/styled';
import { CompanyForm } from '../company/CompanyForm';
import { useCreateMutation } from '../../hooks/rootQueries';
import { Query } from '../../models/query';
import { useApiClient } from '../../hooks/client';
import { CompanyClient } from '../../client/company';
import { CompanySelector } from '../selectors/CompanySelector';

const CompanyRow = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1rem;
`;

interface FormModel {
  forename: string;
  surname: string;
  email: string;
  active: boolean;
  copyInvitationsToCompany: boolean;
  company: Company;
  gender: {
    id: number;
  };
}

interface Props {
  genders: Gender[];
  participants: Participant[];
  participant?: Participant;
  mode?: FormMode;
  saveParticipant: (participant: Participant | ParticipantDraft) => void;
}

export const ParticipantForm: React.FC<Props> = ({ genders, participants, participant, mode, saveParticipant }) => {
  const intl = useIntl();
  const [form] = Form.useForm<FormModel>();
  const requiredRule = createRequiredRule(intl);

  let currentMode = FormMode.CREATE;
  if (mode) {
    currentMode = mode;
  } else if (participant) {
    currentMode = FormMode.UPDATE;
  }

  const companyClient = useApiClient(CompanyClient);
  const updateSelectedCompany = (company: Company) => {
    form.setFieldsValue({ company });
  };
  const createCompanyMutation = useCreateMutation(Query.COMPANIES, companyClient);

  const handleCreateNewCompany = (company: CompanyDraft) => {
    createCompanyMutation.mutate(company, { onSuccess: updateSelectedCompany });
  };

  const [loading, isLoading] = React.useState(false);
  const [open, isOpen] = React.useState(false);
  const toggleModal = () => isOpen(!open);

  const initialValues: Partial<FormModel> = {
    active: true
  };

  React.useEffect(() => {
    if (open && participant) {
      const { gender, company, ...rest } = participant;
      const genderId = genders?.find((g) => g.name === gender)?.id;
      form.setFieldsValue({ ...rest, gender: { id: genderId }, company });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = ({ company }: Partial<FormModel>) => {
    if (company) {
      form.setFieldsValue({ copyInvitationsToCompany: !!company.administrativeEmail });
    }
  };

  const handleSubmit = (values: FormModel) => {
    isLoading(true);
    const { gender, company, ...rest } = values;
    saveParticipant({ ...participant, ...rest, genderId: gender.id, companyId: company.id });
    isLoading(false);
    toggleModal();
  };

  const createButtons = () => {
    switch (currentMode) {
      case FormMode.CREATE:
        return (
          <Button onClick={toggleModal} icon={<UserAddOutlined />}>
            {intl.formatMessage({ id: 'action.create' }, { entity: intl.formatMessage({ id: 'model.participant' }) })}
          </Button>
        );
      case FormMode.UPDATE:
        return <Button onClick={toggleModal} icon={<EditOutlined />} />;
      default:
        return;
    }
  };

  const createTitle = () => {
    switch (currentMode) {
      case FormMode.CREATE:
        return intl.formatMessage({ id: 'action.create' }, { entity: intl.formatMessage({ id: 'model.participant' }) });
      case FormMode.READ:
        return intl.formatMessage({ id: 'action.read' }, { entity: intl.formatMessage({ id: 'model.participant' }) });
      case FormMode.UPDATE:
        return intl.formatMessage({ id: 'action.update' }, { entity: intl.formatMessage({ id: 'model.participant' }) });
      default:
        return;
    }
  };

  const getEmailRule = () => {
    const participantsToCheck = currentMode === FormMode.CREATE ? participants : participants.filter((p) => p.email !== participant.email);
    return createEmailRule(intl, participantsToCheck);
  };

  if (!genders) {
    return <Spin />;
  }

  return (
    <>
      {createButtons()}
      <Modal
        title={createTitle()}
        open={open}
        onCancel={toggleModal}
        onOk={() =>
          form
            .validateFields()
            .then(() => form.submit())
            .catch(() => null)
        }
        okText={intl.formatMessage({ id: 'action.save' })}
        maskClosable={false}
        afterClose={form.resetFields}
        centered={true}
        closable={!loading}
        okButtonProps={{ loading: loading }}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form<FormModel> name="participantForm" form={form} onFinish={handleSubmit} layout="vertical" onValuesChange={handleChange} initialValues={initialValues}>
          <Form.Item name={['gender', 'id']} label={intl.formatMessage({ id: 'model.gender' })}>
            <Select placeholder={intl.formatMessage({ id: 'model.gender' })}>
              {genders.map((g) => (
                <Select.Option value={g.id} key={g.id}>
                  <FormattedMessage id={`model.gender.${g.name}`} />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="forename" rules={[requiredRule]} label={intl.formatMessage({ id: 'model.attribute.forename' })}>
            <Input placeholder={intl.formatMessage({ id: 'model.attribute.forename' })} />
          </Form.Item>
          <Form.Item name="surname" rules={[requiredRule]} label={intl.formatMessage({ id: 'model.attribute.surname' })}>
            <Input placeholder={intl.formatMessage({ id: 'model.attribute.surname' })} />
          </Form.Item>
          <Form.Item name="email" rules={[requiredRule, getEmailRule()]} label={intl.formatMessage({ id: 'model.attribute.email' })}>
            <Input placeholder={intl.formatMessage({ id: 'model.attribute.email' })} type="email" autoComplete="new-password" />
          </Form.Item>
          <CompanyRow>
            <Form.Item name={['company']} rules={[requiredRule]} label={intl.formatMessage({ id: 'model.company' })} style={{ flex: 1 }}>
              <CompanySelector />
            </Form.Item>
            <CompanyForm saveCompany={handleCreateNewCompany} />
          </CompanyRow>
          <Divider />
          <Form.Item<FormModel> noStyle shouldUpdate={(prevValues, currentValues) => prevValues.company?.id !== currentValues.company?.id}>
            {({ getFieldsValue }) => (
              <Form.Item name={'copyInvitationsToCompany'} valuePropName="checked">
                <Checkbox disabled={!getFieldsValue().company?.administrativeEmail}>
                  <FormattedMessage id="view.copyInvitationsToCompany.label" />
                </Checkbox>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item name={'active'} valuePropName="checked">
            <Checkbox>
              <FormattedMessage id="model.attribute.active" />
            </Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
