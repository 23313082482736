import React from 'react';
import { Button, Modal } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {
  show: boolean;
  onCancel: () => void;
  onSaveWithoutUpdate: React.MouseEventHandler<HTMLElement>;
  onConfirmUpdateReferences: React.MouseEventHandler<HTMLElement>;
};

export const UpdateReferencesDialog: React.FC<Props> = ({ show, onCancel, onSaveWithoutUpdate, onConfirmUpdateReferences }) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      open={show}
      title={formatMessage({ id: 'view.updateReferencesDialog.title' })}
      onCancel={onCancel}
      footer={
        <>
          <Button onClick={onSaveWithoutUpdate}>
            <FormattedMessage id="action.saveWithoutUpdating" />
          </Button>
          <Button onClick={onConfirmUpdateReferences} type="primary">
            <FormattedMessage id="action.update" values={{ entity: '' }} />
          </Button>
        </>
      }
    >
      <FormattedMessage id="action.create" values={{ entity: formatMessage({ id: 'model.company' }) }} />
      {formatMessage({ id: 'view.updateReferencesDialog.description' })}
    </Modal>
  );
};
