import { Space, Table } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { SettingsForm } from './SettingsForm';
import { JSONSchema7, JSONSchema7Type } from 'json-schema';
import { DefaultValueJSONSchema, DefaultValueRequest, DefaultValues } from '../../models/defaultValue';

export type DefaultValueRecordField = {
  definition: JSONSchema7;
  value: JSONSchema7Type;
};

export type DefaultValueRecord = {
  id: string;
  definition: DefaultValueJSONSchema;
  value: JSONSchema7Type;
  children?: DefaultValueRecord[];
};

const generateRecords = (schema: DefaultValueJSONSchema, defaultValues: DefaultValues): DefaultValueRecord[] =>
  schema &&
  Object.keys(defaultValues).length > 0 &&
  Object.entries(schema.properties).map(([key, property]) => ({
    id: key,
    definition: property,
    value: defaultValues[key],
    children:
      property.properties &&
      Object.entries(property.properties).map(([childPropertyKey, childProperty]) => ({
        id: childPropertyKey,
        definition: childProperty,
        value: defaultValues[key]?.[childPropertyKey]
      }))
  }));

type Props = {
  schema: DefaultValueJSONSchema;
  defaultValues: DefaultValues;
  onDefaultValueChange: (record: DefaultValueRequest) => void;
};

export const SettingsIndex: React.FC<Props> = (props) => {
  const intl = useIntl();

  const data: DefaultValueRecord[] = generateRecords(props.schema, props.defaultValues);

  const renderValue = (value: JSONSchema7Type, record: DefaultValueRecord) => (
    <>
      {record.children
        ? record.children
            .map(({ value }) => value)
            .filter((x) => x)
            .join(',')
        : value}
    </>
  );

  const renderActions = (record: DefaultValueRecord) => (
    <Space.Compact>
      <SettingsForm record={record} onSave={props.onDefaultValueChange} />
    </Space.Compact>
  );

  return (
    <Table<DefaultValueRecord> dataSource={data} pagination={false} rowKey="id" childrenColumnName="false">
      <Table.Column title={intl.formatMessage({ id: 'model.id' })} dataIndex="id" key="id" defaultSortOrder="descend" width={400} />
      <Table.Column title={intl.formatMessage({ id: 'model.attribute.description' })} dataIndex={['definition', 'description']} key="description" ellipsis />
      <Table.Column title={intl.formatMessage({ id: 'model.attribute.value' })} dataIndex="value" key="value" render={renderValue} ellipsis />
      <Table.Column title={intl.formatMessage({ id: 'actions' })} key="actions" render={renderActions} width="200px" align="center" />
    </Table>
  );
};
