import React from 'react';
import { useIntl } from 'react-intl';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ItemGroupClient } from '../client/itemGroup';
import { ItemGroupForm } from '../components/itemGroup/ItemGroupForm';
import { ItemGroupIndex } from '../components/itemGroup/ItemGroupIndex';
import { ItemGroupSettings } from '../components/itemGroup/ItemGroupSettings';
import { StageContainer } from '../components/layout/StageContainer';
import { useApiClient } from '../hooks/client';
import { useCreateMutation, useIndexQuery, useUpdateMutation } from '../hooks/rootQueries';
import { Query } from '../models/query';
import { createInitialParams, useQueryParams } from '../models/queryParams';

export const ItemGroupContainer: React.FC = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const initialParams = createInitialParams('item_groups.id');
  const [queryParams] = useQueryParams(initialParams);

  const client = useApiClient(ItemGroupClient);
  const { data: itemGroupIndex } = useIndexQuery(Query.ITEM_GROUPS, client, queryParams);
  const updateMutation = useUpdateMutation(Query.ITEM_GROUPS, client);
  const createMutation = useCreateMutation(Query.ITEM_GROUPS, client, initialParams);

  const openSettings = (id: number) => {
    navigate(`${id}`);
  };

  const Index = (
    <StageContainer title={formatMessage({ id: 'model.itemGroups' })} headerActions={<ItemGroupForm saveItemGroup={createMutation.mutate} />}>
      <ItemGroupIndex index={itemGroupIndex} updateItemGroup={updateMutation.mutate} openSettings={openSettings} />
    </StageContainer>
  );
  const Settings = <ItemGroupSettings />;

  return (
    <Routes>
      <Route path="/" element={Index} />
      <Route path=":id" element={Settings} />
    </Routes>
  );
};
