import { RESTClient } from './client';
import { Participant, ParticipantDraft } from '../models/participant';

export class ParticipantClient extends RESTClient<Participant, ParticipantDraft> {
  public constructor(token?: string) {
    super('participants', token);
  }

  public createMultiple(entities: ParticipantDraft[]) {
    return this.clientInstance.post<Participant[]>(this.path, entities);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public delete(_entity: Participant): never {
    throw new Error('Not supported by API');
  }
}
