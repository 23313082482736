import * as React from 'react';
import { Table, Button, Tooltip, Space } from 'antd';
import { useIntl } from 'react-intl';
import { isManualResult, Result, ResultStatus } from '../../models/result';
import { EyeOutlined, FilePdfOutlined, FileSyncOutlined } from '@ant-design/icons';
import { ConditionalPopconfirm } from '../ConditionalPopconfirm';
import { paginationLayoutConfig } from '../../config/pagination';
import { getFiltersFromEnum, useDynamicTable, IndexProps, renderDateTimeCell, renderFullNameCell, renderStatusCell } from '../../services/indexService';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { MultiSelectFilter } from '../MultiSelectFilter';
import { ParticipantSelector } from '../selectors/ParticipantSelector';
import { CompanySelector } from '../selectors/CompanySelector';
import { CertificationSelector } from '../selectors/CertificationSelector';
import { isSubmitted } from '../../models/invitation';

interface Props extends IndexProps<Result> {
  openResult: (id: number) => void;
  onRegenerateCertificate: (result: Result) => void;
}

export const ResultIndex: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const { handleTableChanges, getFilterColumnProps, getSortColumnProps } = useDynamicTable();

  const createOpenResultAction = (record: Result) => {
    return () => {
      props.openResult(record.id);
    };
  };

  const renderActions = (record: Result) => (
    <Space.Compact>
      {isSubmitted(record.status) && !isManualResult(record) && (
        <Tooltip title={formatMessage({ id: 'action.show' }, { entity: formatMessage({ id: 'model.results' }) })}>
          <Button icon={<EyeOutlined />} onClick={createOpenResultAction(record)} />{' '}
        </Tooltip>
      )}
      {record.response?.credential && (
        <>
          <Tooltip title={formatMessage({ id: 'action.regenerate' }, { entity: formatMessage({ id: 'view.certificate' }) })}>
            <ConditionalPopconfirm
              shouldConfirm={!!record.response.certificationPdfUrl}
              title={formatMessage({ id: 'action.confirm.regeneration' })}
              okText={formatMessage({ id: 'action.regenerate' }, { entity: '' })}
              onConfirm={() => props.onRegenerateCertificate(record)}
              placement="bottomRight"
            >
              <Button icon={<FileSyncOutlined />} />
            </ConditionalPopconfirm>
          </Tooltip>
          {record.response.certificationPdfUrl && (
            <a href={record.response.certificationPdfUrl} target="_blank" rel="noreferrer">
              <Tooltip title={formatMessage({ id: 'action.show' }, { entity: formatMessage({ id: 'view.certificate' }) })}>
                <Button icon={<FilePdfOutlined />} />
              </Tooltip>
            </a>
          )}
        </>
      )}
    </Space.Compact>
  );

  const statusFilters = getFiltersFromEnum(ResultStatus, 'status', intl);

  const ParticipantMultiSelect = (props: FilterDropdownProps) => <MultiSelectFilter Selector={ParticipantSelector} filterDropdownProps={props} />;
  const CompanyMultiSelect = (props: FilterDropdownProps) => <MultiSelectFilter Selector={CompanySelector} filterDropdownProps={props} />;
  const CertificationMultiSelect = (props: FilterDropdownProps) => <MultiSelectFilter Selector={CertificationSelector} filterDropdownProps={props} filterAttributeName="rootId" />;

  return (
    <Table<Result> dataSource={props.index.data} loading={props.loading} pagination={{ ...props.index.meta, ...paginationLayoutConfig }} onChange={handleTableChanges} rowKey="id">
      <Table.Column title={formatMessage({ id: 'model.id' })} dataIndex="id" width={80} {...getSortColumnProps('invitations.id')} />
      <Table.Column
        title={formatMessage({ id: 'model.attribute.name' })}
        dataIndex="participant"
        render={(value: Result['participant']) => renderFullNameCell(value)}
        {...getFilterColumnProps('participant', ParticipantMultiSelect)}
      />
      <Table.Column title={formatMessage({ id: 'model.company' })} dataIndex={['participant', 'company', 'name']} {...getFilterColumnProps('company', CompanyMultiSelect)} />
      <Table.Column
        title={formatMessage({ id: 'model.certification' })}
        dataIndex={['certification', 'title']}
        {...getFilterColumnProps('certification', CertificationMultiSelect)}
      />
      <Table.Column
        title={formatMessage({ id: 'model.attribute.status' })}
        dataIndex="status"
        render={renderStatusCell(formatMessage)}
        {...getFilterColumnProps('status', statusFilters)}
      />
      <Table.Column
        title={formatMessage({ id: 'model.attribute.submittedAt' })}
        dataIndex={['response', 'submittedAt']}
        render={(text) => renderDateTimeCell(text)}
        {...getSortColumnProps('responses.updated_at')}
      />
      <Table.Column
        title={formatMessage({ id: 'model.attribute.expirationDate' })}
        dataIndex={['invitation', 'expiresAt']}
        render={(text) => renderDateTimeCell(text)}
        {...getSortColumnProps('invitation_expirations.expires_at')}
      />
      <Table.Column title={formatMessage({ id: 'model.attribute.credential' })} dataIndex={['response', 'credential']} />
      <Table.Column title={formatMessage({ id: 'actions' })} key="actions" render={renderActions} width="200px" align="center" />
    </Table>
  );
};
