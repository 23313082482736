import * as React from 'react';
import { Table, Space } from 'antd';
import { useIntl } from 'react-intl';
import { Company } from '../../models/company';
import { CompanyForm } from './CompanyForm';
import { useDynamicTable, IndexProps, renderActiveCell } from '../../services/indexService';
import { paginationLayoutConfig } from '../../config/pagination';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { MultiSelectFilter } from '../MultiSelectFilter';
import { CountrySelector } from '../selectors/CountrySelector';

interface Props extends IndexProps<Company> {
  updateCompany: (company: Company) => void;
}

export const CompanyIndex: React.FC<Props> = (props) => {
  const intl = useIntl();

  const { handleTableChanges, getFilterColumnProps, getSortColumnProps } = useDynamicTable();

  const CountryMultiSelect = (props: FilterDropdownProps) => <MultiSelectFilter Selector={CountrySelector} filterDropdownProps={props} />;

  const renderActions = (record: Company) => (
    <Space.Compact>
      <CompanyForm saveCompany={props.updateCompany} company={record} />
    </Space.Compact>
  );

  return (
    <Table dataSource={props.index.data} pagination={{ ...paginationLayoutConfig, ...props.index.meta }} onChange={handleTableChanges} rowKey="id">
      <Table.Column title={intl.formatMessage({ id: 'model.id' })} dataIndex="id" {...getSortColumnProps('companies.id')} width={80} />
      <Table.Column title={intl.formatMessage({ id: 'model.attribute.name' })} dataIndex="name" key="name" />
      <Table.Column title={intl.formatMessage({ id: 'model.attribute.street' })} dataIndex="street" key="street" />
      <Table.Column title={intl.formatMessage({ id: 'model.attribute.zip' })} dataIndex="zip" key="zip" />
      <Table.Column title={intl.formatMessage({ id: 'model.attribute.town' })} dataIndex="town" key="town" />
      <Table.Column title={intl.formatMessage({ id: 'model.country' })} dataIndex={['country', 'name']} {...getFilterColumnProps('country', CountryMultiSelect)} />
      <Table.Column title={intl.formatMessage({ id: 'model.attribute.active' })} dataIndex="active" render={renderActiveCell} />
      <Table.Column title={intl.formatMessage({ id: 'actions' })} key="actions" render={renderActions} width="200px" align="center" />
    </Table>
  );
};
