import styled from '@emotion/styled';
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player/youtube';
import { usePageVisibility } from '../../../services/pageVisibility';
import { Segmented } from 'antd';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const playerSize: { width: number; height: number } = {
  width: 840,
  height: 441
};

interface Props {
  url: string;
  playerConfig: Record<string, any>;
  playerSize?: { width: number; height: number };
  onChange?: (finished: boolean) => void;
  id?: string;
}

export const VideoFinishingInput: FC<Props> = (props) => {
  const isVisible = usePageVisibility();
  const [isPlaying, setIsPlaying] = useState(false);

  const [playbackRate, setPlaybackRate] = useState(1);

  return (
    <>
      <Container id={props.id}>
        <ReactPlayer
          playing={isVisible && isPlaying}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          url={props.url}
          fallback={<FormattedMessage id="view.videoNotFound" />}
          {...playerSize}
          onEnded={() => {
            setIsPlaying(false);
            props.onChange(true);
          }}
          playbackRate={playbackRate}
          config={{ playerVars: props.playerConfig, embedOptions: playerSize }}
        />
      </Container>
      <Segmented
        onChange={(value) => setPlaybackRate(value as number)}
        value={playbackRate}
        options={[
          {
            label: '1x',
            value: 1
          },
          { label: '1.5x', value: 1.5 }
        ]}
      />
    </>
  );
};
