import * as React from 'react';
import { Button, Space, Table, TableColumnsType, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import { Invitation, InvitationStatus, isExpired, isOpen } from '../../models/invitation';
import { CheckOutlined, ReloadOutlined, StopOutlined } from '@ant-design/icons';
import { paginationLayoutConfig } from '../../config/pagination';
import {
  getCertificationFilters,
  getYesNoFilters,
  useDynamicTable,
  IndexProps,
  renderDateTimeCell,
  renderFullNameCell,
  getCancelledRowComponentProps,
  renderStatusCell,
  getFiltersFromEnum
} from '../../services/indexService';
import { Certification } from '../../models/certification';
import { MultiSelectFilter } from '../MultiSelectFilter';
import { ParticipantSelector } from '../selectors/ParticipantSelector';
import { ResultStatus } from '../../models/result';
import { ReinviteDialog } from './ReinviteDialog';

interface Props extends IndexProps<Invitation> {
  certifications: Certification[];
  onReinvite: (id: number, validUntil?: Date) => void;
  onCancel: (id: number) => void;
}

export const InvitationIndex: React.FC<Props> = ({ certifications, onReinvite, onCancel, index, loading }) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const [idToReinvite, setIdToReinvite] = React.useState<number>();
  const hideReinviteDialog = () => setIdToReinvite(undefined);

  const { handleTableChanges, getFilterColumnProps, getSortColumnProps } = useDynamicTable();

  const columns: TableColumnsType<Invitation> = [
    {
      title: formatMessage({ id: 'model.id' }),
      dataIndex: 'id',
      width: 80,
      ...getSortColumnProps('invitations.id')
    },
    {
      title: formatMessage({ id: 'model.participant' }),
      dataIndex: 'participant',
      render: (_text, record: Invitation) => renderFullNameCell(record.participant),
      ...getFilterColumnProps('participant', (props) => <MultiSelectFilter Selector={ParticipantSelector} filterDropdownProps={props} />)
    },
    {
      title: formatMessage({ id: 'model.certification' }),
      dataIndex: ['certification', 'title'],
      ...getFilterColumnProps('certification', getCertificationFilters(certifications))
    },
    {
      title: formatMessage({ id: 'model.attribute.status' }),
      dataIndex: 'status',
      render: renderStatusCell(formatMessage),
      ...getFilterColumnProps('status', getFiltersFromEnum(InvitationStatus, 'status', intl))
    },
    {
      title: formatMessage({ id: 'model.attribute.validUntil' }),
      dataIndex: 'validUntil',
      render: (text) => renderDateTimeCell(text),
      ...getSortColumnProps('valid_until')
    },
    {
      title: formatMessage({ id: 'model.attribute.createdAt' }),
      dataIndex: 'createdAt',
      render: (text) => renderDateTimeCell(text),
      ...getSortColumnProps('invitations.created_at')
    },
    {
      title: formatMessage({ id: 'model.attribute.reinvite' }),
      dataIndex: 'reinvite',
      render: (value: boolean) => (value ? <CheckOutlined /> : <StopOutlined />),
      ...getFilterColumnProps('reinvite', getYesNoFilters(intl))
    },
    {
      title: formatMessage({ id: 'actions' }),
      align: 'center',
      render: (_value, record) =>
        !record.cancelledAt && (
          <Space.Compact>
            {(isExpired(ResultStatus[record.status]) || InvitationStatus.invited === record.status) && (
              <Tooltip title={formatMessage({ id: 'action.manuallyReinvite' })}>
                <Button onClick={() => setIdToReinvite(record.id)} icon={<ReloadOutlined />} />
              </Tooltip>
            )}
            {isOpen(record.status) && (
              <Tooltip title={formatMessage({ id: 'action.cancelInvitation' })}>
                <Button onClick={() => onCancel(record.id)} icon={<StopOutlined />} />
              </Tooltip>
            )}
          </Space.Compact>
        )
    }
  ];

  return (
    <>
      <ReinviteDialog open={!!idToReinvite} onClose={hideReinviteDialog} onSubmit={(validUntil) => onReinvite(idToReinvite, validUntil)} />
      <Table<Invitation>
        dataSource={index.data}
        loading={loading}
        columns={columns}
        pagination={{ ...index.meta, ...paginationLayoutConfig }}
        onChange={handleTableChanges}
        rowKey="id"
        onRow={getCancelledRowComponentProps(intl)}
      />
    </>
  );
};
