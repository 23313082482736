import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { TranslationProvider } from './components/TranslationProvider';
import { message } from 'antd';
import { QueryClient, QueryClientProvider } from 'react-query';
import ErrorBoundary from './containers/ErrorBoundary';
import { persistor, store } from './store';
import Router from './containers/Router';
import { ThemeProvider } from './components/ThemeProvider';

// Set up internationalization
if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/en');
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/locale-data/en');
}

// Set up user interface
message.config({
  top: 80,
  maxCount: 5
});

const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <TranslationProvider>
            <ThemeProvider>
              <Router />
            </ThemeProvider>
          </TranslationProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
