import { FormInstance } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { UserClient } from '../../client/user';
import { CertificationForm } from '../../components/certification/CertificationForm';
import { ConfirmLeaveDialog } from '../../components/ConfirmLeaveDialog';
import { StageContainer } from '../../components/layout/StageContainer';
import { SaveButton } from '../../components/SaveButton';
import { useApiClient } from '../../hooks/client';
import { useIndexQuery } from '../../hooks/rootQueries';
import { Certification, CertificationDraft } from '../../models/certification';
import { Query } from '../../models/query';
import { SortOrder } from '../../models/queryParams';
import { RoutePath } from '../Router';

type Props = {
  form: FormInstance;
  certification?: Certification;
  onSaveCertification: (certification: Certification | CertificationDraft) => void;
};

export const CertificationFormContainer: React.FC<Props> = (props) => {
  const { formatMessage } = useIntl();

  const navigate = useNavigate();

  const [hasChanges, setHasChanges] = React.useState(false);

  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const toggleConfirmDialog = () => setShowConfirmDialog(!showConfirmDialog);

  const userClient = useApiClient(UserClient);
  const { data: usersIndex } = useIndexQuery(Query.USERS, userClient, { sorting: { field: 'users.forename', order: SortOrder.ascend } });

  const resolveTitle = () =>
    props.certification
      ? formatMessage({ id: 'action.update' }, { entity: formatMessage({ id: 'model.certification' }) })
      : formatMessage({ id: 'action.create' }, { entity: formatMessage({ id: 'model.certification' }) });

  const goBack = () => {
    navigate(RoutePath.CERTIFICATION);
  };

  const updateHasChanges = () => {
    setHasChanges(props.form.isFieldsTouched());
  };

  return (
    <>
      <StageContainer
        title={resolveTitle()}
        subTitle={props.certification?.title}
        headerActions={<SaveButton onSave={props.form.submit} hasChanges={hasChanges} />}
        onBack={goBack}
        onConfirmBack={toggleConfirmDialog}
        hasChanges={hasChanges}
      >
        <CertificationForm
          saveCertification={props.onSaveCertification}
          users={usersIndex.data}
          form={props.form}
          certification={props.certification}
          onChange={updateHasChanges}
        />
      </StageContainer>
      <ConfirmLeaveDialog onCancel={toggleConfirmDialog} onLeaveAnyway={goBack} onSaveAndLeave={props.form.submit} show={showConfirmDialog} />
    </>
  );
};
