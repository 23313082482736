import { RESTClient } from './client';
import { Company, CompanyDraft } from '../models/company';

export class CompanyClient extends RESTClient<Company, CompanyDraft> {
  public constructor(token?: string) {
    super('companies', token);
  }

  public delete(_entity: any): never {
    throw new Error('Not supported by API');
  }
}
