import { SaveOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {
  hasChanges: boolean;
  onSave: () => void;
};

export const SaveButton: React.FC<Props> = ({ hasChanges, onSave }) => {
  const { formatMessage } = useIntl();

  return (
    <Tooltip title={!hasChanges && formatMessage({ id: 'view.tooltip.noChanges' })} placement="bottom">
      <Button type="primary" onClick={() => hasChanges && onSave()} icon={<SaveOutlined />}>
        <FormattedMessage id="action.save" children={(msg) => <span>{msg}</span>} />
      </Button>
    </Tooltip>
  );
};
