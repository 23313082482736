import * as React from 'react';
import styled from '@emotion/styled';
import { Menu } from 'antd';
import { useIntl } from 'react-intl';
import { LogoutOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

const StyledHeaderMenu = styled(Menu)`
  && {
    display: flex;
    line-height: 64px;
  }
`;

enum MenuActions {
  SIGN_OUT = 'signOut'
}

interface Props {
  signOut: () => void;
  isAuthenticated: boolean;
}

export const HeaderMenu: React.FC<Props> = ({ signOut, isAuthenticated }) => {
  const { formatMessage } = useIntl();

  const logoutItem: ItemType = {
    key: MenuActions.SIGN_OUT,
    label: formatMessage({ id: 'action.signOut' }),
    icon: <LogoutOutlined />,
    onClick: signOut
  };

  return <StyledHeaderMenu selectable={false} mode="horizontal" disabledOverflow={true} items={isAuthenticated && [logoutItem]} />;
};
