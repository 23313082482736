import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';

export enum SortOrder {
  ascend = 'ASC',
  descend = 'DESC'
}

export type Sorting = {
  field: string;
  order: SortOrder;
};

export type QueryParams = {
  page?: number;
  sorting?: Sorting;
  filters?: Record<string, string>;
  search?: string;
};

export const createInitialParams = (field: string): QueryParams => ({
  page: 1,
  sorting: {
    field,
    order: SortOrder.descend
  },
  filters: {}
});

export const useQueryParams = (initialQueryParams?: QueryParams): [QueryParams, (queryParams: QueryParams) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setQueryParams = (queryParams: QueryParams) => {
    if (queryParams) {
      const searchParams = {};
      if (queryParams.page) {
        searchParams['page'] = queryParams.page.toString();
      }
      if (queryParams.sorting?.field && queryParams.sorting?.order) {
        searchParams['sort_by'] = queryParams.sorting.field;
        searchParams['order_by'] = queryParams.sorting.order;
      }
      if (queryParams.filters) {
        Object.entries(queryParams.filters)
          .filter(([_key, value]) => value)
          .forEach(([key, value]) => (searchParams[`filter_${key}`] = value));
      }
      if (queryParams.search) {
        searchParams['search'] = queryParams.search;
      }
      setSearchParams(searchParams);
    }
  };

  const filters = Array.from(searchParams.entries())
    .filter(([key]) => key.startsWith('filter_'))
    .reduce((prev, [key, value]) => {
      prev[key.replace('filter_', '')] = value;
      return prev;
    }, {});

  let queryParams: QueryParams = {
    page: searchParams.get('page') && parseInt(searchParams.get('page')),
    sorting: {
      field: searchParams.get('sort_by'),
      order: searchParams.get('order_by') as SortOrder
    },
    filters,
    search: searchParams.get('search')
  };

  const isQueryParamsEmpty = _.isEmpty(queryParams.filters) && !queryParams.page && !queryParams.sorting?.field && !queryParams.sorting?.order && !queryParams.search;

  if (initialQueryParams && isQueryParamsEmpty) {
    queryParams = initialQueryParams;
  }

  return [queryParams, setQueryParams];
};
