import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { QueryParams } from '../../models/queryParams';
import { useIndexQuery } from '../../hooks/rootQueries';
import { RESTClient } from '../../client/client';
import { useApiClient } from '../../hooks/client';
import { Query } from '../../models/query';
import { SelectorProps } from '../../services/selectorService';
import { DebounceSelect } from './DebounceSelect';

type APISearchableSelectorProps<E extends { id: number }, C extends RESTClient<E>> = SelectorProps<E> & {
  queryName: Query;
  clientClass: new (token?: string) => C;
  filters?: QueryParams['filters'];
  buildLabel: (entity: E) => string;
  valueFieldName?: keyof E;
};

export function APISearchableSelector<E extends { id: number }, C extends RESTClient<E, unknown>>({
  queryName,
  clientClass,
  filters,
  value,
  onChange,
  buildLabel,
  valueFieldName,
  selectProps
}: APISearchableSelectorProps<E, C>) {
  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] = useState<QueryParams>({ page: 1, filters });

  const client = useApiClient(clientClass);

  const {
    data: { data: results }
  } = useIndexQuery(queryName, client, queryParams);

  const [selectedOptions, setSelectedOptions] = useState<E[]>([]);
  const [selectionsFetched, setSelectionsFetched] = useState(false);

  const fetchSelectedOptions = (ids: number[]) => Promise.all(ids.map((id) => queryClient.fetchQuery([queryName, id], () => client.read({ id }).then((c) => c.data))));

  const toOption = (entity: E) => ({ value: entity[valueFieldName ?? 'id'] as number, label: buildLabel(entity) });

  const handleChange = async (value: number | number[]) => {
    const ids = Array.isArray(value) ? value : [value];
    const selections = await fetchSelectedOptions(ids);
    setSelectedOptions(selections);

    onChange(Array.isArray(value) ? selections : selections?.[0]);
  };

  const handleSearch = (value: string) => {
    setQueryParams({ ...queryParams, search: value });
  };

  useEffect(() => {
    if (!value || selectionsFetched) return;

    const ids = Array.isArray(value) ? value.map((v) => v.id) : [value.id];

    if (ids.includes(undefined)) return;

    fetchSelectedOptions(ids)
      .then(setSelectedOptions)
      .then(() => setSelectionsFetched(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return <DebounceSelect onDebounceSearch={handleSearch} onChange={handleChange} value={selectedOptions.map(toOption)} options={results.map(toOption)} {...selectProps} />;
}
