import packageJson from '../../package.json';

type Environment = {
  apiBase?: string;
};

declare global {
  interface Window {
    env?: Environment;
  }
}

// Retrieves the runtime environment (usually defined for this container, where the app runs in)
const runtimeEnvironment =
  window.env &&
  Object.entries(window.env).reduce((prev, [key, value]) => {
    prev[key] = value.startsWith('${') ? undefined : value;
    return prev;
  }, {} as Environment);

export const appName = 'hrcert';
export const version = packageJson.version;
export const localStorageVersion = 2004;
export const apiBase = runtimeEnvironment.apiBase || process.env.API_BASE || 'http://localhost:3000/api';
export const env = process.env.NODE_ENV;
export const locale = 'en-us';
export const defaultDateFormat = 'yyyy-MM-dd';
export const defaultTimeFormat = 'hh:mm';
export const defaultDateTimeFormat = `${defaultDateFormat} ${defaultTimeFormat}`;
